import React, { useContext, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";

const UpdateStaffPage = ({
  open,
  staffData,
  fetchData,
  // fetchstaffData,
  id,
  setSingleFetchData,
  setTimerPopupAlert,
  setTimerAlertMessage,
}) => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");
  // const itemId = staffData._id;

  // useStates  ================================================================================================================

  const [loading, setLoading] = useState(false);
  const [submitButton, setSubmitButton] = useState(false);

  const [addPayroll, setAddPayroll] = useState(false);
  //   ================================================================================================================

  const [data, setData] = useState({

    staffName : staffData.staffName,
    staffIdentification : staffData.staffIdentification,
    staffMobileNumber : staffData.staffMobileNumber,
    staffDesignation : staffData.staffDesignation,
    staffEmail : staffData.staffEmail,
    staffAddress : staffData.staffAddress,
    enableStaffPayroll : staffData.enableStaffPayroll,
    staffPayoutType : staffData.staffPayoutType,
    staffSalary : staffData.staffSalary,
    staffSalaryCycle : staffData.staffSalaryCycle,
    staffOpeningBalanceAmount : staffData.staffOpeningBalanceAmount,
    staffOpeningBalanceType : staffData.staffOpeningBalanceType,
    
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setSubmitButton(true);

    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  const { updateStaff } = useContext(AuthContext);

  const handleUpdate = async (e) => {
    setLoading(true);
    e.preventDefault();
    await updateStaff(
      userId,
      businessId,
      id,
      data,
      setSingleFetchData,
      setTimerPopupAlert,
      setTimerAlertMessage,
      open
    );
    fetchData();
    // fetchstaffData();
    setLoading(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission on Enter
    }
  };

  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };

  const handlePriceChange = (e) => {
    const price = parseFloat(e);
    setData({
      ...data,
      itemPrice: price,
      itemTotalPrice: formatNumber(price + (price * data.itemTax) / 100),
    });
  };

  const handleTaxChange = (e) => {
    const tax = parseFloat(e);
    setData({
      ...data,
      itemTax: tax,
      itemTotalPrice: data.itemPrice
        ? formatNumber(data.itemPrice + (data.itemPrice * tax) / 100)
        : data.itemPrice + (data.itemPrice * tax) / 100,
    });
  };

  const handleTotalChange = (e) => {
    const total = parseFloat(e);
    const basePrice = total / (1 + data.itemTax / 100);
    setData({
      ...data,
      itemTotalPrice: total,
      itemPrice: formatNumber(basePrice),
    });
  };

  return (
    <div>
      <form className="app-form" onSubmit={handleUpdate}>
        <div className="app-form-main">
          <div className="app-form-top">
            <div className="app-form-top-col-1">
              <div
                onClick={(e) => open(false)}
                className="app-form-top-col-back"
              >
                {/* <i className="fa-solid fa-arrow-left"></i> */}
                <i className="fa-solid fa-chevron-left"></i>
              </div>
              <div className="app-form-top-col-heading">
                Update Staff Data ({staffData.staffName}){" "}
              </div>
            </div>

            <div className="app-form-top-col-2">
              <div className="app-form-top-col-2-buttons">
                {loading === true ? (
                  <div className="app-form-top-col-button-disable">
                    Updating...
                  </div>
                ) : !submitButton ? (
                  <>
                    <div
                      onClick={(e) => open(false)}
                      className="app-form-top-col-button-2"
                    >
                      Close
                    </div>
                    <div className="app-form-top-col-button-disable">
                      Update 
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      onClick={(e) => open(false)}
                      className="app-form-top-col-button-2"
                    >
                      Close
                    </div>
                    <button className="app-form-top-col-button-1">
                      Update 
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="app-form-form">
            <div className="app-form-form-head">
              <div>General Details
              </div>
            </div>

            <div className="app-form-form-body app-form-form-body-2">
            
              <div className="app-form-form-input-col">
                <label>Staff Name *</label>
                <input
                  onKeyDown={handleKeyDown}
                  required
                  onChange={handleChange}
                  name="staffName"
                  value={data.staffName}
                  type="text"
                  placeholderOff=""
                />
              </div>
            
              <div className="app-form-form-input-col">
                <label>Id Number</label>
                <input
                  onKeyDown={handleKeyDown}
                  onChange={handleChange}
                  name="staffIdentification"
                  value={data.staffIdentification}
                  type="text"
                  placeholderOff=""
                />
              </div>
            
              <div className="app-form-form-input-col">
                <label>Mobile Number</label>
                <input
                  onKeyDown={handleKeyDown}
                  onChange={handleChange}
                  name="staffMobileNumber"
                  value={data.staffMobileNumber}
                  type="text"
                  placeholderOff=""
                />
              </div>
            
              <div className="app-form-form-input-col">
                <label>Designation</label>
                <input
                  onKeyDown={handleKeyDown}
                  onChange={handleChange}
                  name="staffDesignation"
                  value={data.staffDesignation}
                  type="text"
                  placeholderOff=""
                />
              </div>
            
              <div className="app-form-form-input-col">
                <label>Email</label>
                <input
                  onKeyDown={handleKeyDown}
                  onChange={handleChange}
                  name="staffEmail"
                  value={data.staffEmail}
                  type="text"
                  placeholderOff=""
                />
              </div>
            
              <div className="app-form-form-input-col">
                <label>Address</label>
                <input
                  onKeyDown={handleKeyDown}
                  onChange={handleChange}
                  name="staffAddress"
                  value={data.staffAddress}
                  type="text"
                  placeholderOff=""
                />
              </div>

        </div>
{
  data.enableStaffPayroll ?

        <div >
                             <div className="app-form-form-head">
                    <div>
                    Payroll Details
                    </div>
                    <span
                      onClick={(e) => {setData({ ...data, enableStaffPayroll: false }); setSubmitButton(true);}}
                    >
                      remove payroll <i className="fa-solid fa-circle-minus"></i>
                    </span>
                  </div>

            <div className="app-form-form-body app-form-form-body-2">
  
            
              <div className="app-form-form-input-col">
                <label> Salary Payout Type</label>
  

<select onChange={handleChange} name="staffPayoutType" value={data.staffPayoutType}>
                    <option value="Monthly">Monthly</option>
                    <option value="Weekly">Weekly</option>
                  </select>
                
              </div>
            
              <div className="app-form-form-input-col">
                <label> Salary </label>
                <input
                  onKeyDown={handleKeyDown}
                  required
                  onChange={handleChange}
                  name="staffSalary"
                  value={data.staffSalary}
                  type="number"
                  placeholderOff=""
                />
              </div>
            
              <div className="app-form-form-input-col">
                <label> Salary Cycle</label>
                <input
                  onKeyDown={handleKeyDown}
                  required
                  onChange={handleChange}
                  name="staffSalaryCycle"
                  value={data.staffSalaryCycle}
                  type="text"
                  placeholderOff=""
                />
              </div>


              <div className="app-form-form-input-col app-form-form-input-col-balance-type">
                <label>Outstanding/Opening Balance</label>
                <div>
                  <input
                    onChange={handleChange}
                    name="staffOpeningBalanceAmount"
                    value={data.staffOpeningBalanceAmount}
                    type="number"
                    placeholder="Rs."
                  />
                  <select
                    onChange={handleChange}
                    name="staffOpeningBalanceType"
                    value={data.staffOpeningBalanceType}
                  >
                    <option value="To Collect">To Collect</option>
                    <option value="To Pay">To Pay</option>
                  </select>
                </div>
              </div>



              
            

        </div>
        </div>

        :
        <div className="app-form-form-head">
        <div onClick={(e) => {setData({ ...data, enableStaffPayroll: true }); setSubmitButton(true);}}>
        Add Payroll {" "}
          <i className="fa-solid fa-circle-plus"></i>
        </div>
      </div>
        }

        </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateStaffPage;
