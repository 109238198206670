import React, { useContext, useEffect, useState } from "react";
import LoadingComponent from "../../../../components/extra/LoadingComponent";
import { AuthContext } from "../../../../context/AuthContext";
import PopUpAlertComponent from "../../../../components/extra/PopUpAlertComponent";
import UpdateItemPage from "../update/UpdateItemPage";
import UpdateStaffPage from "../update/UpdateStaffPage";

const ViewStaffPage = ({
  open,
  fetchData,
  data,
  setSingleFetchData,
  dataId,
  setTimerPopupAlert,
  setTimerAlertMessage,
}) => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");
 

  const [loading, setLoading] = useState(false);
  const [popUpAlert, setPopUpAlert] = useState(false);
  const [updateForm, setUpdateForm] = useState(false);
  // const [data, setData] = useState()

  const { deleteSingleStaff, fetchSingleItem } = useContext(AuthContext);

  // const fetchItemData = (e) => fetchSingleItem(userId, businessId, itemId, setData);

  // useEffect(() => {
  //   fetchItemData();
  // }, []);

  const handleDelete = async (e) => {
    setLoading(true);
    await deleteSingleStaff(
      userId,
      businessId,
      dataId,
      open,
      setTimerPopupAlert,
      setTimerAlertMessage
    );
    fetchData();
    setLoading(false);
  };

  if (!data) return <LoadingComponent />;

  return (
    <div>
      {popUpAlert ? (
        <PopUpAlertComponent
          open={setPopUpAlert}
          ok={handleDelete}
          heading={`You want to delete "${data.staffName}" data?`}
        />
      ) : (
        ""
      )}

      {updateForm ? (
        <UpdateStaffPage
          open={setUpdateForm}
          staffData={data}
          fetchData={fetchData}
          id={dataId}
          // fetchItemData={fetchItemData}
          setSingleFetchData={setSingleFetchData}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
        />
      ) : (
        ""
      )}

      <div className="app-form">
        <div className="app-form-main">
          <div className="app-form-top">
            <div className="app-form-top-col-1">
              <div
                onClick={(e) => open(false)}
                className="app-form-top-col-back"
              >
                {/* <i className="fa-solid fa-arrow-left"></i> */}
                <i className="fa-solid fa-chevron-left"></i>
              </div>
              <div className="app-form-top-col-heading">
                {data.staffName ? data.staffName : "---------"}
              </div>
            </div>

            <div className="app-form-top-col-2">
              <div className="app-form-top-col-2-buttons">
                <div
                  className="app-form-top-col-button-2"
                  onClick={(e) => open(false)}
                >
                  {" "}
                  close
                </div>
                <button
                  className="app-form-top-col-button-1"
                  onClick={(e) => setUpdateForm(true)}
                >
                  <i className="fa-solid fa-pen-to-square"></i> Edit
                </button>
                <div
                  className="app-form-top-col-button-3"
                  onClick={(e) => setPopUpAlert(true)}
                >
                  <i className="fa-solid fa-trash"></i> Delete
                </div>
                {/* <button className="app-form-top-col-button-1" ><i className="fa-solid fa-pen-to-square"></i> Edit</button> */}
              </div>
            </div>
          </div>

          <div className="app-form-form">
            
            <div className="app-form-form-head">
              <div>
                General Details
              </div>
            </div>

            <div className="app-form-form-body app-form-form-body-2">
             
              <div className="app-form-form-input-col">
                <label> Name</label>
                <div className="app-form-form-input-col-text">
                  {data.staffName ? data.staffName : "---"}
                </div>
              </div>

             
              <div className="app-form-form-input-col">
                <label> Id Number</label>
                <div className="app-form-form-input-col-text">
                  {data.staffIdentification ? data.staffIdentification : "---"}
                </div>
              </div>

             
              <div className="app-form-form-input-col">
                <label> Mobile Number</label>
                <div className="app-form-form-input-col-text">
                  {data.staffMobileNumber ? data.staffMobileNumber : "---"}
                </div>
              </div>

             
              <div className="app-form-form-input-col">
                <label> Designation</label>
                <div className="app-form-form-input-col-text">
                  {data.staffDesignation ? data.staffDesignation : "---"}
                </div>
              </div>

             
              <div className="app-form-form-input-col">
                <label> Email</label>
                <div className="app-form-form-input-col-text">
                  {data.staffEmail ? data.staffEmail : "---"}
                </div>
              </div>

             
              <div className="app-form-form-input-col">
                <label> Address</label>
                <div className="app-form-form-input-col-text">
                  {data.staffAddress ? data.staffAddress : "---"}
                </div>
              </div>


            </div>

      {
        data.enableStaffPayroll?
        <>

        

<div className="app-form-form-head">
              <div>
                Payroll Details
              </div>
            </div>

            <div className="app-form-form-body app-form-form-body-2">
                   
              <div className="app-form-form-input-col">
                <label> Salary Payout Type</label>
                <div className="app-form-form-input-col-text">
                  {data.staffPayoutType ? data.staffPayoutType : "---"}
                </div>
              </div>

             
              <div className="app-form-form-input-col">
                <label> Salary</label>
                <div className="app-form-form-input-col-text">
                  {data.staffSalary ? data.staffSalary : "---"}
                </div>
              </div>

             {
              data.staffPayoutType === "Monthly"?
             
              <div className="app-form-form-input-col">
                <label> Salary Cycle</label>
                <div className="app-form-form-input-col-text">
                  {data.staffSalaryCycle ? data.staffSalaryCycle : "---"}
                </div>
              </div>
:""}
             
              <div className="app-form-form-input-col">
                <label> Outstanding/Opening Balance</label>
                <div className="app-form-form-input-col-text">
                  {data.staffOpeningBalanceAmount ? <>{data.staffOpeningBalanceAmount} - {data.staffOpeningBalanceType}</> : "---"}
                </div>
              </div>
            </div>

        
        </>
:""}
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewStaffPage;
