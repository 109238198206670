import React, { useContext, useEffect, useState } from 'react'
import LoadingComponent from '../../../components/extra/LoadingComponent'
import { AuthContext } from '../../../context/AuthContext';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import logo from "../../../media/images/logo/invoician-logo.png";
import axios from 'axios';
import TimerPopupAlertComponent from '../../../components/extra/TimerPopupAlertComponent';

const  UserForgotPasswordPage = () => {

  // useStates =======================================================================================
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("");
  const [timerPopupAlert, setTimerPopupAlert] = useState(false)
  const [timerAlertMessage, setTimerAlertMessage] = useState("")

  const [sentOtp, setSentOtp] = useState(false)

  
  
  
  const [user, setUser] = useState({
    email: "",
    password: "",
    otp: "",
  });



  const id = localStorage.getItem("invoician-user-id")
  const token = localStorage.getItem("invoician-token")
  
  //  useContent ==================================================================================
  const { forgotPasswordOtp, updateUserPassword } = useContext(AuthContext);

  const navigate = useNavigate()

   
  
  // functions ========================================================================================
  
  const handleChange = (e) => {
    setError("")
    const value = e.target.value;
    setUser({
      ...user,
      [e.target.name]: value,
    });
  };

  const handlePasswordOtp = async (e) => {
    setLoading(true);
    e.preventDefault();
    await forgotPasswordOtp( user, setSentOtp, setError, setTimerPopupAlert, setTimerAlertMessage )
    setLoading(false);
  };

  

  const handleUpdatePassword = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (user.password === user.confirmPassword) {
      await updateUserPassword( user, setSentOtp, setError, navigate )
    } else {
      setError("Both password not match");
    }
    
    setLoading(false);
  };

  // if (token || id) return <Navigate to="/app"/>

  
  
  return (
    <div>

{/* {loading ? <LoadingComponent /> : ""} */}


{
  timerPopupAlert?
  <TimerPopupAlertComponent message={timerAlertMessage} setTimerPopupAlert={setTimerPopupAlert}/>
  :""
}

<div className="register-page">
  <div className="register-page-main">
    <div className="register-page-content">
      <div className="register-page-head">
        <div className="register-page-head-logo">
          <img src={logo} alt="invocian-logo" title="invoician logo" />
        </div>
        <div className="register-page-head-heading-1">
          {sentOtp ? "OTP Sent On Mail" : " Update Password"}
        </div>
      </div>

      <div className="register-page-form">
        {sentOtp ? (
          <form onSubmit={handleUpdatePassword}>
           <input
              required
              value={user.email}
              className="cursor-disable"
              type="email"
              placeholder="Email"
            />
            <input
                    required
                    onChange={handleChange}
                    name="password"
                    type="password"
                    placeholder="Create New Password"
                  />
                  <input
                    required
                    onChange={handleChange}
                    name="confirmPassword"
                    type="password"
                    placeholder="Confirm Password"
                  />
            <input
              required
              onChange={handleChange}
              value={user.otp}
              name="otp"
              type="text"
              placeholder="Confirm OTP"
            />
            {error ? (
              <div className="register-page-head-error">{error}</div>
            ) : (
              ""
            )}
              {
              loading ?
      <div className="loading-button"> <div></div> Update Password <div class="button-loader"></div> </div>
              :
              <button>Update Password</button>
            }
            {/* <button>Update Password</button> */}
            <div className="register-page-form-end">
              <Link to="/login" >
                back to login?
              </Link>
            </div>
          </form>
        ) : (
          <form onSubmit={handlePasswordOtp}>
            
            <input
              required
              onChange={handleChange}
              name="email"
              type="email"
              placeholder="Email"
            />

            {error ? (
              <div className="register-page-head-error">{error}</div>
            ) : (
              ""
            )}

{
              loading ?
      <div className="loading-button"> <div></div> Request Otp  <div class="button-loader"></div> </div>
              :
              <button>Request Otp</button>
            }
            
            {/* <button>Request Otp</button> */}
            <div className="register-page-form-end">
            <Link to="/login">back to login?</Link>
            </div>
            {/* <div className="register-page-form-end">Don't have an account? <Link to="/register">Register</Link></div> */}
          </form>
        )}
      </div>
    </div>
  </div>
</div>
        
        
    </div>
  )
}

export default  UserForgotPasswordPage 