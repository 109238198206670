import React, { useContext, useEffect, useState } from 'react'
import logo from "../../media/images/logo/invoician-logo.png";
import { Link, useLocation, useNavigate } from 'react-router-dom'
import AppConfiguration from './main/AppConfiguration';
import { AuthContext } from '../../context/AuthContext';
import PopUpAlertComponent from '../../components/extra/PopUpAlertComponent';
import LoadingComponent from '../../components/extra/LoadingComponent';

const SettingNavbar = () => {

  
  // get from storage ================================================================================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  // useStates ================================================================================================================================================

  const [popUpAlert, setPopUpAlert] = useState(false);
  const [appNavUserData, setAppNavUserData] = useState();
  const [appNavBusinessData, setAppNavBusinessData] = useState();
  
  
    
  const location = useLocation();
  const path = location.pathname;
  
  
  const { logout, fetchUser, fetchBusiness } = useContext(AuthContext);
  const navigate = useNavigate();
  
  const userLogout = (e) => {
    logout();
    navigate("/login");
  };
  

  const fetchUserData = (e) => fetchUser(userId, setAppNavUserData);
  const fetchBusinessData = (e) =>
    fetchBusiness(userId, businessId, setAppNavBusinessData);

  useEffect(() => {
    fetchUserData();
    fetchBusinessData();
  }, []);
  
  if(!appNavBusinessData) return <div><LoadingComponent/></div>
  
  
  return (
    <div> 
        <AppConfiguration/>
        {popUpAlert ? <PopUpAlertComponent open={setPopUpAlert} ok={userLogout} heading="You want to logout?" /> :""}

      <div className="app-navbar">
        <div className="app-navbar-main">
          <div className="app-navbar-main-content">
            {/* =================================================================================================================================== */}

            
            
            <div className="app-navbar-company">
              <div className="app-navbar-company-details">
                {appNavUserData && appNavUserData.businessData ? (
                  <div className="app-navbar-company-content">
                    &nbsp;
                    &nbsp;
                    {/* <div className="app-navbar-company-logo">
                      {appNavBusinessData.companyLogo ? (
                        <img
                          src={`https://api.invoician.com/${appNavBusinessData.companyLogo}`}
                          alt="profile"
                        />
                      ) : (
                        <div>
                          {appNavBusinessData &&
                            appNavBusinessData.businessName.charAt(0)}
                        </div>
                      )}
                    </div> */}
                    <div className="app-navbar-company-profile">
                      <div className="app-navbar-company-name">
                        {/* Garage On Call  */}
                        {appNavBusinessData &&
                        appNavBusinessData.businessName.length > 15
                          ? `${appNavBusinessData.businessName.slice(0, 15)}...`
                          : appNavBusinessData.businessName}
                        {/* {name.length>12?`${name.slice(0, 12)}...`:name} */}
                      </div>
                      <div className="app-navbar-company-number">
                        {/* +91 9658-124-124 */}
                        {appNavBusinessData && appNavBusinessData.companyNumber}
                      </div>
                    </div>
                  </div>
                ) : (
                  <Link
                    className="app-navbar-add-company"
                    to="/app/setting/business"
                  >
                    + Add Company
                  </Link>
                )}

                {/* <Link to="/app/setting/business" className="app-navbar-company-icon"> <i className="fa-solid fa-gear"></i> </Link> */}
                {/* <i class="fa-solid fa-angle-down"></i> */}
                {/* <Link className="app-navbar-company-icon"> <i class="fa-solid fa-caret-left"></i> </Link> */}
                
              </div>
            </div>


            {/* back to home =================================================================================================================================== */}
            <Link to="/app" className="app-setting-navbar-back"><i className="fa-solid fa-house"></i> Back to Dashboard</Link>
            


            {/* menu =================================================================================================================================== */}

            <div className="app-navbar-menu">
              {/* <div className="app-navbar-menu-heading">General</div> */}
              <div className="app-navbar-menu-links">
                
                {/* <Link to="/app/setting" className={path === "/app/setting"?"app-navbar-menu-active":""}>
                  <div>
                  <i className="fa-solid fa-gears"></i>General
                  </div>
                </Link> */}

                <Link className={path === "/app/setting/account"?"app-navbar-menu-active":""} to="account">
                  <div>
                    <i className="fa-solid fa-user"></i>Account
                  </div>
                </Link>
                
                <Link to="business" className={path === "/app/setting/business"?"app-navbar-menu-active":""}>
                  <div>
                    <i className="fa-solid fa-address-card"></i>Manage Business
                  </div>
                </Link>
                
                
                <Link to="invoice" className={path === "/app/setting/invoice"?"app-navbar-menu-active":""}>
                  <div>
                  <i className="fa-solid fa-file-lines"></i> Invoice Setting
                  </div>
                </Link>
                
              </div>



            </div>
          </div>
          {/* =================================================================================================================================== */}

          <div onClick={e=>setPopUpAlert(true)}  className="app-navbar-logout">
            <span >
              Logout <i className="fa-solid fa-right-from-bracket"></i>
            </span>
          </div>

          {/* =================================================================================================================================== */}
        </div>
      </div>
        
        
    </div>
  )
}

export default SettingNavbar