import React from 'react'
import AppDashboardComponent from '../../components/pageComponents/AppDashboardComponent'

const AppDashBoard = () => {
  return (
    <div>
        <AppDashboardComponent/>
    </div>
  )
}

export default AppDashBoard