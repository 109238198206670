import React, { useContext, useEffect, useState } from "react";
import SettingNavbar from "../../components/SettingNavbar";
import AppBusinessSettingPageComponent from "../../components/settings/AppBusinessSettingPageComponent";
import { AuthContext } from "../../../context/AuthContext";
import LoadingComponent from "../../../components/extra/LoadingComponent";
import AppAccountSettingPageComponent from "../../components/settings/AppAccountSettingPageComponent";
// import AppNavbar from "../components/AppNavbar";

const  AppAccountSettingPage = () => {

  
  // get from storage ============================================================================================
  
  const userId = localStorage.getItem("invoician-user-id");
  
  
  const [userData, setUserData] = useState("");


  const { fetchUser } = useContext(AuthContext);

  
  
  // useEffect ===============================================================================================================================

const fetchUserData = e =>  fetchUser( userId , setUserData)
  



useEffect(() => {
  fetchUserData()
}, []);

if ( !userData )return (<div><LoadingComponent /></div>);


  

  return (
    <div>
            <AppAccountSettingPageComponent data={userData} fetchUserData={fetchUserData}/>
    </div>
  );
};

export default  AppAccountSettingPage;
