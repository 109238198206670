import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import TimerPopupAlertComponent from "../../../components/extra/TimerPopupAlertComponent";

const CreateItemFormPopup = ({open, fetchData}) => {

         
  // get from storage ============================================================================================
  
  
  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");
  

    
    // useStates  ================================================================================================================

  const [loading, setLoading] = useState(false)
  const [timerAlertMessage, setTimerAlertMessage] = useState("")
  const [timerPopupAlert, setTimerPopupAlert] = useState(false)

//   ================================================================================================================
    const navigate = useNavigate();


    const [data, setData] = useState({
        itemType : "Product",
        itemName : "",
        itemPrice : "",
        itemTax : "",
        itemUnit : "",
        itemCode : "",
        itemCategory : "",
        itemStock : "",
        enableStock : false,
        itemTotalPrice : "",
        
      });
          
      const handleChange = (e) => {
        const value = e.target.value;
        setData({
          ...data,
          [e.target.name]: value,
        });
        
      };
    
const { createItem } = useContext(AuthContext);

const handleCreate = async (e) => {  
  setLoading(true);
  e.preventDefault();
  await createItem(userId, businessId, data,  setTimerPopupAlert, setTimerAlertMessage, emptyData, "" ,open);
  setLoading(false);
  fetchData&&fetchData()
};
  
  const emptyData = (e) => setData({
    itemType : "Product",
    itemName : "",
    itemPrice : "",
    itemTax : "",
    itemUnit : "",
    itemCode : "",
    itemCategory : "",
    itemStock : "",
    enableStock : false
  })

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission on Enter
    }
  };
  
  const handleCreateAndNew = async (e) => {
    setLoading(true)
    if(!data.itemName){
      setTimerPopupAlert(true)
      setTimerAlertMessage(`Enter ${data.itemType} Name`)
    }
    else if(!data.itemPrice){
      setTimerPopupAlert(true)
      setTimerAlertMessage(`Enter ${data.itemType} Price`)
      
    }else{
      e.preventDefault();
      await createItem(userId, businessId, data, setTimerPopupAlert, setTimerAlertMessage, emptyData);
    }
    setLoading(false);
  };



  // ------------------------------------------------------------------------------------------------------------------

  
  // const handleTotalChange = (value) => {
  //   const total = parseFloat(value);

  //   if (value.trim() === "") {
  //     setData({...data, itemPrice : 0 })
  //   } else {
      
  //     const basePrice = total / (1 + data.itemTax / 100);
      
  //     setData({...data, itemPrice : basePrice })
      
  //   }
  // };

  // const calculateTaxedPrice = () => {
  //   return (data.itemPrice + (data.itemPrice * data.itemTax) / 100) ;
  // };
  
  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };
  

  
  const handlePriceChange = (e) => {
    const price = parseFloat(e);
    setData({...data, itemPrice : price, itemTotalPrice : formatNumber(price + (price * data.itemTax) / 100) })
    
  };
  
  const handleTaxChange = (e) => {
    const tax = parseFloat(e);
    setData({...data, itemTax : tax, itemTotalPrice : data.itemPrice?formatNumber(data.itemPrice + (data.itemPrice * tax) / 100):(data.itemPrice + (data.itemPrice * tax) / 100) })
  };
  
  const handleTotalChange = (e) => {
    const total = parseFloat(e);
    const basePrice = total / (1 + data.itemTax / 100);
    setData({...data, itemTotalPrice : total, itemPrice : formatNumber(basePrice) })
  };


  
  
  

  
  return (
    <div>
        
{
  timerPopupAlert?
  <TimerPopupAlertComponent message={timerAlertMessage} setTimerPopupAlert={setTimerPopupAlert}/>
  :""
}
      <form className="app-form-popup" onSubmit={handleCreate}>
        <div className="app-form-main">
          <div className="app-form-top">
            <div className="app-form-top-col-1">
             
              <div className="app-form-top-col-heading">Create Item</div>
            </div>

            <div className="app-form-top-col-2">
              <div className="app-form-top-col-2-buttons">
                
                
                
                
                {
                  loading === true?
                  <div className="app-form-top-col-button-disable">Updating...</div>
                  :
                  <>
                  {/* <div  className="app-form-top-col-button-2">Create & New</div> */}
                  <div onClick={e=>open(false)} className="app-form-top-col-button-2">Close</div>
   <button className="app-form-top-col-button-1">Create</button> 
  </>
                }
                
              </div>
            </div>
          </div>

          {/* <div className="app-form-form">
            <div className="app-form-form-head"><div><i className="fa-solid fa-file-lines"></i> {data.itemType} Details</div></div>

            <div className="app-form-form-body app-form-form-body-2">
              <div className="app-form-form-input-col">
                <label>Item Type *</label>
              <div className="app-form-form-input-col-radio-options">
                <span><input onKeyDown={handleKeyDown} onChange={handleChange} type="radio" name="itemType" value="Product" defaultChecked /> Product</span>
                <span><input onKeyDown={handleKeyDown} onChange={handleChange} type="radio" name="itemType" value="Service" /> Service</span>
              </div>

              </div>

              <div className="app-form-form-input-col">
                <label>{data.itemType} Code</label>
                <input onKeyDown={handleKeyDown} onChange={handleChange} name="itemCode" value={data.itemCode} type="text" placeholderOff="" />
              </div>

              <div className="app-form-form-input-col">
                <label>{data.itemType} Name *</label>
                <input onKeyDown={handleKeyDown} required onChange={handleChange} name="itemName" value={data.itemName} type="text" placeholderOff="" />
              </div>

              <div className="app-form-form-input-col">
                <label>{data.itemType} Price *</label>
                <input onKeyDown={handleKeyDown} required onChange={handleChange} name="itemPrice" value={data.itemPrice} type="number" placeholder="Rs." />
              </div>

              <div className="app-form-form-input-col app-form-form-input-col-balance-type">
                <label>GST Tax Rate(%)</label>
                <div>
                  <input onKeyDown={handleKeyDown} className="input-no-spinner" onChange={handleChange} list="gst-options" name="itemTax" value={data.itemTax} type="number" placeholderOff="" />
                  <datalist id="gst-options">
                  <option value={3}>GST @ 3%</option>
                  <option value={5}>GST @ 5%</option>
                  <option value={6}>GST @ 6%</option>
                  <option value={12}>GST @ 12%</option>
                  <option value={13.8}>GST @ 13.8%</option>
                  <option value={18}>GST @ 18%</option>
                  <option value={28}>GST @ 28%</option>
                </datalist>
                </div>
              </div>

              <div className="app-form-form-input-col">
                <label>{data.itemType} Category</label>
                <input onKeyDown={handleKeyDown} onChange={handleChange} name="itemCategory"
                value={data.itemCategory}
                  type="text"
                  placeholderOff=""
                  />
                
              </div>

              <div className="app-form-form-input-col">
                  <label>{data.itemType} Unit</label>
                <input onKeyDown={handleKeyDown} onChange={handleChange} name="itemUnit" value={data.itemUnit} type="text" placeholder="ex. piece" />
              </div>
              {data.itemType === "Product"?
              <div className="app-form-form-input-col">
                  <label>{data.itemType} Stock</label>
                <input onKeyDown={handleKeyDown} onChange={handleChange} name="itemStock" value={data.itemStock} type="number" placeholderOff="" />
              </div>
              :""
              }

            </div>

          </div> */}


          
<div className="app-form-form">
            <div className="app-form-form-head"><div><i className="fa-solid fa-file-lines"></i> {data.itemType} Details</div></div>

            <div className="app-form-form-body app-form-form-body-2">
              <div className="app-form-form-input-col">
                <label>Item Type *</label>
              <div className="app-form-form-input-col-radio-options">
                {/* <span><input onKeyDown={handleKeyDown} onChange={handleChange} type="radio" name="itemType" value="Product" defaultChecked /> Product</span>
                <span><input onKeyDown={handleKeyDown} onChange={handleChange} type="radio" name="itemType" value="Service" /> Service</span> */}
                <span onClick={e=> setData({...data, itemType : "Product" })} className={data.itemType==="Product"?"app-form-form-input-col-radio-options-active":""}>Product</span>
                <span onClick={e=> setData({...data, itemType : "Service" })} className={data.itemType==="Service"?"app-form-form-input-col-radio-options-active":""}>Service</span>
                
              </div>


                {/* <select onChange={handleChange} name="itemType" value={data.itemType} >
                  <option value="Product">Product</option>
                  <option value="Service">Service</option>
                </select> */}
              </div>

              <div className="app-form-form-input-col">
                <label>{data.itemType} Code</label>
                <input onKeyDown={handleKeyDown} onChange={handleChange} name="itemCode" value={data.itemCode} type="text" placeholderOff="" />
              </div>

              <div className="app-form-form-input-col">
                <label>{data.itemType} Name *</label>
                <input onKeyDown={handleKeyDown} required onChange={handleChange} name="itemName" value={data.itemName} type="text" placeholderOff="" />
              </div>
              
              <div className="app-form-form-input-col">
                <label>{data.itemType} Category</label>
                <input onKeyDown={handleKeyDown} onChange={handleChange} name="itemCategory"
                value={data.itemCategory}
                  type="text"
                  placeholderOff=""
                  />
                
              </div>


              {/* <div className="app-form-form-input-col">
                  <label>{data.itemType} Unit</label>
                <input onKeyDown={handleKeyDown} onChange={handleChange} name="itemUnit" value={data.itemUnit} type="text" placeholder="ex. piece" />
              </div>
              {data.itemType === "Product"?
              <div className="app-form-form-input-col">
                  <label>{data.itemType} Stock</label>
                <input onKeyDown={handleKeyDown} onChange={handleChange} name="itemStock" value={data.itemStock} type="number" placeholderOff="" />
              </div>
              :""
              } */}


            </div>

            
            <table className="app-form-form-input-col-table">

<div className="app-form-form-input-col">
  <label>{data.itemType} Price *</label>
  <input onKeyDown={handleKeyDown} required onChange={e=>handlePriceChange(e.target.value)} name="itemPrice" value={data.itemPrice} type="number" placeholder="Rs." />
</div>

<div className="app-form-form-input-col app-form-form-input-col-balance-type">
  <label>GST Tax Rate(%)</label>
  <div>
    <input onKeyDown={handleKeyDown} className="input-no-spinner" onChange={e=>handleTaxChange(e.target.value)} list="gst-options" name="itemTax" value={data.itemTax} type="number" placeholderOff="" />
    <datalist id="gst-options">
    <option value={3}>GST @ 3%</option>
    <option value={5}>GST @ 5%</option>
    <option value={6}>GST @ 6%</option>
    <option value={12}>GST @ 12%</option>
    <option value={13.8}>GST @ 13.8%</option>
    <option value={18}>GST @ 18%</option>
    <option value={28}>GST @ 28%</option>
  </datalist>
  </div>
</div>

{
  data.itemTax?
<>
<div className="app-form-form-input-col">
  <label>{data.itemType} Final Price *</label>
  <input onKeyDown={handleKeyDown} required onChange={e=>handleTotalChange(e.target.value)}  value={data.itemTotalPrice} type="number" placeholder="Rs." />
  </div>
  </>

:""
}


</table>
            

{
  data.itemType === "Product"?
(
  data.enableStock?
  <>


            <div className="app-form-form-head"><div><i className="fa-solid fa-box-open"></i> {data.itemType} Stock</div> <span  onClick={e=> setData({...data, enableStock : false })}>
                    disable Stock <i className="fa-solid fa-circle-minus"></i>
                  </span></div>

            <div className="app-form-form-body app-form-form-body-2">
             
              <div className="app-form-form-input-col">
                  <label>{data.itemType} Stock *</label>
                <input required onKeyDown={handleKeyDown} onChange={handleChange} name="itemStock" value={data.itemStock} type="number" min={0} placeholderOff="" />
              </div>
              <div className="app-form-form-input-col">
                  <label>{data.itemType} Unit *</label>
                <input required onKeyDown={handleKeyDown} onChange={handleChange} name="itemUnit" value={data.itemUnit} type="text" placeholder="ex. piece" />
              </div>
              
              

            </div>

            </>
:
<div className="app-form-form-head"><div onClick={e=> setData({...data, enableStock : true })}><i className="fa-solid fa-box-open"  ></i> Enable {data.itemType} Stock <i className="fa-solid fa-circle-plus"></i></div></div>
)
            :""}
            

          </div>
          
          
          
        </div>
      </form>
    </div>
  );
};

export default CreateItemFormPopup;
