import React from 'react'

const PopUpAlertComponent = ({ok, open, heading}) => {
  return (
    <div>
        <div className="alert-component">
        <div className="alert-component-content">
        <div className="alert-component-heading">{heading}</div>
        <div className="alert-component-options">
            <span onClick={()=>{ok(); open(false)}}>Yes</span>
            <span onClick={()=>open(false)}>No</span>
        </div>

        </div>

        </div>
    </div>
  )
}

export default PopUpAlertComponent