import React, { useContext, useState } from "react";
import logo from "../../../media/images/logo/invoician-logo.png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import LoadingComponent from "../../../components/extra/LoadingComponent";
import { AuthContext } from "../../../context/AuthContext";
import TimerPopupAlertComponent from "../../../components/extra/TimerPopupAlertComponent";

const UserRegisterPage = () => {
  // useStates ====================================================================

  const [otpTransfer, setOtpTransfer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [timerPopupAlert, setTimerPopupAlert] = useState(false)
  const [timerAlertMessage, setTimerAlertMessage] = useState("")


  const [user, setUser] = useState({
    userName: "",
    email: "",
    password: "",
    otp: "",
    confirmPassword: "",
  });

  //  useContent ==================================================================================
  const { registerOtp, verifyRegisterOtp } = useContext(AuthContext);

  const navigate = useNavigate();

  // functions ========================================================================================

  // sent otp on mail -----------------------------------
  const handleChange = (e) => {
    setError("");
    const value = e.target.value;
    setUser({
      ...user,
      [e.target.name]: value,
    });
  };
  // confirm OTP and create account -------------------------

  const handleRegisterOtp = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (user.password === user.confirmPassword) {
      await registerOtp(user, setOtpTransfer, setError, setTimerPopupAlert, setTimerAlertMessage);
    } else {
      setError("Both password not match");
    }
    setLoading(false);
  };

  const handleVerifyOtp = async (e) => {
    setLoading(true);
    e.preventDefault();
    await verifyRegisterOtp(user, setError, navigate);
    setLoading(false);
  };



  // =======================================================================

  
  const id = localStorage.getItem("invoician-user-id")
  const token = localStorage.getItem("invoician-token")
  
  if (token || id) return <Navigate to="/app"/>

  return (
    <div>
      {/* {loading ? <LoadingComponent /> : ""} */}

      {
  timerPopupAlert?
  <TimerPopupAlertComponent message={timerAlertMessage} setTimerPopupAlert={setTimerPopupAlert}/>
  :""
}
      <div className="register-page">
        <div className="register-page-main">
          <div className="register-page-content">
            <div className="register-page-head">
              <div className="register-page-head-logo">
                <img src={logo} alt="invocian-logo" title="invoician logo" />
              </div>
              <div className="register-page-head-heading-1">
                {otpTransfer ? "OTP Sent On Mail" : "Register New Account"}
              </div>
            </div>

            <div className="register-page-form">
              {otpTransfer ? (
                <form onSubmit={handleVerifyOtp}>
                  <input
                    required
                    value={user.userName}
                    className="cursor-disable"
                    type="text"
                    placeholder="Full Name"
                  />
                  <input
                    required
                    value={user.email}
                    className="cursor-disable"
                    type="email"
                    placeholder="Email"
                  />
                  <input
                    required
                    onChange={handleChange}
                    value={user.otp}
                    name="otp"
                    type="text"
                    placeholder="Confirm OTP"
                  />
                  {error ? (
                    <div className="register-page-head-error">{error}</div>
                  ) : (
                    ""
                  )}
                    {
              loading ?
      <div className="loading-button"> <div></div> Register <div class="button-loader"></div> </div>
              :
              <button>Register</button>
            }
                  {/* <button>Register</button> */}
                  <div className="register-page-form-end">
                    <Link onClick={(e) => setOtpTransfer(false)}>
                    Incorrect email addresses?
                    </Link>
                  </div>
                </form>
              ) : (
                <form onSubmit={handleRegisterOtp}>
                  <input
                    required
                    onChange={handleChange}
                    name="userName"
                    type="text"
                    placeholder="Full Name"
                  />
                  <input
                    required
                    onChange={handleChange}
                    name="email"
                    type="email"
                    placeholder="Email"
                  />
                  <input
                    required
                    onChange={handleChange}
                    name="password"
                    type="password"
                    placeholder="Create Password"
                  />
                  <input
                    required
                    onChange={handleChange}
                    name="confirmPassword"
                    type="password"
                    placeholder="Confirm Password"
                  />
                  {error ? (
                    <div className="register-page-head-error">{error}</div>
                  ) : (
                    ""
                  )}
                  {/* <button>Request Otp</button> */}

                  {
              loading ?
      <div className="loading-button"> <div></div> Request Otp  <div class="button-loader"></div> </div>
              :
              <button>Request Otp</button>
            }
                  
                  <div className="">
                    Already have an account? <Link to="/login">Login</Link>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserRegisterPage;
