import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import CreatePartyPopup from "./../CreatePartyPopup";

import CreateItemPopup from "./../CreateItemPopup";

const UpdateDeliveryChallanComponent = ({
  open,
  invoiceData,
  fetchData,
  // fetchItemData,
  id,
  setSingleFetchData,
  setTimerPopupAlert,
  setTimerAlertMessage,
  businessData,
  handleInvoiceHeight,
  setTimerAlertStatus,
  

  // businessData,
  fetchBusinessData,
  // invoiceData,
}) => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  // useStates  ================================================================================================================

  const [loading, setLoading] = useState(false);

  const [selectParty, setSelectParty] = useState(false);
  const [partyData, setPartyData] = useState(
    invoiceData.invoicePartyData ? invoiceData.invoicePartyData : {}
  );
  const [searchParty, setSearchParty] = useState("");

  const [createParty, setCreateParty] = useState("");

  const [selectItem, setSelectItem] = useState(false);
  const [selectedItems, setSelectedItems] = useState(
    invoiceData.invoiceItemsData ? invoiceData.invoiceItemsData : []
  );
  const [searchItem, setSearchItem] = useState("");

  const [createItem, setCreateItem] = useState(false);

  const [AddNotes, setAddNotes] = useState(
    invoiceData.invoiceNotes ? true : false
  );
  const [AddTerms, setAddTerms] = useState(
    invoiceData.invoiceTermsConditions ? true : false
  );

  const [AddExtraDiscount, setAddExtraDiscount] = useState(
    invoiceData.invoiceExtraDiscount ? true : false
  );

  const [roundOff, setRoundOff] = useState(
    invoiceData.roundOff ? invoiceData.roundOff : false
  );
  const [fullyPaid, setFullyPaid] = useState(
    invoiceData.fullyPaid ? invoiceData.fullyPaid : false
  );

  const [editingDueDate, setEditingDueDate] = useState(null);


  const today = new Date();

  const dueDate = new Date(today);
  dueDate.setDate(today.getDate() + 30);

  const [data, setData] = useState({
    invoiceNumber: invoiceData.invoiceNumber,
    invoiceDate: invoiceData.invoiceDate,
    invoiceEnableDueDate: invoiceData.invoiceEnableDueDate,
    invoiceDueDate: invoiceData.invoiceDueDate,
    invoicePartyData: invoiceData.invoicePartyData,
    invoiceItemsData: invoiceData.invoiceItemsData,
    invoiceExtraDiscount: invoiceData.invoiceExtraDiscount,
    roundOff: invoiceData.roundOff,
    fullyPaid: invoiceData.fullyPaid,
    receivedAmount: invoiceData.receivedAmount,
    paymentType: invoiceData.paymentType,
    invoiceNotes: invoiceData.invoiceNotes,
    invoiceTermsConditions: invoiceData.invoiceTermsConditions,
    invoiceSubTotalAmount: invoiceData.invoiceSubTotalAmount,
    invoiceTotalAmount: invoiceData.invoiceTotalAmount,
    invoiceDetailsOptions: invoiceData.invoiceDetailsOptions?invoiceData.invoiceDetailsOptions:{},
    selectedBankAccount: invoiceData.selectedBankAccount?invoiceData.selectedBankAccount:"",
    invoicePoNo: invoiceData.invoicePoNo?invoiceData.invoicePoNo:"",
    invoiceVehicleNo: invoiceData.invoiceVehicleNo?invoiceData.invoiceVehicleNo:"",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  // --------------------------------------

  const handleDueDate = (e) => {
    const value = e.target.value;

    const invDate = new Date(data.invoiceDate);
    const newDate = new Date(invDate);
    newDate.setDate(invDate.getDate() + Number(value));

    setData({
      ...data,
      invoiceDueDate: newDate.toISOString().split("T")[0],
    });
  };

  // ====================================================================================================================

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission on Enter
    }
  };

  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };

  // ====================================================================================================================
  // ====================================================================================================================

  // ====================================================================================================================

  const selectPartyRef = useRef(null);
  const partyListRef = useRef(null);

  const handleClickOutside = (event) => {
    // Check if the click is outside the button and dropdown
    if (
      partyListRef.current &&
      !partyListRef.current.contains(event.target) &&
      selectPartyRef.current &&
      !selectPartyRef.current.contains(event.target)
    ) {
      setSelectParty(false);
      setSearchParty("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const selectTaxRef = useRef(null);
  const taxListRef = useRef(null);

  const handleTaxOutside = (event) => {
    // Check if the click is outside the button and dropdown
    if (
      taxListRef.current &&
      !taxListRef.current.contains(event.target) &&
      selectTaxRef.current &&
      !selectTaxRef.current.contains(event.target)
    ) {
      setEditingTax(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTaxOutside);

    return () => {
      document.removeEventListener("mousedown", handleTaxOutside);
    };
  }, []);

  // ====================================================================================================================

  const inputSelectRef1 = useRef(null);
  const inputSelectRef2 = useRef(null);

  const handleSelectFocus = (ref) => {

    if (ref.current) {
      ref.current.select();
    }
  };

  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);

  const handleFocus = (ref) => {
    if (ref.current) {
      ref.current.select();
    }
  };

  // ====================================================================================================================

  const handleAddItem = (item) => {
    if (!selectedItems.some((p) => p._id === item._id)) {
      setSelectedItems([
        ...selectedItems,
        {
          ...item,
          quantity: 1,
          discount: 0,
          itemGst:
            item.itemTax === "0"
              ? 0
              : item.itemTax === "0.1"
              ? 0.1
              : item.itemTax === "0.25"
              ? 0.25
              : item.itemTax === "1.5"
              ? 1.5
              : item.itemTax === "3"
              ? 3
              : item.itemTax === "5"
              ? 5
              : item.itemTax === "6"
              ? 6
              : item.itemTax === "12"
              ? 12
              : item.itemTax === "13.8"
              ? 13.8
              : item.itemTax === "14,12"
              ? 14
              : item.itemTax === "18"
              ? 18
              : item.itemTax === "28"
              ? 28
              : item.itemTax === "28,12"
              ? 28
              : item.itemTax === "28,60"
              ? 28
              : 0,
          cess:
            item.itemTax === "14,12"
              ? 12
              : item.itemTax === "28,12"
              ? 12
              : item.itemTax === "28,60"
              ? 60
              : 0,
        },
      ]);
    }
  };

  const handleRemoveItem = (itemId) => {
    setSelectedItems(selectedItems.filter((p) => p._id !== itemId));
  };

  const handleIncreaseQuanitiy = (e) => {
    const updatedQuantity = selectedItems.map((item) =>
      item._id === e._id ? { ...item, quantity: e.quantity + 1 } : item
    );
    setSelectedItems(updatedQuantity);
  };

  const handleDecreaseQuanitiy = (e) => {
    const updatedQuantity = selectedItems.map((item) =>
      item._id === e._id ? { ...item, quantity: e.quantity - 1 } : item
    );
    setSelectedItems(updatedQuantity);
  };

  const handleQuantityChange = (id, value) => {
    if ((value >= 1 && value <= 10000) || value === "") {
      if (value.trim() === "") {
        const updatedQuantity = selectedItems.map((item) =>
          item._id === id ? { ...item, quantity: 0 } : item
        );
        setSelectedItems(updatedQuantity);
      } else {
        const updatedQuantity = selectedItems.map((item) =>
          item._id === id
            ? { ...item, quantity: value.replace(/^0+/, "") }
            : item
        );
        setSelectedItems(updatedQuantity);
      }
    }
  };

  const handlePriceChange = (id, value) => {
    const price = parseFloat(value);
    if (value.trim() === "") {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id ? { ...item, itemPrice: 0 } : item
      );
      setSelectedItems(updatedPrice);
    } else {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id ? { ...item, itemPrice: price } : item
      );
      setSelectedItems(updatedPrice);
    }
  };

  const handleDiscountPercentChange = (id, value) => {
    // const val = parseFloat(value);

    if ((value >= 0 && value <= 100) || value === "") {
      if (value.trim() === "") {
        const updatedDiscount = selectedItems.map((item) =>
          item._id === id ? { ...item, discount: 0 } : item
        );
        setSelectedItems(updatedDiscount);
      } else {
        const updatedDiscount = selectedItems.map((item) =>
          item._id === id
            ? { ...item, discount: parseFloat(value.replace(/^0+/, "")) }
            : item
        );
        setSelectedItems(updatedDiscount);
      }
    }
  };

  const handleDiscountChange = (id, value, price) => {
    const val = (parseFloat(value) * 100) / parseFloat(price);

    if (value.trim() === "") {
      const updatedDiscount = selectedItems.map((item) =>
        item._id === id ? { ...item, discount: 0 } : item
      );
      setSelectedItems(updatedDiscount);
    } else {
      const updatedDiscount = selectedItems.map((item) =>
        item._id === id ? { ...item, discount: val } : item
      );
      setSelectedItems(updatedDiscount);
    }
  };

  const handleTaxChange = (id, value) => {

    if (value === "14,12") {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id ? { ...item, itemGst: 14, cess: 12 } : item
      );
      setSelectedItems(updatedPrice);
    } else if (value === "28,12") {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id ? { ...item, itemGst: 28, cess: 12 } : item
      );
      setSelectedItems(updatedPrice);
    } else if (value === "28,60") {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id ? { ...item, itemGst: 28, cess: 60 } : item
      );
      setSelectedItems(updatedPrice);
    } else {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id ? { ...item, itemGst: value, cess: 0 } : item
      );
      setSelectedItems(updatedPrice);
    }
  };

  const handleTotalChange = (id, value, quantity, tax, cess, discount) => {
    const total = parseFloat(value);
    const product = selectedItems.find((p) => p._id === id);

    if (value.trim() === "") {
      const updatedProducts = selectedItems.map((product) =>
        product._id === id ? { ...product, itemPrice: 0 } : product
      );
      setSelectedItems(updatedProducts);
    } else {
      const basePrice = total / (1 + product.itemGst / 100);

      const price =
        total /
        (quantity * (1 + tax / 100 + cess / 100) * (1 - discount / 100));

      const updatedProducts = selectedItems.map((product) =>
        product._id === id ? { ...product, itemPrice: price } : product
      );
      setSelectedItems(updatedProducts);
    }
  };

  const calculateTaxedPrice = (price, taxRate, quantity, discount, cess) => {
    const discountedPrice = price - (price * discount) / 100;

    return (
      (discountedPrice +
        (discountedPrice * taxRate) / 100 +
        (cess * discountedPrice) / 100) *
      quantity
    );
  };

  const subTotal = selectedItems.reduce((acc, item) => {
    return (
      acc +
      calculateTaxedPrice(
        item.itemPrice,
        item.itemGst,
        item.quantity,
        item.discount,
        item.cess
      )
    );
  }, 0);

  const totalTax = selectedItems.reduce((acc, item) => {
    return (
      acc +
      (((item.itemPrice - (item.discount * item.itemPrice) / 100) *
        item.itemGst) /
        100 +
        ((item.itemPrice - (item.discount * item.itemPrice) / 100) *
          item.cess) /
          100) *
        item.quantity
    );
  }, 0);

  const totalDiscount = selectedItems.reduce((acc, item) => {
    return acc + (item.itemPrice * item.discount * item.quantity) / 100;
  }, 0);

  const totalGst = selectedItems.reduce((acc, item) => {
    return (
      acc +
      (((item.itemPrice - (item.discount * item.itemPrice) / 100) *
        item.itemGst) /
        100) *
        item.quantity
    );
  }, 0);

  const totalCess = selectedItems.reduce((acc, item) => {
    return (
      acc +
      (((item.itemPrice - (item.discount * item.itemPrice) / 100) * item.cess) /
        100) *
        item.quantity
    );
  }, 0);

  const taxableAmount = selectedItems.reduce((acc, item) => {
    return (
      acc +
      (item.itemPrice * item.quantity -
        (item.itemPrice * item.discount * item.quantity) / 100)
    );
  }, 0);

  const totalAmount = roundOff
    ? Math.round(subTotal - data.invoiceExtraDiscount)
    : formatNumber(subTotal - data.invoiceExtraDiscount);

  // ====================================================================================================================
  // ====================================================================================================================

  const [editingQuantity, setEditingQuantity] = useState(null);
  const [editingDiscount, setEditingDiscount] = useState(null);
  const [editingDiscountPercent, setEditingDiscountPercent] = useState(null);
  const [editingPrice, setEditingPrice] = useState(null);
  const [editingTax, setEditingTax] = useState(null);
  const [editingTotalPrice, setEditingTotalPrice] = useState(null);

  const [editingExtraDiscount, setEditingExtraDiscount] = useState(null);
  const [editingExtraDiscountPercentage, setEditingExtraDiscountPercentage] =
    useState(null);

  const handleExtraDiscount = (e) => {
    const value = e.target.value;

    if ((value >= 0 && value <= subTotal) || value === "") {
      setData({
        ...data,
        invoiceExtraDiscount: Number(value),
      });
    }
  };

  const handleExtraDiscountPercentage = (e) => {
    const value = e.target.value;

    if ((value >= 0 && value <= 100) || value === "") {
      const dis = (subTotal * value) / 100;

      setData({
        ...data,
        invoiceExtraDiscount: dis,
      });
    }
  };

  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================

  const handleSaveData = () => {
    setData({
      ...data,
      fullyPaid: fullyPaid,
      roundOff: roundOff,
      invoicePartyData: partyData,
      invoiceItemsData: selectedItems,
      invoiceSubTotalAmount: subTotal,
      invoiceTotalAmount: totalAmount * 1,
      receivedAmount: fullyPaid ? totalAmount * 1 : data.receivedAmount,
      
      invoiceDetailsOptions: Object.fromEntries(
        Object.entries(detailsOptionValue).filter(([key, value]) => value)
      ),
    });

    console.log("data is : ", data);
  };

  const { updateDeliveryChallan, link } = useContext(AuthContext);

  const handleUpdate = async (e) => {
    setLoading(true);
    e.preventDefault();

    await updateDeliveryChallan(
      userId,
      businessId,
      id,
      data,
      setSingleFetchData,
      setTimerPopupAlert,
      setTimerAlertMessage,
      setTimerAlertStatus,
      open
    );
    handleInvoiceHeight()
    fetchData();

    setLoading(false);
  };

  

   // =========================================================================================================================
  // =========================================================================================================================
  // =========================================================================================================================

  const [detailsOptionValue, setdetailsOptionValue] = useState({...invoiceData.invoiceDetailsOptions, ...{}});

  // Update the value based on input changes

  const handleDetailsOptions = (e) => {
    const { name, value } = e.target;
    setdetailsOptionValue((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    console.log("new values are : ", detailsOptionValue);

    
  };

  // =========================================================================================================================
  // =========================================================================================================================
  // =========================================================================================================================
  // =========================================================================================================================

  
  

  return (
    <div>
      {createParty ? (
        <CreatePartyPopup
          open={setCreateParty}
          invoicePartyData={setPartyData}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          setTimerAlertStatus={setTimerAlertStatus}
          fetchBusinessData={fetchBusinessData}
        />
      ) : (
        ""
      )}

      {createItem ? (
        <CreateItemPopup
          open={setCreateItem}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          setTimerAlertStatus={setTimerAlertStatus}
          fetchBusinessData={fetchBusinessData}
        />
      ) : (
        ""
      )}



      <form onSubmit={handleUpdate}>
        <div className="create-invoice-page">
          <div className="create-invoice-page-main">
            {/* top ----------------------------------------------------------------------------------------------------------- */}

            <div className="create-invoice-page-main-top">
              <div className="create-invoice-page-main-top-heading">
                <i
                  class="fa-solid fa-arrow-left"
                  onClick={e=>open(false)}
                ></i>
                Update delivery challan
              </div>
              <div className="create-invoice-page-main-top-buttons">
                <div className="create-invoice-page-main-top-button-setting">
                  Settings <i class="fa-solid fa-gear"></i>
                </div>


                {partyData && selectedItems.length > 0 ? (
                  <button onClick={handleSaveData}>Update</button>
                ) : (
                  <div className="create-invoice-page-main-top-button-save">
                    Save
                  </div>
                )}
              </div>
            </div>

            <div className="create-invoice-page-form">
              {/* content ----------------------------------------------------------------------------------------------------------- */}
              <div className="create-invoice-page-content">
                {/* head ----------------------------------------------------------------------------------------------------------- */}

                <div className="create-invoice-page-head">
                  <div className="create-invoice-page-head-address">
                    <div className="create-invoice-page-head-billing-address">
                      <div className="create-invoice-page-head-address-head">
                        <span>Bill To</span>
                        {partyData ? (
                          <div onClick={() => setPartyData("")}>
                            Change Party
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      {partyData ? (
                        <div className="create-invoice-page-head-address-content">
                          {partyData.partyName ? (
                            <div className="create-invoice-page-head-address-name">
                              {partyData.partyName}
                            </div>
                          ) : (
                            ""
                          )}

                          {partyData.partyBillingAddress ||
                          partyData.partyBillingCity ||
                          partyData.partyBillingState ||
                          partyData.partyBillingPinCode ? (
                            <div className="create-invoice-page-head-address-col">
                              <span>Address</span>
                              <div>
                                {partyData.partyBillingAddress}
                                {partyData.partyBillingCity ? (
                                  <>, {partyData.partyBillingCity}</>
                                ) : (
                                  ""
                                )}
                                {partyData.partyBillingState ? (
                                  <>, {partyData.partyBillingState}</>
                                ) : (
                                  ""
                                )}
                                {partyData.partyBillingPinCode ? (
                                  <> - {partyData.partyBillingPinCode}</>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          {partyData.partyNumber ? (
                            <div className="create-invoice-page-head-address-col">
                              <span>Phone Number</span>
                              <div>{partyData.partyNumber}</div>
                            </div>
                          ) : (
                            ""
                          )}

                          {partyData.partyPanNumber ? (
                            <div className="create-invoice-page-head-address-col">
                              <span>PAN Number</span>
                              <div>{partyData.partyPanNumber}</div>
                            </div>
                          ) : (
                            ""
                          )}

                          {partyData.partyGSTIN ? (
                            <div className="create-invoice-page-head-address-col">
                              <span>GSTIN</span>
                              <div>{partyData.partyGSTIN}</div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        <>
                          {selectParty ? (
                            <div
                              className="create-invoice-page-head-add-party"
                              ref={partyListRef}
                            >
                              <div className="create-invoice-page-head-add-party-search">
                                <input
                                  onKeyDown={handleKeyDown}
                                  type="text"
                                  autoFocus
                                  placeholder="Search party by name"
                                  value={searchParty}
                                  onChange={(e) =>
                                    setSearchParty(e.target.value)
                                  }
                                />
                              </div>
                              <div className="create-invoice-page-head-add-party-head">
                                <div>Party Name</div>
                                <div>Balance</div>
                              </div>
                              <div className="create-invoice-page-head-add-party-data">
                                {businessData.partyData
                                  .filter((e) =>
                                    e.partyName
                                      .toLocaleLowerCase()
                                      .includes(searchParty.toLowerCase())
                                  )
                                  .reverse()
                                  .map((e) => (
                                    <div
                                      className="create-invoice-page-head-add-party-data-row"
                                      onClick={() => {
                                        setPartyData(e);
                                      }}
                                    >
                                      <div>{e.partyName}</div>
                                      <div>
                                        {e.partyBalance === 0 ? (
                                          0.0
                                        ) : e.partyBalance > 0 ? (
                                          <>
                                            ₹ 100{" "}
                                            <i class="fa-solid fa-arrow-down"></i>
                                          </>
                                        ) : e.partyBalance < 0 ? (
                                          <>
                                            ₹ 200{" "}
                                            <i class="fa-solid fa-arrow-up"></i>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  ))}
                              </div>

                              {businessData.partyData.filter((e) =>
                                e.partyName
                                  .toLocaleLowerCase()
                                  .includes(searchParty.toLowerCase())
                              ).length <= 0 ? (
                                <div className="create-invoice-page-head-add-party-data-empty">
                                  No Party Found
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="create-invoice-page-head-add-party-create">
                                <div
                                  onClick={() => {
                                    setCreateParty(true);
                                    setSelectParty(false);
                                  }}
                                >
                                  + Create Party
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          <div
                            ref={selectPartyRef}
                            className="create-invoice-page-head-add-party-option"
                            onClick={() => setSelectParty(true)}
                          >
                            + Add Party
                          </div>
                        </>
                      )}
                    </div>

                    {partyData ? (
                      <>
                        {partyData.partyBothAddressSame ? (
                          <>
                            <div className="create-invoice-page-head-shipping-address">
                              <div className="create-invoice-page-head-address-head">
                                <span>Shipping To</span>
                              </div>
                              <div className="create-invoice-page-head-address-content">
                                {partyData.partyName ? (
                                  <div className="create-invoice-page-head-address-name">
                                    {partyData.partyName}
                                  </div>
                                ) : (
                                  ""
                                )}

                                {partyData.partyBillingAddress ||
                                partyData.partyBillingCity ||
                                partyData.partyBillingState ||
                                partyData.partyBillingPinCode ? (
                                  <div className="create-invoice-page-head-address-col">
                                    <span>Address</span>
                                    <div>
                                      {partyData.partyBillingAddress}
                                      {partyData.partyBillingCity ? (
                                        <>, {partyData.partyBillingCity}</>
                                      ) : (
                                        ""
                                      )}
                                      {partyData.partyBillingState ? (
                                        <>, {partyData.partyBillingState}</>
                                      ) : (
                                        ""
                                      )}
                                      {partyData.partyBillingPinCode ? (
                                        <> - {partyData.partyBillingPinCode}</>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {partyData.partyNumber ? (
                                  <div className="create-invoice-page-head-address-col">
                                    <span>Phone Number</span>
                                    <div>{partyData.partyNumber}</div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {partyData.partyShippingAddress ||
                            partyData.partyShippingCity ||
                            partyData.partyShippingState ||
                            partyData.partyShippingPinCode ? (
                              <div className="create-invoice-page-head-shipping-address">
                                <div className="create-invoice-page-head-address-head">
                                  <span>Shipping To</span>
                                </div>
                                <div className="create-invoice-page-head-address-content">
                                  <div className="create-invoice-page-head-address-name">
                                    {partyData.partyName}
                                  </div>

                                  <div className="create-invoice-page-head-address-col">
                                    <span>Address</span>
                                    <div>
                                      {partyData.partyShippingAddress
                                        ? partyData.partyShippingAddress
                                        : ""}

                                      {partyData.partyShippingCity ? (
                                        <>, {partyData.partyShippingCity}</>
                                      ) : (
                                        ""
                                      )}

                                      {partyData.partyShippingState ? (
                                        <>, {partyData.partyShippingState}</>
                                      ) : (
                                        ""
                                      )}

                                      {partyData.partyShippingPinCode ? (
                                        <>, {partyData.partyShippingPinCode}</>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>

                                  {partyData.partyNumber ? (
                                    <div className="create-invoice-page-head-address-col">
                                      <span>Phone Number</span>
                                      <div>{partyData.partyNumber}</div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="create-invoice-page-head-options">
                    <div className="create-invoice-page-head-options-row">
                      <div className="create-invoice-page-head-options-col">
                        <label>Sales Invoice No:</label>
                        <input
                          onKeyDown={handleKeyDown}
                          required
                          type="number"
                          onChange={handleChange}
                          name="invoiceNumber"
                          value={data.invoiceNumber}
                        />
                      </div>

                      <div className="create-invoice-page-head-options-col">
                        <label>Sales Invoice Date:</label>
                        <input
                          onKeyDown={handleKeyDown}
                          onChange={handleChange}
                          name="invoiceDate"
                          type="date"
                          id=""
                          value={data.invoiceDate}
                        />
                      </div>
                    </div>

                    {data.invoiceEnableDueDate ? (
                      <div className="create-invoice-page-head-options-due">
                        <div className="create-invoice-page-head-options-due-close">
                          <i
                            class="fa-solid fa-circle-xmark"
                            onClick={() =>
                              setData({
                                ...data,
                                invoiceEnableDueDate: false,
                              })
                            }
                          ></i>
                        </div>
                        <div className="create-invoice-page-head-options-row">
                          <div className="create-invoice-page-head-options-col">
                            <label>Payment Terms:</label>
                            <div className="create-invoice-page-head-options-terms">
                              {editingDueDate ? (
                                <input
                                  onKeyDown={handleKeyDown}
                                  onBlur={() => setEditingDueDate(false)}
                                  autoFocus
                                  type="number"
                                  onChange={handleDueDate}
                                  defaultValue={Math.ceil(
                                    Math.abs(
                                      new Date(data.invoiceDueDate) -
                                        new Date(data.invoiceDate)
                                    ) /
                                      (1000 * 60 * 60 * 24)
                                  )}
                                />
                              ) : (
                                <input
                                  onFocus={() => setEditingDueDate(true)}
                                  type="text"
                                  value={Math.ceil(
                                    Math.abs(
                                      new Date(data.invoiceDueDate) -
                                        new Date(data.invoiceDate)
                                    ) /
                                      (1000 * 60 * 60 * 24)
                                  )}
                                />
                              )}
                              <span>days</span>
                            </div>
                          </div>

                          <div className="create-invoice-page-head-options-col">
                            <label>Due Date:</label>
                            <input
                              onKeyDown={handleKeyDown}
                              onChange={handleChange}
                              name="invoiceDueDate"
                              type="date"
                              id=""
                              value={data.invoiceDueDate}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="create-invoice-page-head-options-due-option"
                        onClick={() =>
                          setData({
                            ...data,
                            invoiceEnableDueDate: true,
                          })
                        }
                      >
                        + Add Due Date
                      </div>
                    )}


                     {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}

                     {businessData.invoiceSettingData.invoiceDetailsOptions ? (
                      <div className="create-invoice-page-head-options-row">
                        {businessData.invoiceSettingData.invoiceDetailsOptions
                         .filter(val => !(val in data.invoiceDetailsOptions))
                        .map(
                          (e) => (
                            <div className="create-invoice-page-head-options-col">
                              <label>{e}:</label>
                              <input
                                type="text"
                                name={e}
                                value={detailsOptionValue[e]}
                                onChange={(e) => handleDetailsOptions(e)}                               
                              /> 
                            </div>
                          )
                        )}


           
{
  businessData.invoiceSettingData?
  businessData.invoiceSettingData.invoicePoNoOptions?

                    <div className="create-invoice-page-head-options-col">
                              <label>P.O. No.</label>
                              <input
                                type="text"
                                onChange={handleChange}
                                name="invoicePoNo"
                                value={data.invoicePoNo}
                              /> 
                            </div>
                    
                    :""
                    :""
                    }
{
  businessData.invoiceSettingData?
  businessData.invoiceSettingData.invoiceVehicleNumberOptions?

                    <div className="create-invoice-page-head-options-col">
                              <label>Vehicle No.</label>
                              <input
                                type="text"
                                onChange={handleChange}
                                name="invoiceVehicleNo"
                                value={data.invoiceVehicleNo}
                              /> 
                            </div>
                    
                    :""
                    :""}
                    

                        
                      </div>
                    ) : (
                      ""
                    )}
{/* --------------------------------------------- */}


<div className="create-invoice-page-head-options-row">
{
  data.invoiceDetailsOptions&&Object.entries(data.invoiceDetailsOptions)
  .map(([key, value]) => (
                            <div className="create-invoice-page-head-options-col">

                            <label>{key}:</label>
                            <input
                                type="text"
                                name={key}
                                value={detailsOptionValue[key]}
                                onChange={(e) => handleDetailsOptions(e)}
                              /> 
                            
                          </div>
    
    
  ))
  
}
  </div>


                    {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
                  



                    
                  </div>
                </div>

                {/* ----------------------------------------------------------------------------------------------------------- */}

                {/* table ----------------------------------------------------------------------------------------------------------- */}
                <div className="create-invoice-page-table">
                  <div className="create-invoice-page-table-head">
                    <div className="create-invoice-page-table-sno">No</div>
                    <div className="create-invoice-page-table-items">Items</div>
                    <div className="create-invoice-page-table-hsn">HSN/SAC</div>
                    <div className="create-invoice-page-table-qty">QTY</div>
                    <div className="create-invoice-page-table-price">
                      PRICE/ITEM (₹)
                    </div>
                    <div className="create-invoice-page-table-discount">
                      DISCOUNT
                    </div>
                    <div className="create-invoice-page-table-tax">TAX</div>
                    <div className="create-invoice-page-table-total">
                      AMOUNT (₹)
                    </div>
                    <div className="create-invoice-page-table-add">
                      <i class="fa-solid fa-plus"></i>
                    </div>
                  </div>

                  {selectedItems.map((item, i) => (
                    <div
                      key={item._id}
                      className="create-invoice-page-table-body"
                    >
                      <div className="create-invoice-page-table-sno">{i+1}</div>

                      <div className="create-invoice-page-table-items">
                        {item.itemName}
                      </div>

                      <div className="create-invoice-page-table-hsn">
                        {item.itemCode ? item.itemCode : "-"}
                      </div>

                      <div className="create-invoice-page-table-qty">
                        {editingQuantity === item._id ? (
                          <input
                            onKeyDown={handleKeyDown}
                            type="number"
                            value={item.quantity}
                            placeholder="Enter new price"
                            onChange={(e) =>
                              handleQuantityChange(item._id, e.target.value)
                            }
                            onBlur={() => setEditingQuantity(null)}
                            autoFocus
                            min={0}
                          />
                        ) : (
                          <input
                            onFocus={() => setEditingQuantity(item._id)}
                            type="number"
                            min={0}
                            value={item.quantity}
                          />
                        )}
                        &nbsp;
                        {item.itemMeasuringUnit ? item.itemMeasuringUnit : ""}
                      </div>

                      <div className="create-invoice-page-table-price">
                        {editingPrice === item._id ? (
                          <input
                            onKeyDown={handleKeyDown}
                            type="number"
                            defaultValue={formatNumber(item.itemPrice)}
                            placeholder="Enter new price"
                            onChange={(e) =>
                              handlePriceChange(item._id, e.target.value)
                            }
                            onBlur={() => setEditingPrice(null)}
                            autoFocus
                            min={0}
                          />
                        ) : (
                          <input
                            step="0.01"
                            onFocus={() => setEditingPrice(item._id)}
                            type="number"
                            min={0}
                            value={formatNumber(item.itemPrice)}
                          />
                        )}
                      </div>

                      <div className="create-invoice-page-table-discount">
                        <div>
                          {editingDiscountPercent === item._id ? (
                            <>
                              {" "}
                              %
                              <input
                                onKeyDown={handleKeyDown}
                                type="number"
                                value={formatNumber(item.discount)}
                                onChange={(e) =>
                                  handleDiscountPercentChange(
                                    item._id,
                                    e.target.value
                                  )
                                }
                                onBlur={() => setEditingDiscountPercent(null)}
                                autoFocus
                                min={0}
                                max={100}
                              />
                            </>
                          ) : (
                            <>
                              %{" "}
                              <input
                                type="text"
                                onClick={() =>
                                  setEditingDiscountPercent(item._id)
                                }
                                onFocus={() =>
                                  setEditingDiscountPercent(item._id)
                                }
                                value={formatNumber(item.discount)}
                              />
                            </>
                          )}
                        </div>
                        <div>
                          {editingDiscount === item._id ? (
                            <>
                              ₹
                              <input
                                onKeyDown={handleKeyDown}
                                type="number"
                                defaultValue={formatNumber(
                                  (item.itemPrice *
                                    item.discount *
                                    item.quantity) /
                                    100
                                )}
                                onChange={(e) =>
                                  handleDiscountChange(
                                    item._id,
                                    e.target.value,
                                    item.itemPrice,
                                    item.quantity
                                  )
                                }
                                onBlur={() => setEditingDiscount(null)}
                                autoFocus
                                min={0}
                                max={100}
                              />
                            </>
                          ) : (
                            <>
                              ₹{" "}
                              <input
                                type="text"
                                onClick={() => setEditingDiscount(item._id)}
                                onFocus={() => setEditingDiscount(item._id)}
                                value={formatNumber(
                                  (item.itemPrice *
                                    item.discount *
                                    item.quantity) /
                                    100
                                )}
                              />
                            </>
                          )}
                        </div>
                      </div>

                      <div className="create-invoice-page-table-tax">
                        <input
                          type="text"
                          name=""
                          id=""
                          ref={selectTaxRef}
                          onClick={() => setEditingTax(item._id)}
                          value={formatNumber(
                            (((item.itemPrice -
                              (item.discount * item.itemPrice) / 100) *
                              item.itemGst) /
                              100 +
                              ((item.itemPrice -
                                (item.discount * item.itemPrice) / 100) *
                                item.cess) /
                                100) *
                              item.quantity
                          )}
                        />

                        {editingTax === item._id ? (
                          <div
                            ref={taxListRef}
                            className="create-invoice-page-table-tax-select"
                          >
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 0);
                                setEditingTax(null);
                              }}
                            >
                              None
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 0);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 0%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 0.1);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 0.1%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 0.25);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 0.25%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 1.5);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 1.5%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 3);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 3%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 5);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 5%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 6);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 6%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 12);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 12%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 13.8);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 13.8%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 18);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 18%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, "14,12");
                                setEditingTax(null);
                              }}
                            >
                              GST @ 14% + cess @ 12%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, 28);
                                setEditingTax(null);
                              }}
                            >
                              GST @ 28%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, "28,12");
                                setEditingTax(null);
                              }}
                            >
                              GST @ 28% + Cess @ 12%
                            </div>
                            <div
                              className="create-invoice-page-table-tax-option"
                              onClick={(e) => {
                                handleTaxChange(item._id, "28,60");
                                setEditingTax(null);
                              }}
                            >
                              GST @ 28% + Cess @ 60%
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div>
                          (<>{item.itemGst}%</>
                          {item.cess ? <> + {item.cess} Cess</> : ""})
                        </div>
                      </div>

                      <div className="create-invoice-page-table-total">
                        <div>
                          {editingTotalPrice === item._id ? (
                            <>
                              ₹
                              <input
                                onKeyDown={handleKeyDown}
                                defaultValue={formatNumber(
                                  calculateTaxedPrice(
                                    item.itemPrice,
                                    item.itemGst,
                                    item.quantity,
                                    item.discount,
                                    item.cess
                                  )
                                )}
                                type="number"
                                placeholder="Enter total price"
                                onChange={(e) =>
                                  handleTotalChange(
                                    item._id,
                                    e.target.value,
                                    item.quantity,
                                    item.itemGst,
                                    item.cess,
                                    item.discount
                                  )
                                }
                                onBlur={() => setEditingTotalPrice(null)}
                                autoFocus
                                min={0}
                              />
                            </>
                          ) : (
                            <>
                              ₹
                              <input
                                type="number"
                                min={0}
                                value={formatNumber(
                                  calculateTaxedPrice(
                                    item.itemPrice,
                                    item.itemGst,
                                    item.quantity,
                                    item.discount,
                                    item.cess
                                  )
                                )}
                                onFocus={() => setEditingTotalPrice(item._id)}
                                onChange={() => setEditingTotalPrice(item._id)}
                                step="0.01"
                              />
                            </>
                          )}
                        </div>
                      </div>

                      <div className="create-invoice-page-table-del">
                        <i
                          class="fa-solid fa-trash"
                          onClick={() => handleRemoveItem(item._id)}
                        ></i>
                      </div>
                    </div>
                  ))}
                </div>

                <div
                  onClick={() => setSelectItem(true)}
                  className="create-invoice-page-table-add-item-option"
                >
                  + Add Item
                </div>

                {/* add item ----------------------------------------------------------------------------------------------------------- */}
                {selectItem ? (
                  <div className="create-invoice-page-table-add-item">
                    <div className="create-invoice-page-table-add-item-main">
                      <div className="create-invoice-page-table-add-item-top">
                        Add Items
                      </div>
                      <div className="create-invoice-page-table-add-item-head">
                        <div className="create-invoice-page-table-add-item-head-search">
                          <i class="fa-solid fa-magnifying-glass"></i>
                          <input
                            onKeyDown={handleKeyDown}
                            type="text"
                            placeholder="search items"
                            value={searchItem}
                            onChange={(e) => setSearchItem(e.target.value)}
                          />
                        </div>
                        <div
                          className="create-invoice-page-table-add-item-head-create"
                          onClick={() => {
                            setCreateItem(true);
                          }}
                        >
                          + Create New Item
                        </div>
                      </div>

                      <div className="create-invoice-page-table-add-item-table">
                        <div className="create-invoice-page-table-add-item-table-head">
                          <div className="create-invoice-page-table-add-item-table-name">
                            ITEM NAME
                          </div>
                          <div className="create-invoice-page-table-add-item-table-code">
                            ITEM CODE
                          </div>
                          <div className="create-invoice-page-table-add-item-table-sale">
                            SALE PRICE
                          </div>
                          <div className="create-invoice-page-table-add-item-table-purchase">
                            PURCHASE PRICE
                          </div>
                          <div className="create-invoice-page-table-add-item-table-stock">
                            CURRENT STOCK
                          </div>
                          <div className="create-invoice-page-table-add-item-table-quantity">
                            QUANTITY
                          </div>
                        </div>

                        <div className="create-invoice-page-table-add-item-table-body">
                          {selectedItems.map((item) => (
                            <div
                              key={item._id}
                              className="create-invoice-page-table-add-item-table-body-row"
                            >
                              <div className="create-invoice-page-table-add-item-table-name">
                                {" "}
                                <i
                                  class="fa-regular fa-square-check"
                                  onClick={() => handleRemoveItem(item._id)}
                                ></i>{" "}
                                {item.itemName}
                              </div>
                              <div className="create-invoice-page-table-add-item-table-code">
                                {item.itemCode ? item.itemCode : "-"}
                              </div>
                              <div className="create-invoice-page-table-add-item-table-sale">
                                {item.itemPrice ? (
                                  <>
                                    {item.itemTax === "0" ||
                                    item.itemTax === "0.1" ||
                                    item.itemTax === "0.25" ||
                                    item.itemTax === "1.5" ||
                                    item.itemTax === "3" ||
                                    item.itemTax === "5" ||
                                    item.itemTax === "6" ||
                                    item.itemTax === "12" ||
                                    item.itemTax === "13.8" ||
                                    item.itemTax === "18" ||
                                    item.itemTax === "28" ? (
                                      <>
                                        ₹{" "}
                                        {formatNumber(
                                          item.itemPrice +
                                            (item.itemPrice *
                                              Number(item.itemTax)) /
                                              100
                                        ) * 1}{" "}
                                      </>
                                    ) : item.itemTax === "14,12" ? (
                                      <>
                                        ₹{" "}
                                        {formatNumber(
                                          item.itemPrice +
                                            (item.itemPrice * 14) / 100 +
                                            (item.itemPrice * 12) / 100
                                        ) * 1}{" "}
                                      </>
                                    ) : item.itemTax === "28,12" ? (
                                      <>
                                        ₹{" "}
                                        {formatNumber(
                                          item.itemPrice +
                                            (item.itemPrice * 28) / 100 +
                                            (item.itemPrice * 12) / 100
                                        ) * 1}{" "}
                                      </>
                                    ) : item.itemTax === "28,60" ? (
                                      <>
                                        ₹{" "}
                                        {formatNumber(
                                          item.itemPrice +
                                            (item.itemPrice * 28) / 100 +
                                            (item.itemPrice * 60) / 100
                                        ) * 1}{" "}
                                      </>
                                    ) : (
                                      <>₹ {item.itemPrice}</>
                                    )}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </div>
                              <div className="create-invoice-page-table-add-item-table-purchase">
                                {item.itemPurchasePrice ? (
                                  <>₹ {item.itemPurchasePrice}</>
                                ) : (
                                  "-"
                                )}
                              </div>
                              <div className="create-invoice-page-table-add-item-table-stock">
                                <div>
                                  {" "}
                                  <div>
                                    {" "}
                                    {item.itemStock ? (
                                      <>
                                        {item.itemStock}{" "}
                                        {item.itemMeasuringUnit
                                          ? item.itemMeasuringUnit
                                          : ""}{" "}
                                      </>
                                    ) : (
                                      "-"
                                    )}
                                  </div>
                                </div>
                                {item.itemStock < 0 ? (
                                  <span>Insufficient Stock</span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="create-invoice-page-table-add-item-table-quantity">
                                <div className="create-invoice-page-table-add-item-table-quantity-added">
                                  {item.quantity <= 1 ? (
                                    <i
                                      onClick={() => handleRemoveItem(item._id)}
                                      class="fa-solid fa-square-minus"
                                    ></i>
                                  ) : (
                                    <i
                                      onClick={() =>
                                        handleDecreaseQuanitiy(item)
                                      }
                                      class="fa-solid fa-square-minus"
                                    ></i>
                                  )}

                                  <input
                                    type="number"
                                    autoFocus
                                    defaultValue={item.quantity}
                                    onChange={(e) =>
                                      handleQuantityChange(
                                        item._id,
                                        e.target.value
                                      )
                                    }
                                    min={0}
                                    value={item.quantity}
                                  />
                                  <i
                                    onClick={() => handleIncreaseQuanitiy(item)}
                                    class="fa-solid fa-square-plus"
                                  ></i>
                                  <div>
                                    {item.itemMeasuringUnit
                                      ? item.itemMeasuringUnit
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                          {businessData.itemData
                            .filter((e) =>
                              e.itemName
                                .toLocaleLowerCase()
                                .includes(searchItem.toLowerCase())
                            )

                            .filter(
                              (e) => !selectedItems.some((b) => e._id === b._id)
                            )
                            .reverse()
                            .map((item) => (
                              <div
                                key={item._id}
                                className="create-invoice-page-table-add-item-table-body-row"
                              >
                                <div className="create-invoice-page-table-add-item-table-name">
                                  {" "}
                                  <i
                                    class="fa-regular fa-square"
                                    onClick={() => handleAddItem(item)}
                                  ></i>{" "}
                                  {item.itemName}
                                </div>
                                <div className="create-invoice-page-table-add-item-table-code">
                                  {item.itemCode ? item.itemCode : "-"}
                                </div>
                                

                                <div className="create-invoice-page-table-add-item-table-sale">
                                  {item.itemPrice ? (
                                    <>
                                      {item.itemTax === "0" ||
                                      item.itemTax === "0.1" ||
                                      item.itemTax === "0.25" ||
                                      item.itemTax === "1.5" ||
                                      item.itemTax === "3" ||
                                      item.itemTax === "5" ||
                                      item.itemTax === "6" ||
                                      item.itemTax === "12" ||
                                      item.itemTax === "13.8" ||
                                      item.itemTax === "18" ||
                                      item.itemTax === "28" ? (
                                        <>
                                          ₹{" "}
                                          {formatNumber(
                                            item.itemPrice +
                                              (item.itemPrice *
                                                Number(item.itemTax)) /
                                                100
                                          ) * 1}{" "}
                                        </>
                                      ) : item.itemTax === "14,12" ? (
                                        <>
                                          ₹{" "}
                                          {formatNumber(
                                            item.itemPrice +
                                              (item.itemPrice * 14) / 100 +
                                              (item.itemPrice * 12) / 100
                                          ) * 1}{" "}
                                        </>
                                      ) : item.itemTax === "28,12" ? (
                                        <>
                                          ₹{" "}
                                          {formatNumber(
                                            item.itemPrice +
                                              (item.itemPrice * 28) / 100 +
                                              (item.itemPrice * 12) / 100
                                          ) * 1}{" "}
                                        </>
                                      ) : item.itemTax === "28,60" ? (
                                        <>
                                          ₹{" "}
                                          {formatNumber(
                                            item.itemPrice +
                                              (item.itemPrice * 28) / 100 +
                                              (item.itemPrice * 60) / 100
                                          ) * 1}{" "}
                                        </>
                                      ) : (
                                        <>₹ {item.itemPrice}</>
                                      )}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </div>

                                <div className="create-invoice-page-table-add-item-table-purchase">
                                  {item.itemPurchasePrice ? (
                                    <>₹ {item.itemPurchasePrice}</>
                                  ) : (
                                    "-"
                                  )}
                                </div>
                                <div className="create-invoice-page-table-add-item-table-stock">
                                  <div>
                                    {" "}
                                    {item.itemStock ? (
                                      <>
                                        {item.itemStock}{" "}
                                        {item.itemMeasuringUnit
                                          ? item.itemMeasuringUnit
                                          : ""}{" "}
                                      </>
                                    ) : (
                                      "-"
                                    )}
                                  </div>
                                </div>
                                <div className="create-invoice-page-table-add-item-table-quantity">
                                  <div
                                    onClick={() => handleAddItem(item)}
                                    className="create-invoice-page-table-add-item-table-quantity-add"
                                  >
                                    + Add
                                  </div>
                                </div>
                              </div>
                            ))}

                          {businessData.itemData.filter((e) =>
                            e.itemName
                              .toLocaleLowerCase()
                              .includes(searchItem.toLowerCase())
                          ).length <= 0 ? (
                            <div className="create-invoice-page-table-add-item-table-empty">
                              No Items found
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="create-invoice-page-table-add-item-button">
                        <div
                          onClick={() => {
                            setSelectItem(false);
                            setSearchItem("");
                          }}
                        >
                          Done
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="create-invoice-page-table-sub-total">
                  <div>SUBTOTAL</div>
                  <div>₹ {formatNumber(totalDiscount)}</div>
                  <div>₹ {formatNumber(totalTax)}</div>
                  <div>₹ {formatNumber(subTotal)}</div>
                </div>

                {/* ----------------------------------------------------------------------------------------------------------- */}

                <div className="create-invoice-page-end">
                  <div className="create-invoice-page-end-col-1">
                    {AddNotes ? (
                      <div className="create-invoice-page-end-form">
                        <div className="create-invoice-page-end-head">
                          <div>Notes</div>
                          <span>
                            <i
                              class="fa-regular fa-circle-xmark"
                              onClick={() => {
                                setAddNotes(false);
                                setData({
                                  ...data,
                                  invoiceNotes: "",
                                });
                              }}
                            ></i>
                          </span>
                        </div>
                        <textarea
                          name="invoiceNotes"
                          value={data.invoiceNotes}
                          onChange={handleChange}
                          placeholder="Enter your notes"
                        ></textarea>
                      </div>
                    ) : (
                      <div
                        className="create-invoice-page-end-link"
                        onClick={() => {
                          setAddNotes(true);
                        }}
                      >
                        + Add Notes
                      </div>
                    )}

                    {AddTerms || data.invoiceTermsConditions ? (
                      <div className="create-invoice-page-end-form">
                        <div className="create-invoice-page-end-head">
                          <div>Terms and Conditions</div>
                          <span>
                            <i
                              class="fa-regular fa-circle-xmark"
                              onClick={() => {
                                setAddTerms(false);
                                setData({
                                  ...data,
                                  invoiceTermsConditions: "",
                                });
                              }}
                            ></i>
                          </span>
                        </div>
                        <textarea
                          name="invoiceTermsConditions"
                          value={data.invoiceTermsConditions}
                          onChange={handleChange}
                          placeholder="Enter your terms and conditions"
                        ></textarea>
                      </div>
                    ) : (
                      <div
                        className="create-invoice-page-end-link"
                        onClick={() => {
                          setAddTerms(true);
                        }}
                      >
                        + Terms and Conditions
                      </div>
                    )}
                  </div>

                  <div className="create-invoice-page-end-amount">

                    <div className="create-invoice-page-end-amount-row">
                      <div>Taxable Amount</div>
                      <div>₹ {formatNumber(taxableAmount) * 1}</div>
                    </div>
                    {totalGst ? (
                      <>
                        <div className="create-invoice-page-end-amount-row">
                          <div>SGST</div>
                          <div>₹ {formatNumber(totalGst) / 2}</div>
                        </div>

                        <div className="create-invoice-page-end-amount-row">
                          <div>CGST</div>
                          <div>₹ {formatNumber(totalGst) / 2}</div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {totalCess ? (
                      <div className="create-invoice-page-end-amount-row">
                        <div>CESS</div>
                        <div>₹ {formatNumber(totalCess) * 1}</div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="create-invoice-page-end-amount-discount">
                      {AddExtraDiscount ? (
                        <div className="create-invoice-page-end-amount-discount-row">
                          <select name="" id="">
                            <option value="Discount After Tax">
                              Discount After Tax
                            </option>
                          </select>
                          <div className="create-invoice-page-end-amount-discount-col">
                            <div className="create-invoice-page-end-amount-discount-input">
                              %
                              <input
                                onBlur={() =>
                                  setEditingExtraDiscountPercentage(false)
                                }
                                type="number"
                                value={
                                  formatNumber(
                                    (data.invoiceExtraDiscount * 100) / subTotal
                                  ) * 1
                                }
                                onChange={handleExtraDiscountPercentage}
                                max={100}
                                ref={inputSelectRef1}
                                onFocus={() =>
                                  handleSelectFocus(inputSelectRef1)
                                }
                              />
                              
                            </div>

                            <div className="create-invoice-page-end-amount-discount-input">
                              ₹
                              <input
                                onBlur={() => setEditingExtraDiscount(null)}
                                value={
                                  formatNumber(data.invoiceExtraDiscount * 1) *
                                  1
                                }
                                type="number"
                                name="editingExtraDiscount"
                                onChange={handleExtraDiscount}
                                ref={inputSelectRef2}
                                onFocus={() =>
                                  handleSelectFocus(inputSelectRef2)
                                }
                              />
                             
                            </div>

                            <i
                              class="fa-regular fa-circle-xmark"
                              onClick={() => {
                                setAddExtraDiscount(false);
                                setData({
                                  ...data,
                                  invoiceExtraDiscount: 0,
                                });
                              }}
                            ></i>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="create-invoice-page-end-link"
                          onClick={() => setAddExtraDiscount(true)}
                        >
                          + Add Discount
                        </div>
                      )}
                    </div>

                    <div className="create-invoice-page-end-hr"></div>
                    {roundOff ? (
                      <div
                        onClick={() => setRoundOff(false)}
                        className="create-invoice-page-end-option"
                      >
                        <i class="fa-regular fa-square-check"></i>
                        Auto Round Off
                      </div>
                    ) : (
                      <div
                        onClick={() => setRoundOff(true)}
                        className="create-invoice-page-end-option"
                      >
                        <i class="fa-regular fa-square"></i>
                        Auto Round Off
                      </div>
                    )}

                    <div className="create-invoice-page-end-total">
                      <div>Total Amount</div>
                      <div>₹ {totalAmount * 1}</div>
                    </div>




                    <div className="create-invoice-page-end-hr"></div>

{
  businessData.companySignatureType === "Box"?
  
  <div className="create-invoice-page-end-signature">
    <div className="create-invoice-page-end-signature-box"></div>
  <div className="create-invoice-page-end-signature-heading">
    Authorized signatory for <b>{businessData.businessName}</b>
  </div>
  </div>


:
businessData.companySignatureType === "Image"?
businessData.companySignature?
<div className="create-invoice-page-end-signature-image">
<img src={`${link}/${businessData.companySignature}`} />
  <div className="create-invoice-page-end-signature-heading">
    Authorized signatory for <b>{businessData.businessName}</b>
  </div>
</div>
:
                    <div className="create-invoice-page-end-signature">
                      <div className="create-invoice-page-end-signature-heading">
                        Authorized signatory for <b>{businessData.businessName}</b>
                      </div>
                      <Link to="/app/setting/business" target="_blank" className="create-invoice-page-end-signature-option">
                        + Add Signature
                      </Link>
                    </div>

:

                    <div className="create-invoice-page-end-signature">
                      <div className="create-invoice-page-end-signature-heading">
                        Authorized signatory for <b>{businessData.businessName}</b>
                      </div>
                      <Link to="/app/setting/business" target="_blank" className="create-invoice-page-end-signature-option">
                        + Add Signature
                      </Link>
                    </div>
}





                  </div>
                </div>

                {/* ----------------------------------------------------------------------------------------------------------- */}
              </div>
            </div>

            {/* ----------------------------------------------------------------------------------------------------------- */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateDeliveryChallanComponent;
