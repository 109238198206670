import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import TimerPopupAlertComponent from "../../../components/extra/TimerPopupAlertComponent";
import LoadingComponent from "../../../components/extra/LoadingComponent";
import SelectPartyForm from "./SelectPartyForm";
import { toWords } from "number-to-words";
import SelectItemStockForm from "./SelectItemStockForm";

const CreateSalesInvoiceForm = () => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  // useStates  ================================================================================================================

  const [loading, setLoading] = useState(false);
  const [timerAlertMessage, setTimerAlertMessage] = useState("");
  const [timerPopupAlert, setTimerPopupAlert] = useState(false);

  const [partyData, setPartyData] = useState();

  const [selectParty, setSelectParty] = useState(false);
  const [selectItem, setSelectItem] = useState(false);

  const [invoiceNumberChange, setInvoiceNumberChange] = useState(false);

  // fetch company  ================================================================================================================
  const [businessData, setBusinessData] = useState(null);

  const { fetchBusiness } = useContext(AuthContext);

  const fetchBusinessData = (e) =>
    fetchBusiness(userId, businessId, setBusinessData);

  useEffect(() => {
    fetchBusinessData();
  }, []);

  // select items ================================================================================================================

  const [selectedItems, setSelectedItems] = useState([]);

  const handleRemoveItem = (itemId) => {
    setSelectedItems(selectedItems.filter((p) => p._id !== itemId));
  };

  //   edit value ================================================================================================================

  //   ================================================================================================================
  //   ================================================================================================================
  //   ================================================================================================================
  //   ================================================================================================================
  //   ================================================================================================================
  //   ================================================================================================================
  //   ================================================================================================================

  const [editingQuantity, setEditingQuantity] = useState(null);
  const [editingDiscount, setEditingDiscount] = useState(null);
  const [editingDiscountPercent, setEditingDiscountPercent] = useState(null);
  const [editingPrice, setEditingPrice] = useState(null);
  const [editingTax, setEditingTax] = useState(null);
  const [editingTotalPrice, setEditingTotalPrice] = useState(null);

  const handleQuantityChange = (id, value) => {

    if( value >= 0 && value <= 10000  ||  value === ""  ){


    if (value.trim() === "") {
      const updatedQuantity = selectedItems.map((item) =>
        item._id === id ? { ...item, quantity: 0 } : item
      );
      setSelectedItems(updatedQuantity);
    } else {
      const updatedQuantity = selectedItems.map((item) =>
        item._id === id ? { ...item, quantity: value.replace(/^0+/, "") } : item
      );
      setSelectedItems(updatedQuantity);
    }
    
    

  }
    
  };

  const handleDiscountChange = (id, value, price, quantity) => {
    const val = ( parseFloat(value) * 100 / (parseFloat(price) * quantity));

    if (value.trim() === "") {
      const updatedDiscount = selectedItems.map((item) =>
        item._id === id ? { ...item, discount: 0 } : item
      );
      setSelectedItems(updatedDiscount);
    } else {
      const updatedDiscount = selectedItems.map((item) =>
        item._id === id ? { ...item, discount: val } : item
      );
      setSelectedItems(updatedDiscount);
    }
  };

  const handleDiscountPercentChange = (id, value) => {
    // const val = parseFloat(value);

    if( value >= 0 && value <= 100  ||  value === ""  ){

    if (value.trim() === "") {
      const updatedDiscount = selectedItems.map((item) =>
        item._id === id ? { ...item, discount: 0 } : item
      );
      setSelectedItems(updatedDiscount);
    } else {
      const updatedDiscount = selectedItems.map((item) =>
        item._id === id ? { ...item, discount: parseFloat(value.replace(/^0+/, "")) } : item
      );
      setSelectedItems(updatedDiscount);
    }
  }
  };


  const handlePriceChange = (id, value) => {
    const price = parseFloat(value);
    if (value.trim() === "") {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id ? { ...item, itemPrice: 0 } : item
      );
      setSelectedItems(updatedPrice);
    } else {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id ? { ...item, itemPrice: price } : item
      );
      setSelectedItems(updatedPrice);
    }
  };

  const handleTaxChange = (id, value) => {
    const tax = parseFloat(value);

    // if (value.trim() === "") {
    //   const updatedPrice = selectedItems.map((item) =>
    //     item._id === id ? { ...item, itemTax: 0 } : item
    //   );
    //   setSelectedItems(updatedPrice);
    // } else {
    //   const newValue = value.replace(/^0+/, "");
    //   const updatedPrice = selectedItems.map((item) =>
    //     item._id === id ? { ...item, itemTax: newValue } : item
    //   );
    //   setSelectedItems(updatedPrice);
    // }

    if (value === "14,12") {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id ? { ...item, itemTax: 14, cess: 12 } : item
      );
      setSelectedItems(updatedPrice);
    } 
    else if (value === "28,12") {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id ? { ...item, itemTax: 28, cess: 12 } : item
      );
      setSelectedItems(updatedPrice);
    } 
    else if (value === "28,60") {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id ? { ...item, itemTax: 28, cess: 60 } : item
      );
      setSelectedItems(updatedPrice);
    } 
    else {
      const updatedPrice = selectedItems.map((item) =>
        item._id === id ? { ...item, itemTax: value, cess: 0 } : item
      );
      setSelectedItems(updatedPrice);
    }
  };

  const handleTotalChange = (id, value, quantity) => {
    const total = parseFloat(value);
    const product = selectedItems.find((p) => p._id === id);

    if (value.trim() === "") {
      const updatedProducts = selectedItems.map((product) =>
        product._id === id ? { ...product, itemPrice: 0 } : product
      );
      setSelectedItems(updatedProducts);
    } else {
      const basePrice = total / (1 + product.itemTax / 100);

      const updatedProducts = selectedItems.map((product) =>
        product._id === id
          ? { ...product, itemPrice: basePrice / quantity }
          : product
      );
      setSelectedItems(updatedProducts);
    }
  };

  const calculateTaxedPrice = (price, taxRate, quantity, discount, cess) => {

    const discountedPrice =  price - (price*discount/100) 
    
    return (
      // (price + (price * taxRate) / 100) * quantity -
      // ((price * discountRate) / 100) * quantity

      // (price - discount + ((price - discount) * taxRate) / 100) * quantity

      // (price - (price*discount/100) + ((price - (price*discount/100)) * taxRate) / 100) * quantity

      // ( discountedPrice + ( discountedPrice * taxRate / 100) ) * quantity

    //  ( discountedPrice + ( discountedPrice * taxRate / 100) + ( cess * taxRate / 100) ) * quantity

    ( discountedPrice + ( discountedPrice * taxRate / 100) + ( cess * discountedPrice / 100) ) * quantity

      
      
    );
  };

  const totalAmount = selectedItems.reduce((acc, item) => {
    return (
      acc +
      calculateTaxedPrice(
        item.itemPrice,
        item.itemTax,
        item.quantity,
        item.discount,
        item.cess,
      )
    );
  }, 0);

  const taxableAmount = selectedItems.reduce((acc, item) => {
    return acc + item.itemPrice * item.quantity;
  }, 0);

  const totalTax = selectedItems.reduce((acc, item) => {
    return acc + ((item.itemTax * item.itemPrice) / 100) * item.quantity;
  }, 0);

  //   ================================================================================================================
  //   ================================================================================================================
  //   ================================================================================================================
  //   ================================================================================================================
  //   ================================================================================================================
  //   ================================================================================================================
  //   ================================================================================================================
  //   ================================================================================================================
  //   ================================================================================================================

  // create invoice  ================================================================================================================

  //   ================================================================================================================
  const navigate = useNavigate();

  const [data, setData] = useState({
    invoiceNumber: "",
    invoiceDate: new Date().toISOString().split("T")[0],
    invoiceDueDate: "",
    receivedAmount: "",
    updateInvoiceNumber: false,
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  const { createInvoice } = useContext(AuthContext);

  const handleCreate = async (e) => {
    setLoading(true);
    e.preventDefault();
    await createInvoice(
      userId,
      businessId,
      data,
      setTimerPopupAlert,
      setTimerAlertMessage,
      navigate
    );
    setLoading(false);
  };

  const handleInvoiceNumberChange = (e) => {
    setInvoiceNumberChange(true);
    setData({
      ...data,
      invoiceNumber: e.target.value,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission on Enter
    }
  };

  const hamdleSaveData = (e) => {
    if (invoiceNumberChange === true) {
      if (
        data.invoiceNumber ==
        businessData.invoiceSettingData.lastInvoiceNumber + 1
      ) {
        setData({
          ...data,
          updateInvoiceNumber: true,
          invoicePartyData: partyData,
          invoiceItemsData: selectedItems,
          invoiceTotalAmount: totalAmount.toFixed(2),
          // invoiceCustomFields : customFieldValue,
          invoiceCustomFields: Object.fromEntries(
            Object.entries(customFieldValue).filter(([key, value]) => value)
          ),
        });
      } else {
        setData({
          ...data,
          updateInvoiceNumber: false,
          invoicePartyData: partyData,
          invoiceItemsData: selectedItems,
          invoiceTotalAmount: totalAmount.toFixed(2),
          // invoiceCustomFields : customFieldValue,
          invoiceCustomFields: Object.fromEntries(
            Object.entries(customFieldValue).filter(([key, value]) => value)
          ),
        });
      }
    } else {
      setData({
        ...data,
        invoiceNumber: businessData.invoiceSettingData.lastInvoiceNumber + 1,
        invoicePartyData: partyData,
        invoiceItemsData: selectedItems,
        invoiceTotalAmount: totalAmount.toFixed(2),
        updateInvoiceNumber: true,
        // invoiceCustomFields : customFieldValue,
        invoiceCustomFields: Object.fromEntries(
          Object.entries(customFieldValue).filter(([key, value]) => value)
        ),
      });
    }
  };

  // ====================================================================================================================

  const [customFieldValue, setCustomFieldValue] = useState({});

  // Update the value based on input changes

  const handleCustomFields = (e) => {
    const { name, value } = e.target;
    setCustomFieldValue((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    console.log("new values are : ", customFieldValue);
    // setCustomFieldValue((prevValues) => ({
    //   ...prevValues,
    //   [key]: value,
    // }));
  };

  // ====================================================================================================================

  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };

  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // 0101010101010101====================================================================================================================

  const inputRef = useRef(null);

  const handleWheel = (event) => {
    event.preventDefault(); // Prevent the scroll wheel from changing the input value
    if (inputRef.current) {
      inputRef.current.blur(); // Remove focus from the input
    }
  };

  const [enableDueDate, setEnableDueDate] = useState(false);
  const [enableShippingAddress, setEnableShippingAddress] = useState(true);

  // 0202020202020202020====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================

  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    // Check if the click is outside the button and dropdown
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setEditingTax(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  // ====================================================================================================================
  if (!businessData)
    return (
      <div>
        <LoadingComponent />
      </div>
    );

  // if (businessData.invoiceSettingData.invoiceThemeColor) {
  //   document.documentElement.style.setProperty(
  //     "--invoice",
  //     businessData.invoiceSettingData.invoiceThemeColor
  //   );
  // }

  return (
    <div>
      {selectParty ? (
        <SelectPartyForm
          open={setSelectParty}
          invoicePartyData={setPartyData}
        />
      ) : (
        ""
      )}

      {selectItem ? (
        <SelectItemStockForm
          open={setSelectItem}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          handleRemoveItem={handleRemoveItem}
          handleQuantityChange={handleQuantityChange}
        />
      ) : (
        ""
      )}
      {timerPopupAlert ? (
        <TimerPopupAlertComponent
          message={timerAlertMessage}
          setTimerPopupAlert={setTimerPopupAlert}
        />
      ) : (
        ""
      )}

      {/* *****************************************************************************************************88 */}
      {/* *****************************************************************************************************88 */}
      {/* *****************************************************************************************************88 */}
      {/* *****************************************************************************************************88 */}

      {/* <div className="invoice-theme-form"> */}
      <div>
        <div className="create-invoice create-invoice-create">
          {/* invoice address *****************************************************************************************************88 */}

          {/* invoice details *****************************************************************************************************88 */}
          <div className="create-invoice-invoive-details create-invoice-invoive-details-wb">
            <div className="create-invoice-invoive-details-col">
              <div className="create-invoice-invoive-details-col-heading">
                Invoice No. :
              </div>
              <input
                onKeyDown={handleKeyDown}
                // onChange={handleChange}
                onChange={handleInvoiceNumberChange}
                name="invoiceNumber"
                defaultValue={
                  businessData.invoiceSettingData.lastInvoiceNumber + 1
                }
                type="number"
              />
            </div>

            <div className="create-invoice-invoive-details-col">
              <div className="create-invoice-invoive-details-col-heading">
                Invoice Date :
              </div>
              <input
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                name="invoiceDate"
                type="date"
                id=""
                value={data.invoiceDate}
                // defaultValue={new Date().toISOString().split('T')[0]}
              />
            </div>
            {businessData.invoiceSettingData.enableDueDate ? (
              <div className="create-invoice-invoive-details-col">
                <div className="create-invoice-invoive-details-col-heading">
                  Due Date :
                </div>
                <input
                  onKeyDown={handleKeyDown}
                  type="date"
                  name="invoiceDueDate"
                  onChange={handleChange}
                />
              </div>
            ) : (
              ""
            )}
          </div>

          {/* party details *****************************************************************************************************88 */}

          <div className="create-invoice-party-details">
            {partyData ? (
              <div className="create-invoice-party-details-col-1">
                <div className="create-invoice-party-details-col-1-heading">
                  Bill To{" "}
                  <div onClick={(e) => setSelectParty(true)}>Change Party</div>
                </div>
                {partyData.partyName ? (
                  <div className="create-invoice-party-details-col-1-name">
                    {partyData.partyName}
                  </div>
                ) : (
                  ""
                )}
                {partyData.partyBillingAddress ||
                partyData.partyBillingState ||
                partyData.partyBillingPinCode ? (
                  <div>
                    {partyData.partyBillingAddress}
                    {partyData.partyBillingState ? (
                      <>, {partyData.partyBillingState}</>
                    ) : (
                      ""
                    )}
                    {partyData.partyBillingPinCode ? (
                      <>, {partyData.partyBillingPinCode}</>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}

                {partyData.partyNumber ? (
                  <div className="create-invoice-party-details-col-1-text">
                    <b>Mobile :</b>
                    {partyData.partyNumber}
                  </div>
                ) : (
                  ""
                )}
                {partyData.partyGSTIN ? (
                  <div className="create-invoice-party-details-col-1-text">
                    <b>GSTIN : </b>
                    {partyData.partyGSTIN}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div className="create-invoice-party-details-col-1">
                <div
                  className="create-invoice-party-details-col-1-add"
                  onClick={(e) => setSelectParty(true)}
                >
                  + Add Party
                </div>
              </div>
            )}

            {businessData.invoiceSettingData.enableCustomFields ? (
              <div className="create-invoice-party-details-col-2">
                <table>
                  {businessData.invoiceSettingData.invoiceCustomFields.map(
                    (e) => (
                      <tr>
                        <td>{e}</td>
                        <td>:</td>
                        <td>
                          <input
                            type="text"
                            onKeyDown={handleKeyDown}
                            name={e}
                            value={customFieldValue[e]}
                            // onChange={(e) => handleCustomFields(e, e.target.value)}
                            onChange={(e) => handleCustomFields(e)}
                          />
                        </td>
                      </tr>
                    )
                  )}

                  {/* <tr className="create-invoice-party-details-col-2-col">
                      <td className="create-invoice-party-details-col-2-col-heading">
                        Bike Model :
                      </td>
                      <td className="create-invoice-party-details-col-2-col-data">
                        Bajaj Avenger 220
                      </td>
                    </tr> */}

                  {/* <tr className="create-invoice-party-details-col-2-col">
                <td className="create-invoice-party-details-col-2-col-heading">Vehicle No.</td>
                <td className="create-invoice-party-details-col-2-col-colons">:</td>
                <td className="create-invoice-party-details-col-2-col-data">UP 70 DD 6590</td>
              </tr> */}
                </table>
              </div>
            ) : (
              ""
            )}
          </div>

          {/* party details *****************************************************************************************************88 */}
          <>
            <div className="create-invoice-item-data">
              <table className="create-invoice-item-data-table create-invoice-item-data-table-wb">
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Item / Service</th>
                    <th>Qty</th>
                    <th>Rate</th>
                    <th>Discount</th>
                    <th>Tax %</th>
                    <th>Amount</th>
                    <th>
                      <i className="fa-solid fa-trash"></i>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {selectedItems.map((item, i) => (
                    <tr key={item._id}>
                      <td className="create-invoice-item-data-table-sno">
                        {i + 1}
                      </td>
                      <td>{item.itemName}</td>
                      {/* <td>{item.quantity}</td> */}
                      <td className="create-invoice-item-data-table-qty">
                        {editingQuantity  === item._id ? (
                          <input
                            onKeyDown={handleKeyDown}
                            type="number"
                            value={item.quantity}
                            
                            placeholder="Enter new price"
                            onChange={(e) =>
                              handleQuantityChange(item._id, e.target.value)
                            }
                            onBlur={() => setEditingQuantity(null)}
                            autoFocus
                            min={0}
                          />
                        ) : (
                          // <div onClick={() => setEditingQuantity(item._id)}>
                          //   {" "}
                          //   {item.quantity}
                          // </div>

                          <div onClick={() => setEditingQuantity(item._id)}>
                            <input
                              onFocus={() => setEditingQuantity(item._id)}
                              type="number"
                              min={0}
                              value={item.quantity}
                            />
                          </div>
                        )}
                      </td>

                      <td className="create-invoice-item-data-table-price">
                        {editingPrice === item._id ? (
                          <input
                            onKeyDown={handleKeyDown}
                            type="number"
                            defaultValue={formatNumber(item.itemPrice)}
                            placeholder="Enter new price"
                            onChange={(e) =>
                              handlePriceChange(item._id, e.target.value)
                            }
                            onBlur={() => setEditingPrice(null)}
                            autoFocus
                            min={0}
                          />
                        ) : ( 
                          // <div onClick={() => setEditingPrice(item._id)}>
                          //   {" "}
                          //   {item.itemPrice.toFixed(2)}
                          // </div>
                          <div onClick={() => setEditingPrice(item._id)}>
                            <input
                              step="0.01"
                              onFocus={() => setEditingPrice(item._id)}
                              type="number"
                              min={0}
                              value={formatNumber(item.itemPrice)}
                            />
                          </div>
                        )}
                      </td>

                      {/* ------------------------------------------------------------------- */}

                      {/* <td className="create-invoice-item-data-table-qty">
<input type="number" min={0} value={item.discount}/>

</td> */}

                      {/* 11000000 */}

                      <td className="create-invoice-item-data-table-discount">
                        <div className="create-invoice-item-data-table-discount-percentage">
                          <div>%</div> 

 
                          {editingDiscountPercent === item._id ? (
                            <input
                              onKeyDown={handleKeyDown}
                              type="number"
                              // defaultValue={item.discount}
                              // defaultValue={formatNumber(item.discount)}
                              value={formatNumber(item.discount)}
                              onChange={(e) =>
                                handleDiscountPercentChange(item._id, e.target.value)
                              } 
                              onBlur={() => setEditingDiscountPercent(null)}
                              autoFocus
                              min={0}
                              max={100}
                            />
                          ) : (
                            <span
                              onClick={() => setEditingDiscountPercent(item._id)}
                              onFocus={() => setEditingDiscountPercent(item._id)}
                            >
                              { formatNumber(item.discount) }
                            </span>
                          )}
                          
                          {/* <input type="text" /> */}
                        </div>
                        <div className="create-invoice-item-data-table-discount-value">
                          {" "}
                          <div>
                            <span className="rupee-text"></span>
                          </div>{" "}
                          {/* <input type="text" /> */}
                          {editingDiscount === item._id ? (
                            <input
                              onKeyDown={handleKeyDown}
                              type="number"
                              // defaultValue={item.discount} 
                              defaultValue={formatNumber(( item.itemPrice * item.discount / 100 ))} 
                              onChange={(e) =>
                                handleDiscountChange(item._id, e.target.value, item.itemPrice )
                              } 
                              onBlur={() => setEditingDiscount(null)}
                              autoFocus
                              min={0}
                              max={100}
                            />
                          ) : (
                            <span
                              onClick={() => setEditingDiscount(item._id)}
                              onFocus={() => setEditingDiscount(item._id)}
                            >
                              {formatNumber(( item.itemPrice * item.discount / 100 ))}
                            </span>
                          )}
                        </div>
                      </td>

                      {/* <td className="create-invoice-item-data-table-qty">
                            
                            {editingDiscount === item._id ? (
                              <input
                                onKeyDown={handleKeyDown}
                                type="number"
                                defaultValue={item.discount}
                                onChange={(e) =>
                                  handleDiscountChange(item._id, e.target.value)
                                }
                                onBlur={() => setEditingDiscount(null)}
                                autoFocus
                                min={0}
                                max={100}
                              />
                            ) : (
                              <div onClick={() => setEditingDiscount(item._id)}>
                                <input
                                  onFocus={() => setEditingDiscount(item._id)}
                                  type="number"
                                  value={item.discount}
                                  min={0}
                                  max={100}
                                />
                              </div>
                            )}
                          </td> */}

                      {/* ------------------------------------------------------------------- */}

                      <td className="create-invoice-item-data-table-tax">
                        <div className="create-invoice-item-data-table-tax-select">
                          <div
                            className="create-invoice-item-data-table-tax-select-value"
                            ref={buttonRef}
                            onClick={() => setEditingTax(item._id)} 
                          >
                            {item.itemTax ? item.itemTax : 0}% {item.cess ? <>, {item.cess}%</> : ""}
                          </div>
                          {editingTax === item._id ? (
                            <div
                              className="create-invoice-item-data-table-tax-select-option"
                              ref={dropdownRef}
                            > 
                              <div className="create-invoice-item-data-table-tax-select-option-top"></div>
                              <div className="create-invoice-item-data-table-tax-select-option-options">
                                <div
                                  className="create-invoice-item-data-table-tax-select-option-value"
                                  onClick={(e) => {
                                    handleTaxChange(item._id, 0);
                                    setEditingTax(null);
                                  }}
                                >
                                  none
                                </div>
                                {/* <div className="create-invoice-item-data-table-tax-select-option-value" onClick={ (e) => { handleTaxChange(item._id, 0 ); setEditingTax(null) } } >GST @ 0%</div> */}
                                <div
                                  className="create-invoice-item-data-table-tax-select-option-value"
                                  onClick={(e) => {
                                    handleTaxChange(item._id, 0.1);
                                    setEditingTax(null);
                                  }}
                                >
                                  GST @ 0.1%
                                </div>
                                <div
                                  className="create-invoice-item-data-table-tax-select-option-value"
                                  onClick={(e) => {
                                    handleTaxChange(item._id, 0.25);
                                    setEditingTax(null);
                                  }} 
                                >
                                  GST @ 0.25%
                                </div>
                                <div
                                  className="create-invoice-item-data-table-tax-select-option-value"
                                  onClick={(e) => {
                                    handleTaxChange(item._id, 1.5);
                                    setEditingTax(null);
                                  }}
                                >
                                  GST @ 1.5%
                                </div>
                                <div
                                  className="create-invoice-item-data-table-tax-select-option-value"
                                  onClick={(e) => {
                                    handleTaxChange(item._id, 3);
                                    setEditingTax(null);
                                  }}
                                >
                                  GST @ 3%
                                </div>
                                <div
                                  className="create-invoice-item-data-table-tax-select-option-value"
                                  onClick={(e) => {
                                    handleTaxChange(item._id, 5);
                                    setEditingTax(null);
                                  }}
                                >
                                  GST @ 5%
                                </div>
                                <div
                                  className="create-invoice-item-data-table-tax-select-option-value"
                                  onClick={(e) => {
                                    handleTaxChange(item._id, 6);
                                    setEditingTax(null);
                                  }}
                                >
                                  GST @ 6%
                                </div>
                                <div
                                  className="create-invoice-item-data-table-tax-select-option-value"
                                  onClick={(e) => {
                                    handleTaxChange(item._id, 12);
                                    setEditingTax(null);
                                  }}
                                >
                                  GST @ 12%
                                </div>
                                <div
                                  className="create-invoice-item-data-table-tax-select-option-value"
                                  onClick={(e) => {
                                    handleTaxChange(item._id, 13.8);
                                    setEditingTax(null);
                                  }}
                                >
                                  GST @ 13.8%
                                </div>
                                <div
                                  className="create-invoice-item-data-table-tax-select-option-value"
                                  onClick={(e) => {
                                    handleTaxChange(item._id, 18);
                                    setEditingTax(null);
                                  }}
                                >
                                  GST @ 18%
                                </div>
                                <div
                                  className="create-invoice-item-data-table-tax-select-option-value"
                                  onClick={(e) => {
                                    handleTaxChange(item._id, "14,12");
                                    setEditingTax(null);
                                  }}
                                >
                                  GST @ 14% + cess @ 12%
                                </div>
                                <div
                                  className="create-invoice-item-data-table-tax-select-option-value"
                                  onClick={(e) => {
                                    handleTaxChange(item._id, 28);
                                    setEditingTax(null);
                                  }}
                                >
                                  GST @ 28%
                                </div>
                                <div
                                  className="create-invoice-item-data-table-tax-select-option-value"
                                  onClick={(e) => {
                                    handleTaxChange(item._id, "28,12");
                                    setEditingTax(null);
                                  }}
                                >
                                  GST @ 28% + cess @ 12%
                                </div>
                                <div
                                  className="create-invoice-item-data-table-tax-select-option-value"
                                  onClick={(e) => {
                                    handleTaxChange(item._id, "28,60");
                                    setEditingTax(null);
                                  }}
                                >
                                  GST @ 28% + cess @ 60%
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="create-invoice-item-data-table-tax-amount">
                          {" "}
                          <div className="rupee-text"></div>{" "}
                          {/* { formatNumber( ( (item.itemPrice - (item.discount * item.itemPrice / 100)) * item.itemTax) /100 )} */}

                          { formatNumber(
                             (
                              ( ( (item.itemPrice - (item.discount * item.itemPrice / 100)) * item.itemTax) /100 )
                              +
                              ( ( (item.itemPrice - (item.discount * item.itemPrice / 100)) * item.cess) /100 )
                             )
                             * item.quantity

                             )}

                          
                            {/* {item.discount * item.itemPrice / 100} */}
                            
                        </div>
                      </td>

                      {/* <td className="create-invoice-item-data-table-tax">


              


                            
                            {editingTax === item._id ? (

                           <div>



                <div className="create-invoice-item-data-table-tax-options">

                    <div ref={buttonRef}>
                      <div ref={dropdownRef}>
                    <div onClick={ (e) => { handleTaxChange(item._id, 10); setEditingTax(null) } } > 10</div>
                    <div onClick={ (e) => { handleTaxChange(item._id, "1"); setEditingTax(null) } } > 1</div>
                    <div onClick={ (e) => { handleTaxChange(item._id, "100,30"); setEditingTax(null) } } > 10 + 30</div>


    </div>
    </div>

                    
                    

                </div>


                            
                            

                              
                                </div> 
                            ) : (
                             
                              <div onClick={() => setEditingTax(item._id)}>
                                <input
                                  step="0.01"
                                  onFocus={() => setEditingTax(item._id)}
                                  type="number"
                                  min={0}
                                  value={item.itemTax ? item.itemTax : 0}
                                />
                              </div>
                            )}

                            
                          
                          </td> */}

                      <td className="create-invoice-item-data-table-total">
                        {editingTotalPrice === item._id ? (
                          <input
                            onKeyDown={handleKeyDown}
                            defaultValue={formatNumber(
                              calculateTaxedPrice(
                                item.itemPrice,
                                item.itemTax,
                                item.quantity,
                                item.discount,
                                item.cess,
                              )
                            )}
                            type="number"
                            placeholder="Enter total price"
                            onChange={(e) =>
                              handleTotalChange(
                                item._id,
                                e.target.value,
                                item.quantity
                              )
                            }
                            onBlur={() => setEditingTotalPrice(null)}
                            autoFocus
                            min={0}
                          />
                        ) : (
                          // <div
                          //   onClick={() => setEditingTotalPrice(item._id)}
                          // >
                          //   {calculateTaxedPrice(
                          //     item.itemPrice,
                          //     item.itemTax,
                          //     item.quantity
                          //   ).toFixed(2)}
                          // </div>
                          <div onClick={() => setEditingTotalPrice(item._id)}>
                            <input
                              type="number"
                              min={0}
                              value={formatNumber(
                                calculateTaxedPrice(
                                  item.itemPrice,
                                  item.itemTax,
                                  item.quantity,
                                  item.discount,
                                  item.cess,
                                )
                              )}
                              onFocus={() => setEditingTotalPrice(item._id)}
                              onChange={() => setEditingTotalPrice(item._id)}
                              step="0.01"
                            />

                            {/* {calculateTaxedPrice(
                                  item.itemPrice,
                                  item.itemTax,
                                  item.quantity
                                ).toFixed(2)} */}
                          </div>
                        )} 
                      </td>

                      <td
                        className="create-invoice-item-data-table-remove"
                        onClick={(e) => handleRemoveItem(item._id)}
                      >
                        <i className="fa-solid fa-trash"></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div
                className="create-invoice-item-data-add"
                onClick={(e) => setSelectItem(true)}
              >
                + Add Item
              </div>
            </div>

            <div className="create-invoice-item-data-sub-total create-invoice-item-data-sub-total-wb">
              <div>Sub Total</div> 
              {/* <div> Rs. 0</div> */}
              <div> Rs. {formatNumber(totalAmount)}</div>
            </div>
          </>

          {/* bill end *****************************************************************************************************88 */}

          {/* bill end *****************************************************************************************************88 */}

          <div className="create-invoice-end">
            <div className="create-invoice-end-col-1">
              {/* ----------------------------------- */}

              {businessData.invoiceSettingData.enableTermsCondition ? (
                <div className="create-invoice-end-col-1-tc">
                  <div className="create-invoice-end-col-1-tc-heading">
                    TERMS AND CONDITIONS
                  </div>
                  <div className="create-invoice-end-col-1-tc-list">
                    {businessData.invoiceSettingData.termsConditionPoints.map(
                      (e, i) => (
                        <div>
                          {i + 1}. {e}
                        </div>
                      )
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* ----------------------------------- */}
              {businessData.invoiceSettingData.enableBankData ? (
                <div className="create-invoice-end-col-1-bank-datails">
                  <div className="create-invoice-end-col-1-bank-datails-heading">
                    BANK DETAILS
                  </div>
                  <table className="create-invoice-end-col-1-bank-datails-data">
                    <tr>
                      <td>Bank Name</td>
                      <td>{businessData.invoiceSettingData.bankName}</td>
                    </tr>
                    <tr>
                      <td>Account Name</td>
                      <td>{businessData.invoiceSettingData.accountName}</td>
                    </tr>
                    <tr>
                      <td>Account No</td>
                      <td>{businessData.invoiceSettingData.accountNumber}</td>
                    </tr>
                    <tr>
                      <td>IFSC Code</td>
                      <td>{businessData.invoiceSettingData.accountIFSC}</td>
                    </tr>
                  </table>
                </div>
              ) : (
                // <div className="create-invoice-end-button">+add bank details</div>
                ""
              )}
            </div>
            {/* ====================================================================== */}

            <div className="create-invoice-end-col-2">
              <div className="create-invoice-end-col-2-amount">
                <div className="create-invoice-end-col-2-amount-col">
                  <div className="create-invoice-end-col-2-amount-col-heading">
                    Taxable Value
                  </div>
                  <div className="create-invoice-end-col-2-amount-col-data">
                    {/* Rs. {(taxableAmount.toFixed(2) / 1).toLocaleString()} */}
                    Rs. {formatNumber(taxableAmount.toFixed(2) / 1)}
                  </div>
                </div>

                <div className="create-invoice-end-col-2-amount-col">
                  <div className="create-invoice-end-col-2-amount-col-heading">
                    CGST
                  </div>
                  <div className="create-invoice-end-col-2-amount-col-data">
                    {/* Rs. {(totalTax.toFixed(2) / 2).toLocaleString()} */}
                    Rs. {formatNumber(totalTax / 2)}
                  </div>
                </div>

                <div className="create-invoice-end-col-2-amount-col">
                  <div className="create-invoice-end-col-2-amount-col-heading">
                    SGST
                  </div>
                  <div className="create-invoice-end-col-2-amount-col-data">
                    Rs. {formatNumber(totalTax.toFixed(2) / 2)}
                  </div>
                </div>

                <div className="create-invoice-end-col-2-amount-col create-invoice-end-col-2-amount-col-total">
                  <div className="create-invoice-end-col-2-amount-col-heading">
                    Total Amount
                  </div>
                  <div className="create-invoice-end-col-2-amount-col-data">
                    Rs.{" "}
                    {/* {Math.round(totalAmount.toFixed(2) / 1).toLocaleString()} */}
                    {formatNumber(totalAmount).toLocaleString()}
                  </div>
                </div>

                {businessData.invoiceSettingData.enableReceivedAmount ? (
                  <>
                    {/* {Math.round(data.receivedAmount) ==
                        Math.round(totalAmount.toFixed(2)) ? ( */}
                    {data.receivedAmount == totalAmount.toFixed(2) ? (
                      <div className="create-invoice-end-col-2-amount-col create-invoice-end-col-2-amount-col-green">
                        <div className="create-invoice-end-col-2-amount-col-heading">
                          Fully paid
                        </div>
                        <div className="create-invoice-end-col-2-amount-col-data">
                          {/* <i class="fa-solid fa-square-check"></i> */}
                        </div>
                      </div>
                    ) : (
                      <div
                        className="create-invoice-end-col-2-amount-col create-invoice-end-col-2-amount-col-blue"
                        onClick={(e) =>
                          setData({
                            ...data,
                            receivedAmount: totalAmount.toFixed(2),
                          })
                        }
                      >
                        <div className="create-invoice-end-col-2-amount-col-heading">
                          mark as fully paid
                        </div>
                        <div className="create-invoice-end-col-2-amount-col-data">
                          <i class="fa-regular fa-square"></i>
                        </div>
                      </div>
                    )}
                    <div className="create-invoice-end-col-2-amount-col">
                      <div className="create-invoice-end-col-2-amount-col-heading">
                        Received Amount
                      </div>
                      <div className="create-invoice-end-col-2-amount-col-data">
                        <input
                          onKeyDown={handleKeyDown}
                          // required
                          placeholder=""
                          value={data.receivedAmount}
                          type="number"
                          onChange={handleChange}
                          name="receivedAmount"
                          max={totalAmount.toFixed(2)}
                        />
                      </div>
                    </div>

                    <div className="create-invoice-end-col-2-amount-col">
                      <div className="create-invoice-end-col-2-amount-col-heading">
                        Balance
                      </div>
                      <div className="create-invoice-end-col-2-amount-col-data">
                        Rs. {formatNumber(totalAmount - data.receivedAmount)}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}

                <div className="create-invoice-end-col-2-amount-text">
                  <div>Total Amount (in words) :</div>
                  <div>{toWords(totalAmount)} rupees</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* *****************************************************************************************************88 */}
      {/* *****************************************************************************************************88 */}
      {/* *****************************************************************************************************88 */}
      {/* *****************************************************************************************************88 */}
      {/* *****************************************************************************************************88 */}

      <br />
      <br />
      <br />

      {/* 0000000000000000000000000000000000000000000 */}

      <div className="create-invoice">
        <div className="create-invoice-main">
          {/* invoice details *****************************************************************************************************88 */}

          <div className="create-invoice-details">
            <div className="create-invoice-details-col">
              <div className="create-invoice-details-col-heading">
                Invoice No. :
              </div>
              <input
                onKeyDown={handleKeyDown}
                onChange={handleInvoiceNumberChange}
                name="invoiceNumber"
                ref={inputRef}
                onWheel={handleWheel}
                defaultValue={
                  businessData.invoiceSettingData.lastInvoiceNumber + 1
                }
                type="number"
              />
            </div>

            <div className="create-invoice-details-col">
              <div className="create-invoice-details-col-heading">
                Invoice Date :
              </div>
              <input
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                name="invoiceDate"
                type="date"
                id=""
                value={data.invoiceDate}
              />
            </div>

            {enableDueDate ? (
              <div className="create-invoice-details-col-border">
                <div className="create-invoice-details-col-close">
                  <i
                    class="fa-solid fa-circle-xmark"
                    onClick={() => {
                      setEnableDueDate(false);
                    }}
                  ></i>
                </div>
                <div className="create-invoice-details-col">
                  <div className="create-invoice-details-col-heading">
                    Due Date :
                  </div>
                  <input
                    onKeyDown={handleKeyDown}
                    type="date"
                    name="invoiceDueDate"
                    onChange={handleChange}
                  />
                </div>
              </div>
            ) : (
              <div
                className="create-invoice-details-link"
                onClick={() => setEnableDueDate(true)}
              >
                + add due Date
              </div>
            )}
          </div>

          {/* invoice section 2 *****************************************************************************************************88 */}

          <div className="create-invoice-section-2">
            <div className="create-invoice-section-2-col-1">
              <div className="create-invoice-billing-link">+ Add Party</div>

              <div className="create-invoice-billing-address">
                <div className="create-invoice-address-heading"></div>
                <div className="create-invoice-address-name"></div>
                <div className="create-invoice-address-address"></div>
                <div className="create-invoice-address-number"></div>
                <div className="create-invoice-address-gst"></div>
              </div>
            </div>

            <div className="create-invoice-section-2-col-2">
              <div className="create-invoice-section-2-col-2-col">
                <div className="create-invoice-section-2-col-2-heading">
                  Bike
                </div>
                <input type="text" />
              </div>
            </div>

            {/* <table>
              <tr>
                {
                    partyData?

                <>
                <td className="create-invoice-address">
                  <div className="create-invoice-address-head">Bill To </div>
                  <div className="create-invoice-address-content">

                    <div className="create-invoice-address-name">{partyData.partyName}</div>
                    <div className="create-invoice-address-address"></div>
             
                  </div>
                </td>

                <td className="create-invoice-address">
                  <div className="create-invoice-address-heading">
                    Ship To <span>remove shipping address</span>
                  </div>
                  <div>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Delectus explicabo pariatur 
                  </div>
                  <div>Lorem ipsum dolor sit amet.</div>
                  <div>789098780-0987789</div>
                </td>
                </>
                :
                ""}

                <td>
                  <div>
                    <label htmlFor="">bike </label>
                    <input type="text" name="" id="" />
                  </div>
                </td>
              </tr>
            </table> */}
          </div>
        </div>
      </div>

      {/* 1111111111111111111111111111111111111111 */}



      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default CreateSalesInvoiceForm;
