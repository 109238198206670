import React from 'react'
import PricingPageComponent from './components/PricingPageComponent'
import Navbar from './components/main/Navbar'
import FooterComponent from './components/main/FooterComponent'

const PricingPage = () => {
  return (
    <div>
      <div className="web-navbar">
      <Navbar/>

      </div>
        <PricingPageComponent/>
        <FooterComponent/>
    </div>
  )
}

export default PricingPage