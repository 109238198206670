import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./app/store/store";
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
  {/* <Provider > */}
    <App />
  </Provider>

  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
);

reportWebVitals();
