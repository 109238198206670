import React, { useContext, useEffect, useState } from 'react'
import CreateSalesInoiceComponent from './components/CreateSalesInoiceComponent'
import { AuthContext } from '../../../context/AuthContext';
import LoadingComponent from '../../../components/extra/LoadingComponent';

const CreateSalesInvoicePage = () => {

    // get from storage ============================================================================================

    const userId = localStorage.getItem("invoician-user-id");
    const businessId = localStorage.getItem("business-id");
  
  
  // useStates  ================================================================================================================

  const [businessData, setBusinessData] = useState(null);

    // fetch company  ================================================================================================================

    const { fetchBusiness } = useContext(AuthContext);

    const fetchBusinessData = () =>
      fetchBusiness(userId, businessId, setBusinessData);
  
    useEffect(() => {
      fetchBusinessData();
    }, []);


    if (!businessData)
      return (
        <div>
          <LoadingComponent />
        </div>
      );
    
    

  return (
    <div>
      <CreateSalesInoiceComponent businessData={businessData} fetchBusinessData={fetchBusinessData}/>
    </div>
  )
}

export default CreateSalesInvoicePage