import React, { useContext, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";

const AddStaffForm = ({ open, setTimerPopupAlert, setTimerAlertMessage, fetchData }) => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  // useStates  ================================================================================================================

  const [addPayroll, setAddPayroll] = useState(false);
  const [loading, setLoading] = useState(false);




  
  const [data, setData] = useState({
    staffName : "",
    staffIdentification : "",
    staffMobileNumber : "",
    staffDesignation : "",
    staffEmail : "",
    staffAddress : "",
    enableStaffPayroll : false,
    staffPayoutType : "Monthly",
    staffSalary : "",
    staffSalaryCycle : "1 to 1 every month",
    staffOpeningBalanceAmount : 0,
    staffOpeningBalanceType : "To Pay",    
  });
      
  const handleChange = (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value,
    });
  };

const { createStaff } = useContext(AuthContext);

const handleCreate = async (e) => {  
setLoading(true);
e.preventDefault();
await createStaff(userId, businessId, data, open, setTimerPopupAlert, setTimerAlertMessage, fetchData);
setLoading(false);
};



const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission on Enter
    }
  };
  
  

  return (
    <div>
      <form onSubmit={handleCreate} className="add-staff-form">
        <div className="add-staff-form-main">
          <div className="add-staff-form-head">
            <div>Add Staff</div>
            <div>
              <i
                onClick={() => open(false)}
                class="fa-solid fa-circle-xmark"
              ></i>
            </div>
          </div>
          <div className="add-staff-form-content">
            <div className="add-staff-form-col">
              <label>Staff Name *</label>
              <input required onKeyDown={handleKeyDown} onChange={handleChange}  type="text" name="staffName" />
            </div>

            <div className="add-staff-form-col">
              <label>Id Number</label>
              <input onKeyDown={handleKeyDown} onChange={handleChange}  type="text" name="staffIdentification" />
            </div>

            <div className="add-staff-form-col">
              <label>Mobile Number</label>
              <input onKeyDown={handleKeyDown} onChange={handleChange}  type="text" name="staffMobileNumber"  />
            </div>

            <div className="add-staff-form-col">
              <label>Designation</label>
              <input onKeyDown={handleKeyDown} onChange={handleChange}  type="text" name="staffDesignation" />
            </div>

            <div className="add-staff-form-col">
              <label>Email</label>
              <input onKeyDown={handleKeyDown} onChange={handleChange}  type="text" name="staffEmail" />
            </div>

            <div className="add-staff-form-col">
              <label>Address</label>
              <input onKeyDown={handleKeyDown} onChange={handleChange}  type="text" name="staffAddress" />
            </div>
          </div>

          {addPayroll ? (
            <>
              <div className="add-staff-form-heading">
                <div>Staff Payroll</div>
                <span onClick={(e) => {setAddPayroll(false); setData({...data, enableStaffPayroll : false })}}>
                  - remove payroll
                </span>
              </div>

              <div className="add-staff-form-content">
                <div className="add-staff-form-col">
                  <label>Salary Payout Type</label>
                  <select onChange={handleChange} name="staffPayoutType">
                    <option value="Monthly">Monthly</option>
                    <option value="Weekly">Weekly</option>
                  </select>
                </div>

                <div className="add-staff-form-col">
                  <label>Salary</label>
                  <input required onKeyDown={handleKeyDown} onChange={handleChange}  type="number" name="staffSalary" />
                </div>

                <div className="add-staff-form-col">
                  <label>Salary Cycle</label>
                  <select onChange={handleChange} name="staffSalaryCycle">
                    <option value="1 to 1 every month">1 to 1 every month</option>
                    <option value="2 to 2 every month">2 to 2 every month</option>
                    <option value="3 to 3 every month">3 to 3 every month</option>
                    <option value="4 to 4 every month">4 to 4 every month</option>
                    <option value="5 to 5 every month">5 to 5 every month</option>
                    <option value="6 to 6 every month">6 to 6 every month</option>
                    <option value="7 to 7 every month">7 to 7 every month</option>
                    <option value="8 to 8 every month">8 to 8 every month</option>
                    <option value="9 to 9 every month">9 to 9 every month</option>
                    <option value="10 to 10 every month">10 to 10 every month</option>
                    <option value="11 to 11 every month">11 to 11 every month</option>
                    <option value="12 to 12 every month">12 to 12 every month</option>
                    <option value="13 to 13 every month">13 to 13 every month</option>
                    <option value="14 to 14 every month">14 to 14 every month</option>
                    <option value="15 to 15 every month">15 to 15 every month</option>
                    <option value="16 to 16 every month">16 to 16 every month</option>
                    <option value="17 to 17 every month">17 to 17 every month</option>
                    <option value="18 to 18 every month">18 to 18 every month</option>
                    <option value="19 to 19 every month">19 to 19 every month</option>
                    <option value="20 to 20 every month">20 to 20 every month</option>
                    <option value="21 to 21 every month">21 to 21 every month</option>
                    <option value="22 to 22 every month">22 to 22 every month</option>
                    <option value="23 to 23 every month">23 to 23 every month</option>
                    <option value="24 to 24 every month">24 to 24 every month</option>
                    <option value="25 to 25 every month">25 to 25 every month</option>
                    <option value="26 to 26 every month">26 to 26 every month</option>
                    <option value="27 to 27 every month">27 to 27 every month</option>
                    <option value="28 to 28 every month">28 to 28 every month</option>
                  </select>
                </div>

                <div className="add-staff-form-col">
                  <label>Outstanding/Opening Balance</label>
                  <div>
                    <input onKeyDown={handleKeyDown} onChange={handleChange}  type="number" name="staffOpeningBalanceAmount" />
                    <select onChange={handleChange} name="staffOpeningBalanceType" id="">
                      <option value="To Pay">To Pay</option>
                      <option value="To Collect">To Collect</option>
                    </select>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {!addPayroll ? (
            <div
              onClick={(e) => {setAddPayroll(true); setData({...data, enableStaffPayroll : true })}}
              className="add-staff-form-option"
            >
              + Add Payroll
            </div>
          ) : (
            ""
          )}

          <div className="add-staff-form-buttons">
            <div onClick={() => open(false)}>Cancel</div>
            <button>Create</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddStaffForm;
