import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import LoadingComponent from "../../../components/extra/LoadingComponent";

const AppTransactionPage = () => {
// get from storage ============================================================================================

const userId = localStorage.getItem("invoician-user-id");
const businessId = localStorage.getItem("business-id");

// useState ============================================================================================
const [search, setsearch] = useState("");


  const [businessData, setBusinessData] = useState(null)
  
  //  ============================================================================================
  const { fetchBusiness } = useContext(AuthContext);
  const fetchBusinessData = (e) => fetchBusiness(userId, businessId, setBusinessData);

  
  useEffect(() => {
    fetchBusinessData();
  }, []);
  

  
  
  
  if ( !businessData)
    return (
      <div>
        <LoadingComponent />
      </div>
    );

    
    const salesInvoiceData = businessData.salesInvoiceData.map( e => {
      return { ...e, invoiceType : "sales Invoice"}
  });
  const quotationData = businessData.quotationData.map( e => {
      return { ...e, invoiceType : "quotation"}
  });
  const proformaData = businessData.proformaData.map( e => {
      return { ...e, invoiceType : "proforma"}
  });
  const deliveryChallanData = businessData.deliveryChallanData.map( e => {
      return { ...e, invoiceType : "delivery Challan"}
  });
  const salesReturnData = businessData.salesReturnData.map( e => {
      return { ...e, invoiceType : "sales Return"}
  });
  const creditNoteData = businessData.creditNoteData.map( e => {
      return { ...e, invoiceType : "credit Note"}
  });
  const purchaseInvoiceData = businessData.purchaseInvoiceData.map( e => {
      return { ...e, invoiceType : "purchase Invoice"}
  });
  const purchaseReturnData = businessData.purchaseReturnData.map( e => {
      return { ...e, invoiceType : "purchase Return"}
  });
  const debitNoteData = businessData.debitNoteData.map( e => {
      return { ...e, invoiceType : "debit Note"}
  });
  const purchaseOrderData = businessData.purchaseOrderData.map( e => {
      return { ...e, invoiceType : "purchase Order"}
  });

  const combinedData = [
      ...salesInvoiceData,
      ...quotationData,
      ...proformaData,
      ...deliveryChallanData,
      ...salesReturnData,
      ...creditNoteData,
      ...purchaseInvoiceData,
      ...purchaseReturnData,
      ...debitNoteData,
      ...purchaseOrderData,
  ]
  
  combinedData.sort((a, b) => new Date(b.invoiceDate) - new Date(a.invoiceDate));
  
  
  
  return (
    <div>
      <div>
         <div className="app-transaction-page">
        <div className="app-transaction-page-main">
          <div className="app-transaction-page-top">
            <div>All Transactions</div>
            <div className="app-transaction-page-top-search">
              <i class="fa-solid fa-magnifying-glass"></i>
              <input type="text" value={search}
                  onChange={(e) => setsearch(e.target.value)} placeholder="search transaction..." />
                  {search?
              <i class="fa-solid fa-xmark" 
              onClick={(e) => setsearch("")}></i>
                  :""}
            </div>
          </div>

          <div className="app-transaction-page-table">
            <div className="app-transaction-page-table-head">
              <div>Date</div>
              <div>Transaction Number</div>
              <div>Type</div>
              <div>Party Name</div>
              <div>Amount</div>
            </div>
            <div className="app-transaction-page-table-body">
                {
                    combinedData
                    .filter((e) =>
                      search
                        ? e.invoicePartyData.partyName
                            .toLocaleLowerCase()
                            .includes(search.toLowerCase())
                        : e
                    )
                    .map( e => (
                        <>
                        <div>{new Date(e.invoiceDate).toDateString()}</div>
                        <div>{e.invoiceNumber}</div>
                        <div>{e.invoiceType}</div>
                        <div>{e.invoicePartyData.partyName}</div>
                        <div>₹ {e.invoiceTotalAmount}</div>

                        </>
                    ))
                }
            </div>
          </div>

          {
             combinedData
             .filter((e) =>
               search
                 ? e.invoicePartyData.partyName
                     .toLocaleLowerCase()
                     .includes(search.toLowerCase())
                 : e
             ).length<=0?
             <div className="app-transaction-page-blank">No Transactions</div>
             :""
          }
          


          
          
        </div>
      </div>
    </div>
      
    </div>
  );
};

export default AppTransactionPage;
