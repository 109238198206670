import React, { useEffect, useState } from "react";

const TimerPopupAlertComponent = ({ message, setTimerPopupAlert, status }) => {
  const [showAlert, setShowAlert] = useState(true);
  const [progress, setProgress] = useState(100); // Percentage of the time bar

  useEffect(() => {
    let timer;
    if (showAlert) {
      timer = setInterval(() => {
        setProgress((prev) => {
          if (prev <= 0) {
            clearInterval(timer);
            setShowAlert(false);
            setTimerPopupAlert && setTimerPopupAlert(false);
            return 0;
          }
          return prev - 100 / 25; // Decrease by 2% every 100ms
        });
      }, 100);
    }

    return () => clearInterval(timer); // Cleanup timer on component unmount
  }, [showAlert]);

  const handleClose = () => {
    setTimerPopupAlert && setTimerPopupAlert(false);
    setShowAlert(false);
  };

  return (
    <div>
      {showAlert ? (
        <div className="timer-popup-alert">
          <div
            className="timer-popup-alert-bar"
            style={{ width: `${progress}%` }}
          ></div>
          <div className="timer-popup-alert-close">
            <i onClick={handleClose} className="fa-solid fa-circle-xmark"></i>
          </div>
          <div className="timer-popup-alert-text">
            {status && status === "True" ? (
              <i class="fa-solid fa-circle-check"></i>
            ) : status && status === "False" ? (
              // <i className="fa-solid fa-circle-exclamation"></i>
              <i class="fa-solid fa-triangle-exclamation"></i>
            ) : (
              ""
            )}

            {message}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TimerPopupAlertComponent;
