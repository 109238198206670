import React, { useContext, useState } from 'react'
// import LoadingComponent from '../../../components/extra/LoadingComponent'
import { AuthContext } from '../../../context/AuthContext';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import logo from "../../../media/images/logo/invoician-logo.png";
import TimerPopupAlertComponent from '../../../components/extra/TimerPopupAlertComponent';
// import AppConfiguration from '../../components/main/AppConfiguration';

const UserLoginPage = () => {

  // useStates =======================================================================================
  const [loading, setLoading] = useState(false)
  const [UserCorrect, setUserCorrect] = useState(false)
  const [error, setError] = useState("");
  const [timerPopupAlert, setTimerPopupAlert] = useState(false)
  const [timerAlertMessage, setTimerAlertMessage] = useState("")

  
  
  
  const [user, setUser] = useState({
    email: "",
    password: "",
    otp: "",
  });



  

  const id = localStorage.getItem("invoician-user-id")
  const token = localStorage.getItem("invoician-token")
  
  //  useContent ==================================================================================
  const { loginOtp, verifyLoginOtp } = useContext(AuthContext);

  const navigate = useNavigate()

   
  
  // functions ========================================================================================
  
  const handleChange = (e) => {
    setError("")
    const value = e.target.value;
    setUser({
      ...user,
      [e.target.name]: value,
    });
  };

  const handleLoginOtp = async (e) => {
    setLoading(true);
    e.preventDefault();
    await loginOtp( user, setUserCorrect, setError, setTimerPopupAlert, setTimerAlertMessage )
    setLoading(false);
  };

  

  const handleVerifyOtp = async (e) => {
    setLoading(true);
    e.preventDefault();
    await verifyLoginOtp( user, setUserCorrect, setError, navigate )
    setLoading(false);
  };

  if (token || id) return <Navigate to="/app"/>

  
  
  return (
    <div>


{
  timerPopupAlert?
  <TimerPopupAlertComponent message={timerAlertMessage} setTimerPopupAlert={setTimerPopupAlert}/>
  :""
}
      {/* <AppConfiguration/> */}

{/* {loading ? <LoadingComponent /> : ""} */}

<div className="register-page">
  <div className="register-page-main">
    <div className="register-page-content">
      <div className="register-page-head">
        <div className="register-page-head-logo">
          <img src={logo} alt="invocian-logo" title="invoician logo" />
        </div>
        <div className="register-page-head-heading-1">
          {UserCorrect ? "OTP Sent On Mail" : "Login To Your Account"}
        </div>
      </div>

      <div className="register-page-form">
        {UserCorrect ? (
          <form onSubmit={handleVerifyOtp}>
           <input
              required
              value={user.email}
              className="cursor-disable"
              type="email"
              placeholder="Email"
            />
            <input
              required
              onChange={handleChange}
              value={user.otp}
              name="otp"
              type="text"
              placeholder="Confirm OTP"
            />
            {error ? (
              <div className="register-page-head-error">{error}</div>
            ) : (
              ""
            )}

{
              loading ?
      <div className="loading-button"> <div></div> Login  <div class="button-loader"></div> </div>
              :
              <button>Login</button>
            }
            
            {/* <button>Login</button> */}
            <div className="register-page-form-end">
              <Link onClick={(e) => setUserCorrect(false)}>
                Incorrect email addresses?
              </Link>
            </div>
          </form>
        ) : (
          <form onSubmit={handleLoginOtp}>
            
            <input
              required
              onChange={handleChange}
              name="email"
              type="email"
              placeholder="Email"
            />
            <input
              required
              onChange={handleChange}
              name="password"
              type="password"
              placeholder="Password"
            />

            {error ? (
              <div className="register-page-head-error">{error}</div>
            ) : (
              ""
            )}

            {
              loading ?
      <div className="loading-button"> <div></div> Request Otp  <div class="button-loader"></div> </div>
              :
              <button>Request Otp</button>
            }
            
            <div className="register-page-form-end">
            <Link to="/register">Create an account?</Link>
            <Link to="/update-password">Forgot password? </Link>
            </div>
            {/* <div className="register-page-form-end">Don't have an account? <Link to="/register">Register</Link></div> */}
          </form>
        )}
      </div>
    </div>
  </div>
</div>
        
        
    </div>
  )
}

export default UserLoginPage 