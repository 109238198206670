import React, { useContext, useEffect, useRef, useState } from "react";
import LoadingComponent from "../../../../components/extra/LoadingComponent";
import { AuthContext } from "../../../../context/AuthContext";
import PopUpAlertComponent from "../../../../components/extra/PopUpAlertComponent";

import UpdateSaleInvoiceFormOne from "../update/UpdateSaleInvoiceFormOne";
import ViewSaleInvoiceFormOne from "./ViewSaleInvoiceFormOne";

import html2pdf from "html2pdf.js";
import ViewSaleInvoiceFormOneCopy from "./ViewSaleInvoiceFormOneCopy";
import DownloadingComponent from "../../../../components/extra/DownloadingComponent";
import CreateSaleInvoiceFormTwo from "../../../components/forms/CreateSaleInvoiceFormTwo";
import ViewSaleInvoiceTwo from "./ViewSaleInvoiceTwo";
import UpdateSaleInvoiceComponent from "../../form/components/UpdateSaleInvoiceComponent";

const ViewInvoicePage = ({
  open,
  businessData,
  fetchBusinessData,
  fetchData,
  data,
  setSingleFetchData,
  dataId,
  setTimerPopupAlert,
  setTimerAlertMessage,
  setTimerAlertStatus,
}) => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  const [loading, setLoading] = useState(false);
  const [popUpAlert, setPopUpAlert] = useState(false);
  const [updateForm, setUpdateForm] = useState(false);

  const [downloading, setDownloading] = useState(false);

  // const [data, setData] = useState()

  const { deleteSingleInvoice, fetchSingleItem } = useContext(AuthContext);

  // const fetchItemData = (e) => fetchSingleItem(userId, businessId, itemId, setData);

  // useEffect(() => {
  //   fetchItemData();
  // }, []);

  const handleDelete = async (e) => {
    setLoading(true);
    await deleteSingleInvoice(
      userId,
      businessId,
      dataId,
      open,
      setTimerPopupAlert,
      setTimerAlertMessage,
      setTimerAlertStatus,
    );
    fetchData();
    setLoading(false);
  };

  //   ======================================================================================================================================================
  //   ======================================================================================================================================================

  //   ======================================================================================================================================================

  //   ======================================================================================================================================================

  const divRef = useRef(null);
  const [invoiceHeight, setInvoiceHeight] = useState(0);

  const handleInvoiceHeight = (e) => {
    if (divRef.current) {
      setInvoiceHeight(divRef.current.offsetHeight);
    }
  };

  const generatePDF = async () => {
    const element = document.getElementById("content-to-pdf");
    setDownloading(true);
    // margin: [0, 0, .3, 0],
    const options = {
      margin: 0,
      filename: `${
        data.invoicePartyData && data.invoicePartyData.partyName
      } Invoice-${data.invoiceNumber}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      // jsPDF: { unit: 'px', format: [800, 1100], orientation: 'portrait' },
      jsPDF: {
        unit: "px",
        format: [800, invoiceHeight + 20],
        orientation: "portrait",
      },
      // jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
    };
    await html2pdf().from(element).set(options).save();
    setDownloading(false);
  };

  useEffect(() => {
    // if (divRef.current) {
    //   setInvoiceHeight(divRef.current.offsetHeight);
    // }

    handleInvoiceHeight();
  }, [generatePDF]);

  //   ======================================================================================================================================================

  if (!data) return <LoadingComponent />;
  if (!businessData) return <LoadingComponent />;

  if (businessData.invoiceSettingData.invoiceThemeColor) {
    document.documentElement.style.setProperty(
      "--invoice",
      businessData.invoiceSettingData.invoiceThemeColor
    );
  }

  //   console.log("first -----", data)

  return (
    <div>
      {downloading ? <DownloadingComponent /> : ""}

      {popUpAlert ? (
        <PopUpAlertComponent
          open={setPopUpAlert}
          ok={handleDelete}
          heading={`You want to delete "${
            data.invoicePartyData ? data.invoicePartyData.partyName : "this"
          }" data?`}
        />
      ) : (
        ""
      )}

      {updateForm ? (
        <>
          {/* <UpdateSaleInvoiceFormOne
            open={setUpdateForm}
            invoiceData={data}
            fetchData={fetchData}
            id={dataId}
            setSingleFetchData={setSingleFetchData}
            setTimerPopupAlert={setTimerPopupAlert}
            setTimerAlertMessage={setTimerAlertMessage}
            businessData={businessData}
            handleInvoiceHeight={handleInvoiceHeight}
          /> */}

          <UpdateSaleInvoiceComponent
            open={setUpdateForm}
            invoiceData={data}
            fetchData={fetchData}
            id={dataId}
            setSingleFetchData={setSingleFetchData}
            setTimerPopupAlert={setTimerPopupAlert}
            setTimerAlertMessage={setTimerAlertMessage}
            businessData={businessData}
            handleInvoiceHeight={handleInvoiceHeight}
            setTimerAlertStatus={setTimerAlertStatus}


            fetchBusinessData={fetchBusinessData}
            // businessData={businessData}
            // invoiceData={data}
          />
        </>
      ) : (
        ""
      )}

      <div className="app-form">
        <div className="app-form-main">
          <div className="app-form-top">
            <div className="app-form-top-col-1">
              <div
                onClick={(e) => open(false)}
                className="app-form-top-col-back"
              >
                {/* <i className="fa-solid fa-arrow-left"></i> */}
                <i className="fa-solid fa-chevron-left"></i>
              </div>
              <div className="app-form-top-col-heading">
                {/* {data.itemName ? data.itemName : "---------"} */}
                {/* {data.invoicePartyData && data.invoicePartyData.partyName ? (
                  <>"{data.invoicePartyData.partyName}"</>
                ) : (
                  "View"
                )}{" "}
                Invoice  */}
                Sales Invoice {data.invoiceNumber?<>#{data.invoiceNumber}</>:""}
              </div>
            </div>

            <div className="app-form-top-col-2">
              <div className="app-form-top-col-2-buttons">
                {/* <div
                  className="app-form-top-col-button-4"
                  onClick={(e) => open(false)}
                >
                  {" "}
                  close
                </div> */}

                <div
                  onClick={generatePDF}
                  className="app-form-top-col-button-2"
                >
                  <i class="fa-solid fa-download"></i> download
                </div>

                <button
                  className="app-form-top-col-button-1"
                  onClick={(e) => setUpdateForm(true)}
                >
                  <i className="fa-solid fa-pen-to-square"></i> Edit
                </button>

                <div
                  className="app-form-top-col-button-3"
                  onClick={(e) => setPopUpAlert(true)}
                >
                  <i className="fa-solid fa-trash"></i> Delete
                </div>

                {/* <button className="app-form-top-col-button-1" ><i className="fa-solid fa-pen-to-square"></i> Edit</button> */}
              </div>
            </div>
          </div>

          <div className="invoice-theme-form">
            <div ref={divRef} id="content-to-pdf" className="invoice-theme-1">
              {/* <p>The height of the div is: {invoiceHeight}px</p> */}
              <ViewSaleInvoiceFormOne
                  data={data}
                  businessData={businessData}
                />
              
              {/* {businessData &&
              businessData.invoiceSettingData.invoiceTheme === 1 ? (
                <ViewSaleInvoiceFormOne
                  data={data}
                  businessData={businessData}
                />
              ) : businessData &&
              businessData.invoiceSettingData.invoiceTheme === 2 ? (
                <ViewSaleInvoiceTwo data={data} businessData={businessData} />
              ) : (
                <ViewSaleInvoiceFormOne
                  data={data}
                  businessData={businessData}
                />
                  
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewInvoicePage;
