import React, { useContext, useEffect, useState } from 'react'
import AppSettingInvoicePageComponent from './AppSettingInvoicePageComponent'
import { AuthContext } from '../../../context/AuthContext';
import LoadingComponent from '../../../components/extra/LoadingComponent';

const AppSettingInvoicePage = () => {
  
// get from storage ================================================================================================================================================

const userId = localStorage.getItem("invoician-user-id");
const businessId = localStorage.getItem("business-id");


// usestates ================================================================================================================================================

const [businessUpdateGetData, setBusinessUpdateGetData] = useState()


// ================================================================================================================================================

  const { fetchBusiness } = useContext(AuthContext);


  const fetchBusinessData = e =>  fetchBusiness( userId, businessId, setBusinessUpdateGetData)
  

useEffect(() => {
  fetchBusinessData()
},[]);
    
if (!businessUpdateGetData)return (<div><LoadingComponent /></div>);

  
  return (
    <div>
      <AppSettingInvoicePageComponent businessData={businessUpdateGetData}/>
    </div>
  )
}

export default AppSettingInvoicePage