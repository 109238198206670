import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import LoadingComponent from "../../../components/extra/LoadingComponent";
import TimerPopupAlertComponent from "../../../components/extra/TimerPopupAlertComponent";
import ViewPurchaseInvoicePage from "./view/ViewPurchaseInvoicePage";
import ViewPurchaseReturnPage from "./view/ViewPurchaseReturnPage";

const AppPurchaseReturnPage = () => {
  
  // get from storage ============================================================================================
  
  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");
  
  // useState ============================================================================================
  const [fetchData, setFetchData] = useState()
  const [businessData, setBusinessData] = useState(null);

  const [singlefetchData, setSingleFetchData] = useState()
  const [singlefetchDataId, setSingleFetchDataId] = useState();
  const [timerPopupAlert, setTimerPopupAlert] = useState(false)
  const [timerAlertMessage, setTimerAlertMessage] = useState("")
  const [timerAlertStatus, setTimerAlertStatus] = useState("")
  const [search, setsearch] = useState("");
  
  // const [openSetting, setOpenSetting] = useState(false)
  
  const [viewData, setViewData] = useState(false)




  
  //  ============================================================================================
   // fetch company  ================================================================================================================

    
  const { fetchPurchaseReturn, fetchBusiness } = useContext(AuthContext);
  
  const fetchInvoiceData = (e) => fetchPurchaseReturn(userId, businessId, setFetchData);
  
  const fetchBusinessData = (e) => fetchBusiness(userId, businessId, setBusinessData);
  
  useEffect(() => {
    fetchInvoiceData();
    fetchBusinessData();
  }, []);
  

  //  filtered data ==================================================================================================================

  const [filteredData, setFilteredData] = useState("")
  

  // sort ascending ------------------------------------------------------------------------------
  
  const [sortOrder, setSortOrder] = useState("")
  
  const handleAtoZ = () => {
    setSortOrder("z-a")
    const sorted = [...fetchData].sort((a, b) => {
      return a.invoicePartyData&&a.invoicePartyData.partyName.localeCompare(b.invoicePartyData&&b.invoicePartyData.partyName);
    });
    setFetchData(sorted);
  };  
  
  // sort descending ------------------------------------------------------------------------------
  
  
  const handleZtoA = () => {
    setSortOrder("a-z")
    const sorted = [...fetchData].sort((a, b) => {
      return b.invoicePartyData&&b.invoicePartyData.partyName.localeCompare(a.invoicePartyData&&a.invoicePartyData.partyName);
    });
    setFetchData(sorted);
  };  
  
  
  // sort number ascending ------------------------------------------------------------------------------
  
  
  const handlePriceAcs = () => {
    setSortOrder("p-9-1")
    const sorted = [...fetchData].sort((a, b) => {
      return a.invoiceTotalAmount - b.invoiceTotalAmount;
      
    });
    setFetchData(sorted);
    
  };  
  
  // sort number descending ------------------------------------------------------------------------------

  const handlePriceDes = () => {
    setSortOrder("p-1-9")
    const sorted = [...fetchData].sort((a, b) => {
        return b.invoiceTotalAmount - a.invoiceTotalAmount;
    });
    setFetchData(sorted);

  }; 
  
  
  if (!fetchData || !businessData)return (<div><LoadingComponent /></div>);


  
  

  return (
  

<div>

{
  viewData?
  <ViewPurchaseReturnPage open={setViewData} fetchData={fetchInvoiceData} businessData={businessData} fetchBusinessData={fetchBusinessData} data={singlefetchData} dataId={singlefetchDataId} setSingleFetchData={setSingleFetchData} setTimerPopupAlert={setTimerPopupAlert} setTimerAlertMessage={setTimerAlertMessage} setTimerAlertStatus={setTimerAlertStatus}/>
  :""
}
  
  
  
{
timerPopupAlert?
<TimerPopupAlertComponent message={timerAlertMessage} setTimerPopupAlert={setTimerPopupAlert} status={timerAlertStatus}/>
:""
}


<div className="app-page">
  <div className="app-main-page">
    
    
    
    <div className="app-page-top">
      <div className="app-page-top-col">
        <div className="app-page-top-heading">Purchase Return</div>
      </div>
      <div className="app-page-top-col">

        <div className="app-page-top-search">
        <i className="fa-solid fa-magnifying-glass"></i>{" "}
        <input type="text" placeholder="search invoice...." value={search} onChange={(e) => setsearch(e.target.value)}/>
{
search? 
<i onClick={e=>setsearch('')} className="fa-solid fa-circle-xmark"></i>
:""
}
      </div>
        
        <Link to="/app/add-purchase-return" className="app-page-top-button">Create Purchase Return</Link>

      </div>
    </div>




          {/* <div className="app-page-filter app-page-filter-3">
            <div
              onClick={(e) => setFilteredData("")}
              className={
                filteredData === ""
                  ? "app-page-filter-col-1 app-page-filter-col-active"
                  : "app-page-filter-col-1"
              }
            >
              <div className="app-page-filter-col-icon">
                <i className="fa-solid fa-file-lines"></i>
              </div>
              <div className="app-page-filter-col-text">

            {
              fetchData
              .map(e=>(e.invoiceTotalAmount))
              .reduce((a,b)=>a+b, 0).toLocaleString() 
            }

                <span>Total Return</span>
              </div>
            </div>

           
            <div
              onClick={(e) => setFilteredData("Paid")}
              className={
                filteredData === "Paid"
                  ? "app-page-filter-col-2 app-page-filter-col-active"
                  : "app-page-filter-col-2"
              }
            >
              <div className="app-page-filter-col-icon">
                <i className="fa-solid fa-indian-rupee-sign"></i>
              </div>
              <div className="app-page-filter-col-text">
             
                  
            {
              fetchData
              .filter(e=>e.receivedAmount)
              .map(e=>e.receivedAmount)
              .reduce((a,b)=>a+b, 0) 
            }
                
                <span>Paid</span>
              </div>
            </div>

           
            
            <div
              onClick={(e) => setFilteredData("Unpaid")}
              className={
                filteredData === "Unpaid"
                  ? "app-page-filter-col-3 app-page-filter-col-active"
                  : "app-page-filter-col-3"
              }
            >
              <div className="app-page-filter-col-icon">
                <i className="fa-solid fa-indian-rupee-sign"></i>
              </div>
              <div className="app-page-filter-col-text">
                
            {(fetchData.map(e=>(e.invoiceTotalAmount)).reduce((a,b)=>a+b, 0) - fetchData.map(e=>(e.receivedAmount?e.receivedAmount:0)).reduce((a,b)=>a+b, 0)).toLocaleString() }
                      
                <span>Unpaid</span>
              </div>
            </div>

            
          </div> */}





    <div className="app-page-table app-page-table-quotation">
      <div className="app-page-table-head">

<div>Date </div>

<div>Return No.</div>
        

        {
sortOrder === "z-a"?

<div onClick={handleZtoA} className="app-page-table-head-filter">
        <>Party Name <div><i className="fa-solid fa-sort-up"></i> <i className="fa-solid fa-sort-down app-page-table-head-filter-active"></i></div></>
      </div>
:
sortOrder === "a-z"?
      <div onClick={handleAtoZ}  className="app-page-table-head-filter">
        <>Party Name <div><i className="fa-solid fa-sort-up  app-page-table-head-filter-active"></i> <i className="fa-solid fa-sort-down"></i></div></>
      </div>
        :
      <div onClick={handleAtoZ}  className="app-page-table-head-filter">
        <>Party Name <div><i className="fa-solid fa-sort-up"></i> <i className="fa-solid fa-sort-down"></i></div></>
      </div>
}

  
        
{
sortOrder === "p-9-1"?

<div onClick={handlePriceDes} className="app-page-table-head-filter">
        <>Amount <div><i className="fa-solid fa-sort-up"></i> <i className="fa-solid fa-sort-down app-page-table-head-filter-active"></i></div></>
      </div>
:
sortOrder === "p-1-9"?
      <div onClick={handlePriceAcs}  className="app-page-table-head-filter">
        <>Amount <div><i className="fa-solid fa-sort-up  app-page-table-head-filter-active"></i> <i className="fa-solid fa-sort-down"></i></div></>
      </div>
        :
      <div onClick={handlePriceAcs}  className="app-page-table-head-filter">
        <>Amount <div><i className="fa-solid fa-sort-up"></i> <i className="fa-solid fa-sort-down"></i></div></>
      </div>
}

{/* <div>Status</div> */}

      </div>

      <div className="app-page-table-body">
{


fetchData.filter(e=> filteredData === "Paid"?  e.receivedAmount>0 : filteredData === "Unpaid"? e.receivedAmount < e.invoiceTotalAmount || e.receivedAmount === 0 || !e.receivedAmount  :  e )
  .filter( e => search?e.invoicePartyData&&e.invoicePartyData.partyName.toLocaleLowerCase().includes(search.toLowerCase()):e )
.map( e => (
<div key={e._id} className="app-page-table-body-data">
<div onClick={ a => {setViewData(true) ; setSingleFetchData(e); setSingleFetchDataId(e._id); } }>{e.invoiceDate?new Date(e.invoiceDate).toDateString():"-"}</div>
<div onClick={ a => {setViewData(true) ; setSingleFetchData(e); setSingleFetchDataId(e._id); } }>{e.invoiceNumber?e.invoiceNumber:"-"}</div>
<div onClick={ a => {setViewData(true) ; setSingleFetchData(e); setSingleFetchDataId(e._id); } }>{e.invoicePartyData?e.invoicePartyData.partyName:"-"}</div>

<div onClick={ a => {setViewData(true) ; setSingleFetchData(e); setSingleFetchDataId(e._id); } }>
₹ {e.invoiceTotalAmount.toFixed(2)}
</div>

{/* <div>
   {e.invoiceTotalAmount === e.receivedAmount? <div className="app-page-table-body-data-paid">Paid</div> : !e.receivedAmount || e.receivedAmount === 0? <div className="app-page-table-body-data-unpaid">Unpaid</div> : e.receivedAmount>0? <div className="app-page-table-body-data-partially">Partially Paid</div> : "" } 
</div> */}


</div>
) )
}
        

      </div>
    {
fetchData.filter(e=> filteredData === "Paid"?  e.receivedAmount>0 : filteredData === "Unpaid"? e.receivedAmount < e.invoiceTotalAmount || e.receivedAmount === 0 || !e.receivedAmount  :  e )
  .filter( e => search?e.invoicePartyData&&e.invoicePartyData.partyName.toLocaleLowerCase().includes(search.toLowerCase()):e )

.length == 0
||
      fetchData.length===0?
      <div className="app-page-table-blank">
        <span>No such data found</span>
        </div>
      
      :""
    }

    </div>
  </div>
</div>
</div>
    
  );
};

export default AppPurchaseReturnPage;
