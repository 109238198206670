import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cart: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {

    // ------------------------------------------------------------------------------------------
    addItems: (state, action) => {
      let find = state.cart.findIndex((item) => item._id === action.payload._id);
      if (find >= 0) {
        alert("item already added to bill !")
      } else {
        state.cart.push(action.payload);
      }
    },
  

    // ------------------------------------------------------------------------------------------
    
    
    removeItem: (state, action) => {
      state.cart = state.cart.filter((item) => item._id !== action.payload);
      },
      
// ------------------------------------------------------------------------------------------
      emptyCart: (state) => {
      state.cart = [];
    },
    
 // ------------------------------------------------------------------------------------------
    
    
      
      
      // ------------------------------------------------------------------------------------------


    
    
    
    // ------------------------------------------------------------------------------------------

    
    
    
    
    // ------------------------------------------------------------------------------------------

    
    
    

  },
});

export const {
  addItems,
  removeItem,
  emptyCart,
} = cartSlice.actions;

export default cartSlice.reducer;
