import React from "react";
import AppConfiguration from "./components/main/AppConfiguration";
import { Route, Routes } from "react-router-dom";
import AppPartiesPage from "./pages/main/AppPartiesPage";
import SettingNavbar from "./components/SettingNavbar";
import AppAccountSettingPage from "./pages/setting/AppAccountSettingPage";
import AppBusinessSettingPage from "./pages/setting/AppBusinessSettingPage";
import AppInvoiceSettingPage from "./pages/setting/AppInvoiceSettingPage";
import AppSettingInvoicePage from "./pages/setting/AppSettingInvoicePage";
import AppSettingBusinessPage from "./pages/setting/AppSettingBusinessPage";

const AppSetting = () => {
  return (
    <div>
      <AppConfiguration />

      <div className="app-page-layout">
        <div className="app-page-main">
          <div className="app-page-navbar">
            <SettingNavbar />
          </div>
          <div className="app-page-content">
            <Routes>
              <Route path="account" element={<AppAccountSettingPage />} />
              <Route path="business" element={<AppSettingBusinessPage />} />
              <Route path="invoice" element={<AppSettingInvoicePage />} />

              
              {/* <Route path="invoices" element={<AppInvoiceSettingPage />} /> */}
              {/* <Route path="businesss" element={<AppBusinessSettingPage />} /> */}
              
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppSetting;
