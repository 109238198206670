import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import LoadingComponent from "../../../components/extra/LoadingComponent";
import TimerPopupAlertComponent from "../../../components/extra/TimerPopupAlertComponent";
import ViewPartyComponent from "../form/components/ViewPartyComponent";

const AppPartiesPage = () => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  // useState ============================================================================================
  const [singlePartyData, setSinglePartyData] = useState();
  const [singlefetchDataId, setSingleFetchDataId] = useState();

  const [partyData, setPartyData] = useState(null);
  const [businessData, setBusinessData] = useState(null)
  const [viewParty, setViewParty] = useState(false);
  const [timerPopupAlert, setTimerPopupAlert] = useState(false);
  const [timerAlertMessage, setTimerAlertMessage] = useState("");
  const [timerAlertStatus, setTimerAlertStatus] = useState("");

  const [searchItem, setSearchItem] = useState("");

  //  ============================================================================================
  
  const location = useLocation();
  const path = location.pathname;
  

  //  ============================================================================================
  //  ============================================================================================
  const { fetchParties, fetchBusiness } = useContext(AuthContext);

  const fetchPartyData = (e) => fetchParties(userId, businessId, setPartyData);
  const fetchBusinessData = (e) => fetchBusiness(userId, businessId, setBusinessData);

  useEffect(() => {
    fetchPartyData();
    fetchBusinessData();
  }, []);

  //  filtered data ==================================================================================================================

  const [filteredData, setFilteredData] = useState("");

  // sort ascending ------------------------------------------------------------------------------

  const [sortOrder, setSortOrder] = useState("");

  const handleAtoZ = () => {
    setSortOrder("z-a");
    const sorted = [...partyData].sort((a, b) => {
      return a.partyName.localeCompare(b.partyName);
    });
    setPartyData(sorted);
  };

  // sort descending ------------------------------------------------------------------------------

  const handleZtoA = () => {
    setSortOrder("a-z");
    const sorted = [...partyData].sort((a, b) => {
      return b.partyName.localeCompare(a.partyName);
    });
    setPartyData(sorted);
  };

  // sort number ascending ------------------------------------------------------------------------------

  const handlePriceAcs = () => {
    setSortOrder("p-9-1");
    const sorted = [...partyData].sort((a, b) => {
      return partyTotalAmount(a._id, a.partyOpeningBalance, a.partyOpeningBalanceType) - partyTotalAmount(b._id, b.partyOpeningBalance, b.partyOpeningBalanceType);
    });
    setPartyData(sorted);
  };

  // sort number descending ------------------------------------------------------------------------------

  const handlePriceDes = () => {
    setSortOrder("p-1-9");
    const sorted = [...partyData].sort((a, b) => {
      return partyTotalAmount(b._id, b.partyOpeningBalance, b.partyOpeningBalanceType) - partyTotalAmount(a._id, a.partyOpeningBalance, a.partyOpeningBalanceType);
    });
    setPartyData(sorted);
  };

  if (!partyData && !businessData)
    return (
      <div>
        <LoadingComponent />
      </div>
    );
    

      
  // ================================================================================
  // ================================================================================
  
  const partyTotalAmount = (id, openingBalance, openingBalanceType) => {
    
    const salesData = businessData && businessData.salesInvoiceData.filter(
      (e) => e.invoicePartyData._id === id
    );
    
    const salesReturnData = businessData && businessData.salesReturnData.filter(
      (e) => e.invoicePartyData._id === id
    );
    
    const creditNoteData = businessData && businessData.creditNoteData.filter(
      (e) => e.invoicePartyData._id === id
    );
    
    const purchaseData = businessData && businessData.purchaseInvoiceData.filter(
      (e) => e.invoicePartyData._id === id
    );
    
    const purchaseReturnData = businessData && businessData.purchaseReturnData.filter(
      (e) => e.invoicePartyData._id === id
    );
    
    const debitNoteData = businessData && businessData.debitNoteData.filter(
      (e) => e.invoicePartyData._id === id
    );

    const salesBalance = salesData && salesData.reduce((e, data) => e + (data.invoiceTotalAmount - data.receivedAmount), 0 )
    const salesReturnBalance = salesReturnData && salesReturnData.reduce((e, data) => e + (data.invoiceTotalAmount - data.receivedAmount), 0 )
    const creditNoteBalance = creditNoteData && creditNoteData.reduce((e, data) => e + (data.invoiceTotalAmount - data.receivedAmount), 0 )

    const purchaseBalance = purchaseData && purchaseData.reduce((e, data) => e + (data.invoiceTotalAmount - data.receivedAmount), 0 )
    const purchaseReturnBalance = purchaseReturnData && purchaseReturnData.reduce((e, data) => e + (data.invoiceTotalAmount - data.receivedAmount), 0 )
    const debitNoteBalance = debitNoteData && debitNoteData.reduce((e, data) => e + (data.invoiceTotalAmount - data.receivedAmount), 0 )
    // return salesBalance;

    const totalBalance = openingBalanceType === "collect" ?(salesBalance && salesBalance - salesReturnBalance - creditNoteBalance - purchaseBalance + purchaseReturnBalance + debitNoteBalance + openingBalance):openingBalanceType === "pay" ?(salesBalance && salesBalance - salesReturnBalance - creditNoteBalance - purchaseBalance + purchaseReturnBalance + debitNoteBalance - openingBalance):"update balance"

    return totalBalance;

  }
 
    

  return (
    <div>
      {timerPopupAlert ? (
        <TimerPopupAlertComponent
          message={timerAlertMessage}
          setTimerPopupAlert={setTimerPopupAlert}
          status={timerAlertStatus}
        />
      ) : (
        ""
      )}
      {viewParty ? (

        <>
          <ViewPartyComponent
          open={setViewParty}
          data={singlePartyData}
          dataId={singlefetchDataId}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          setTimerAlertStatus={setTimerAlertStatus}
          fetchPartiesData={fetchPartyData}
          setSingleData={setSinglePartyData}
          setSingleFetchDataId={setSingleFetchDataId}
          partyData={partyData}
          businessData={businessData}
          fetchBusinessData={fetchBusinessData}

 
          />


        {/* <ViewPartyPage
          open={setViewParty}
          fetchPartiesData={fetchPartyData}
          userPartyId={singlePartyData._id}
          data={singlePartyData}
          setSingleData={setSinglePartyData}
          dataId={singlefetchDataId}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          /> */}

        
          </>
      ) : (
        ""
      )}
      <div className="app-page">
        <div className="app-main-page">
          <div className="app-page-top">
            <div className="app-page-top-col">
              <div className="app-page-top-heading">Parties</div>
            </div>
            <div className="app-page-top-col">
              <div className="app-page-top-search">
                <i className="fa-solid fa-magnifying-glass"></i>{" "}
                <input
                  type="text"
                  placeholder="search party...."
                  value={searchItem}
                  onChange={(e) => setSearchItem(e.target.value)}
                />
                {searchItem ? (
                  <i
                    onClick={(e) => setSearchItem("")}
                    className="fa-solid fa-circle-xmark"
                  ></i>
                ) : (
                  ""
                )}
              </div>

              <Link to="/app/add-party" className="app-page-top-button">
                Create Party
              </Link>
            </div>
          </div>

          <div className="app-page-filter app-page-filter-3">
            {/* <div className="app-page-filter-col-1 app-page-filter-col-active"> */}
            <a href="#"
              onClick={(e) => setFilteredData("")}
              className={
                filteredData === ""&&
                location.hash !== "#collect"&&
                location.hash !== "#pay"
                  ? "app-page-filter-col-1 app-page-filter-col-active"
                  : "app-page-filter-col-1"
              }
            >
              <div className="app-page-filter-col-icon">
                <i className="fa-solid fa-users"></i>
              </div>
              <div className="app-page-filter-col-text">
                {partyData&&partyData.length}
                <span>All Parties </span>
              </div>
            </a>

            {/* <div className="app-page-filter-col-2"> */}
            <a href="#collect"
              onClick={(e) => setFilteredData("To Collect")}
              className={
                filteredData === "To Collect"||
                location.hash === "#collect"
                  ? "app-page-filter-col-2 app-page-filter-col-active"
                  : "app-page-filter-col-2"
              }
            >
              <div className="app-page-filter-col-icon">
                <i className="fa-solid fa-indian-rupee-sign"></i>
              </div>
              <div className="app-page-filter-col-text">
                {new Intl.NumberFormat('en-IN').format(partyData&&partyData
                  .filter((e) => partyTotalAmount(e._id, e.partyOpeningBalance, e.partyOpeningBalanceType) > 0)
                  .reduce((sum, e) => sum +  partyTotalAmount(e._id, e.partyOpeningBalance, e.partyOpeningBalanceType) , 0)
                  )}
                <span>To Collect </span>
              </div>
            </a>

            {/* <div className="app-page-filter-col-3"> */}
            <a href="#paid"
              onClick={(e) => setFilteredData("To Pay")}
              className={
                filteredData === "To Pay"||
                location.hash === "#pay"
                  ? "app-page-filter-col-3 app-page-filter-col-active"
                  : "app-page-filter-col-3"
              }
            >
              <div className="app-page-filter-col-icon">
                <i className="fa-solid fa-indian-rupee-sign"></i>
              </div>
              <div className="app-page-filter-col-text">
                
              {new Intl.NumberFormat('en-IN').format(Math.abs(partyData&&partyData
                  .filter((e) => partyTotalAmount(e._id, e.partyOpeningBalance, e.partyOpeningBalanceType) < 0)
                  .reduce((sum, e) => sum +  partyTotalAmount(e._id, e.partyOpeningBalance, e.partyOpeningBalanceType) , 0))
                  )}
                
                <span>To Pay</span>
              </div>
            </a>
          </div>

          <div className="app-page-table app-page-table-party">
            <div className="app-page-table-head">
              {/* <div>Party Name</div> */}

              {sortOrder === "z-a" ? (
                <div
                  onClick={handleZtoA}
                  className="app-page-table-head-filter"
                >
                  <>
                    Party Name{" "}
                    <div>
                      <i className="fa-solid fa-sort-up"></i>{" "}
                      <i className="fa-solid fa-sort-down app-page-table-head-filter-active"></i>
                    </div>
                  </>
                </div>
              ) : sortOrder === "a-z" ? (
                <div
                  onClick={handleAtoZ}
                  className="app-page-table-head-filter"
                >
                  <>
                    Party Name{" "}
                    <div>
                      <i className="fa-solid fa-sort-up  app-page-table-head-filter-active"></i>{" "}
                      <i className="fa-solid fa-sort-down"></i>
                    </div>
                  </>
                </div>
              ) : (
                <div
                  onClick={handleAtoZ}
                  className="app-page-table-head-filter"
                >
                  <>
                    Party Name{" "}
                    <div>
                      <i className="fa-solid fa-sort-up"></i>{" "}
                      <i className="fa-solid fa-sort-down"></i>
                    </div>
                  </>
                </div>
              )}

              <div>Category</div>
              <div>Mobile Number</div>
              <div>Party Type</div>

              {sortOrder === "p-9-1" ? (
                <div
                  onClick={handlePriceDes}
                  className="app-page-table-head-filter"
                >
                  <>
                    Balance{" "}
                    <div>
                      <i className="fa-solid fa-sort-up"></i>{" "}
                      <i className="fa-solid fa-sort-down app-page-table-head-filter-active"></i>
                    </div>
                  </>
                </div>
              ) : sortOrder === "p-1-9" ? (
                <div
                  onClick={handlePriceAcs}
                  className="app-page-table-head-filter"
                >
                  <>
                    Balance{" "}
                    <div>
                      <i className="fa-solid fa-sort-up  app-page-table-head-filter-active"></i>{" "}
                      <i className="fa-solid fa-sort-down"></i>
                    </div>
                  </>
                </div>
              ) : (
                <div
                  onClick={handlePriceAcs}
                  className="app-page-table-head-filter"
                >
                  <>
                    Balance{" "}
                    <div>
                      <i className="fa-solid fa-sort-up"></i>{" "}
                      <i className="fa-solid fa-sort-down"></i>
                    </div>
                  </>
                </div>
              )}

              {/* <div>Balance</div> */}
            </div>

            <div className="app-page-table-body">
              {partyData&&partyData
                .filter((e) =>
                  filteredData === "To Pay" ||
                  location.hash === "#pay"
                  ? 
                  partyTotalAmount(e._id, e.partyOpeningBalance, e.partyOpeningBalanceType) < 0
                  : filteredData === "To Collect"||
                  location.hash === "#collect"
                    ? 
                  partyTotalAmount(e._id, e.partyOpeningBalance, e.partyOpeningBalanceType) > 0
                    : e
                )
                .filter((e) =>
                  e.partyName
                    .toLocaleLowerCase()
                    .includes(searchItem.toLowerCase())
                )

                .map((e) => (
                  <div
                    onClick={(a) => {
                      setViewParty(true);
                      setSinglePartyData(e);
                      setSingleFetchDataId(e._id);
                    }}
                    className="app-page-table-body-data"
                    key={e._id}
                  >
                    <div>{e.partyName}</div>
                    <div>{e.partyCategory ? e.partyCategory : "-"}</div>
                    <div>{e.partyNumber ? e.partyNumber : "-"}</div>
                    <div>{e.partyType ? e.partyType : "-"}</div>
                    <div> {partyTotalAmount(e._id, e.partyOpeningBalance, e.partyOpeningBalanceType) > 0 ? <i className="fa-solid fa-arrow-down"></i> : partyTotalAmount(e._id, e.partyOpeningBalance, e.partyOpeningBalanceType) < 0 ? <i className="fa-solid fa-arrow-up"></i>: "" } ₹ {Math.abs(partyTotalAmount(e._id, e.partyOpeningBalance, e.partyOpeningBalanceType))}</div>
                    {/* <div>
                      {" "}
                      {e.partyOpeningBalance ? (
                        <>
                          {
                          e.partyOpeningBalanceType === "To Pay" ? 
                          (
                            <>
                              <i className="fa-solid fa-arrow-up"></i>{" "}
                              {e.partyOpeningBalance.toLocaleString().length>12?`${e.partyOpeningBalance.toLocaleString().slice(0, 12)}....`:e.partyOpeningBalance.toLocaleString() }
                            </>
                          ) : 
                          e.partyOpeningBalanceType === "To Collect" ? 
                          (
                            <>
                              <i className="fa-solid fa-arrow-down"></i>{" "}
                              
                              {e.partyOpeningBalance.toLocaleString().length>12?`${e.partyOpeningBalance.toLocaleString().slice(0, 12)}....`:e.partyOpeningBalance.toLocaleString() }
                            </>
                          )
                          :<>{e.partyOpeningBalance.toLocaleString()} - {e.partyOpeningBalanceType}</>
                        }
                        </>
                      ) : (
                        "-"
                      )}
                    </div> */}
                  </div>
                ))}
            </div>
            {
              // partyData.filter(e=> filteredData === "To Pay"? e.partyOpeningBalanceType === "To Pay" : filteredData === "To Collect"? e.partyOpeningBalanceType === "To Collect" && e.partyOpeningBalance : searchItem ? e.partyName.toLocaleLowerCase().includes(searchItem.toLowerCase()) : e )
              partyData&&partyData
              .filter((e) =>
                filteredData === "To Pay" ||
                location.hash === "#pay"
                ? 
                partyTotalAmount(e._id, e.partyOpeningBalance, e.partyOpeningBalanceType) < 0
                : filteredData === "To Collect"||
                location.hash === "#collect"
                  ? 
                partyTotalAmount(e._id, e.partyOpeningBalance, e.partyOpeningBalanceType) > 0
                  : e
              )
                .filter((e) =>
                  e.partyName
                    .toLocaleLowerCase()
                    .includes(searchItem.toLowerCase())
                ).length === 0 || partyData&&partyData.length === 0 ? (
                <div className="app-page-table-blank">
                  <span>No data found</span>
                  {/* <Link to="/app/add-party">Add Parties</Link> */}
                </div>
              ) : (
                ""
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppPartiesPage;
