import React from 'react'
import CreateSalesInvoiceForm from '../../app/components/forms/CreateSalesInvoiceForm'
import DateDifferenceCalculator from './DateDifferenceCalculator'
import TimerPopupAlertComponent from '../../components/extra/TimerPopupAlertComponent'

const OtherPage = () => {
  return (
    <div>
      <CreateSalesInvoiceForm />
      {/* <DateDifferenceCalculator/> */}
      <TimerPopupAlertComponent message={"Created successfully"} status={"True"}/>
    </div>
  )
}

export default OtherPage