import React, { useContext, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import TimerPopupAlertComponent from "../../../../components/extra/TimerPopupAlertComponent";

const CreatePartyComponent = () => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  // useStates  ================================================================================================================

  const [loading, setLoading] = useState(false);
  const [timerAlertMessage, setTimerAlertMessage] = useState("");
  const [timerPopupAlert, setTimerPopupAlert] = useState(false);
  const [timerAlertStatus, setTimerAlertStatus] = useState("");

  const [data, setData] = useState({
    partyName: "",
    partyNumber: "",
    partyEmail: "",
    partyOpeningBalance: "",
    partyOpeningBalanceType: "collect",
    partyGSTIN: "",
    partyPanNumber: "",
    partyType: "Customer",
    partyCategory: "",
    partyBillingAddress: "",
    partyBillingCity: "",
    partyBillingState: "",
    partyBillingPinCode: "",
    partyBothAddressSame: true,
    partyShippingAddress: "",
    partyShippingCity: "",
    partyShippingState: "",
    partyShippingPinCode: "",

    partyCreditPeriod: 30,
    partyCreditLimit: 0,
  });

  const [billingAddressForm, setBillingAddressForm] = useState(false);
  const [shippingAddressForm, setShippingAddressForm] = useState(false);

  const [popupData, setPopupData] = useState({
    partyBillingAddress: "",
    partyBillingCity: "",
    partyBillingState: "",
    partyBillingPinCode: "",
    partyShippingAddress: "",
    partyShippingCity: "",
    partyShippingState: "",
    partyShippingPinCode: "",
  });

  const handleAddress = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setPopupData({
      ...popupData,
      [name]: value,
    });
  };

  const saveBillingAddress = () => {
    setData({
      ...data,
      partyBillingAddress: popupData.partyBillingAddress,
      partyBillingCity: popupData.partyBillingCity,
      partyBillingState: popupData.partyBillingState,
      partyBillingPinCode: popupData.partyBillingPinCode,
    });
  };

  const saveShippingAddress = () => {
    setData({
      ...data,
      partyShippingAddress: popupData.partyShippingAddress,
      partyShippingCity: popupData.partyShippingCity,
      partyShippingState: popupData.partyShippingState,
      partyShippingPinCode: popupData.partyShippingPinCode,
    });
  };

  // ================================================================================

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setData({
      ...data,
      [name]: value,
    });
  };

  const navigate = useNavigate();

  const { createParty } = useContext(AuthContext);

  const handleCreate = async (e) => {
    setLoading(true);
    e.preventDefault();
    await createParty(
      userId,
      businessId,
      data,
      setTimerPopupAlert,
      setTimerAlertMessage,
      "",
      navigate,
      "",
      "",
      setTimerAlertStatus,
    );
    setLoading(false);
  };

  return (
    <div>
      
      {timerPopupAlert ? (
        <TimerPopupAlertComponent
          message={timerAlertMessage}
          setTimerPopupAlert={setTimerPopupAlert}
          status={timerAlertStatus}
        />
      ) : (
        ""
      )}

      {/* ============================================================================================================================== */}
      {/* ============================================================================================================================== */}
      {/* ============================================================================================================================== */}

<>


      {billingAddressForm ? (
        <form
          className="create-party-popup"
          onSubmit={(e) => {
            e.preventDefault();
            setBillingAddressForm(false);
          }}
        >
          <div className="create-party-popup-main">
            <div className="create-party-popup-top">
              <div>Add Billing Address</div>
              <i
                class="fa-solid fa-xmark"
                onClick={(e) => {
                  setBillingAddressForm(false);
                }}
              ></i>
            </div>

            <div className="create-party-popup-form">
              {/* ---------------------------- */}
              {/* ---------------------------- */}
              {/* ---------------------------- */}
              {/* ---------------------------- */}

              <div>
                <div className="create-party-popup-form-col">
                  <label>
                    Street Address <span>*</span>
                  </label>
                  <textarea
                    type="text"
                    required
                    name="partyBillingAddress"
                    placeholder="Enter Street Address"
                    defaultValue={data.partyBillingAddress}
                    onChange={handleAddress}
                  />
                </div>

                <div className="create-party-popup-form-row">
                  <div className="create-party-popup-form-col">
                    <label>State</label>
                    <input
                      type="text"
                      name="partyBillingState"
                      placeholder="Enter State"
                      list="state-list"
                      defaultValue={data.partyBillingState}
                      onChange={handleAddress}
                    />

                    <datalist id="state-list">
                      <option value="Andhra Pradesh">Andhra Pradesh</option>
                      <option value="Arunachal Pradesh">
                        Arunachal Pradesh
                      </option>
                      <option value="Assam">Assam</option>
                      <option value="Bihar">Bihar</option>
                      <option value="Chandigarh">Chandigarh</option>
                      <option value="Chhattisgarh">Chhattisgarh</option>
                      <option value="Delhi">Delhi</option>
                      <option value="Goa">Goa</option>
                      <option value="Gujarat">Gujarat</option>
                      <option value="Haryana">Haryana</option>
                      <option value="Himachal Pradesh">Himachal Pradesh</option>
                      <option value="Jammu and Kashmir">
                        Jammu and Kashmir
                      </option>
                      <option value="Jharkhand">Jharkhand</option>
                      <option value="Karnataka">Karnataka</option>
                      <option value="Kerala">Kerala</option>
                      <option value="Ladakh">Ladakh</option>
                      <option value="Lakshadweep">Lakshadweep</option>
                      <option value="Madhya Pradesh">Madhya Pradesh</option>
                      <option value="Maharashtra">Maharashtra</option>
                      <option value="Manipur">Manipur</option>
                      <option value="Meghalaya">Meghalaya</option>
                      <option value="Mizoram">Mizoram</option>
                      <option value="Nagaland">Nagaland</option>
                      <option value="Odisha">Odisha</option>
                      <option value="Puducherry">Puducherry</option>
                      <option value="Punjab">Punjab</option>
                      <option value="Rajasthan">Rajasthan</option>
                      <option value="Sikkim">Sikkim</option>
                      <option value="Tamil Nadu">Tamil Nadu</option>
                      <option value="Telangana">Telangana</option>
                      <option value="Tripura">Tripura</option>
                      <option value="Uttar Pradesh">Uttar Pradesh</option>
                      <option value="Uttarakhand">Uttarakhand </option>
                    </datalist>
                  </div>

                  <div className="create-party-popup-form-col">
                    <label>Pincode</label>
                    <input
                      type="number"
                      name="partyBillingPinCode"
                      placeholder="Enter Pincode"
                      defaultValue={data.partyBillingPinCode}
                      onChange={handleAddress}
                    />
                  </div>
                </div>

                <div className="create-party-popup-form-col">
                  <label>City</label>
                  <input
                    type="text"
                    name="partyBillingCity"
                    placeholder="Enter City"
                    defaultValue={data.partyBillingCity}
                    onChange={handleAddress}
                  />
                </div>
              </div>

              {/* ---------------------------- */}
              {/* ---------------------------- */}
              {/* ---------------------------- */}
              {/* ---------------------------- */}
              {/* ---------------------------- */}
            </div>

            <div className="create-party-popup-buttons">
              <div
                className="create-party-popup-button-cancel"
                onClick={(e) => {
                  setBillingAddressForm(false);
                }}
              >
                Cancel
              </div>
              <button
                onClick={saveBillingAddress}
                className="create-party-popup-button-save"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      ) : (
        ""
      )}

      {/* ============================================================================================================================== */}

      {shippingAddressForm ? (
        <form
          className="create-party-popup"
          onSubmit={(e) => {
            e.preventDefault();
            setShippingAddressForm(false);
          }}
        >
          <div className="create-party-popup-main">
            <div className="create-party-popup-top">
              <div>Add Shipping Address</div>
              <i
                class="fa-solid fa-xmark"
                onClick={(e) => {
                  setShippingAddressForm(false);
                }}
              ></i>
            </div>

            <div className="create-party-popup-form">
              {/* ---------------------------- */}
              {/* ---------------------------- */}
              {/* ---------------------------- */}
              {/* ---------------------------- */}

              <div>
                <div className="create-party-popup-form-col">
                  <label>
                    Street Address <span>*</span>
                  </label>
                  <textarea
                    type="text"
                    required
                    name="partyShippingAddress"
                    placeholder="Enter Street Address"
                    defaultValue={data.partyShippingAddress}
                    onChange={handleAddress}
                  />
                </div>

                <div className="create-party-popup-form-row">
                  <div className="create-party-popup-form-col">
                    <label>State</label>
                    <input
                      type="text"
                      name="partyShippingState"
                      placeholder="Enter State"
                      list="state-list"
                      defaultValue={data.partyShippingState}
                      onChange={handleAddress}
                    />

                    <datalist id="state-list">
                      <option value="Andhra Pradesh">Andhra Pradesh</option>
                      <option value="Arunachal Pradesh">
                        Arunachal Pradesh
                      </option>
                      <option value="Assam">Assam</option>
                      <option value="Bihar">Bihar</option>
                      <option value="Chandigarh">Chandigarh</option>
                      <option value="Chhattisgarh">Chhattisgarh</option>
                      <option value="Delhi">Delhi</option>
                      <option value="Goa">Goa</option>
                      <option value="Gujarat">Gujarat</option>
                      <option value="Haryana">Haryana</option>
                      <option value="Himachal Pradesh">Himachal Pradesh</option>
                      <option value="Jammu and Kashmir">
                        Jammu and Kashmir
                      </option>
                      <option value="Jharkhand">Jharkhand</option>
                      <option value="Karnataka">Karnataka</option>
                      <option value="Kerala">Kerala</option>
                      <option value="Ladakh">Ladakh</option>
                      <option value="Lakshadweep">Lakshadweep</option>
                      <option value="Madhya Pradesh">Madhya Pradesh</option>
                      <option value="Maharashtra">Maharashtra</option>
                      <option value="Manipur">Manipur</option>
                      <option value="Meghalaya">Meghalaya</option>
                      <option value="Mizoram">Mizoram</option>
                      <option value="Nagaland">Nagaland</option>
                      <option value="Odisha">Odisha</option>
                      <option value="Puducherry">Puducherry</option>
                      <option value="Punjab">Punjab</option>
                      <option value="Rajasthan">Rajasthan</option>
                      <option value="Sikkim">Sikkim</option>
                      <option value="Tamil Nadu">Tamil Nadu</option>
                      <option value="Telangana">Telangana</option>
                      <option value="Tripura">Tripura</option>
                      <option value="Uttar Pradesh">Uttar Pradesh</option>
                      <option value="Uttarakhand">Uttarakhand </option>
                    </datalist>
                  </div>

                  <div className="create-party-popup-form-col">
                    <label>Pincode</label>
                    <input
                      type="number"
                      name="partyShippingPinCode"
                      placeholder="Enter Pincode"
                      defaultValue={data.partyShippingPinCode}
                      onChange={handleAddress}
                    />
                  </div>
                </div>

                <div className="create-party-popup-form-col">
                  <label>City</label>
                  <input
                    type="text"
                    name="partyShippingCity"
                    placeholder="Enter City"
                    defaultValue={data.partyShippingCity}
                    onChange={handleAddress}
                  />
                </div>
              </div>

              {/* ---------------------------- */}
              {/* ---------------------------- */}
              {/* ---------------------------- */}
              {/* ---------------------------- */}
              {/* ---------------------------- */}
            </div>

            <div className="create-party-popup-buttons">
              <div
                className="create-party-popup-button-cancel"
                onClick={(e) => {
                  setShippingAddressForm(false);
                }}
              >
                Cancel
              </div>
              <button
                onClick={saveShippingAddress}
                className="create-party-popup-button-save"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      ) : (
        ""
      )}

</>

      {/* ============================================================================================================================== */}
      {/* ============================================================================================================================== */}
      {/* ============================================================================================================================== */}

      <form onSubmit={handleCreate} className="create-party">
        <div className="create-party-main">
          <div className="create-party-top">
            <div className="create-party-top-heading">
              <i class="fa-solid fa-arrow-left" onClick={() => navigate(-1)}></i>
              {/* <i class="fa-solid fa-arrow-left" /> */}
              Create Party
            </div>
            <div className="create-party-top-buttons">
              <button className="create-party-top-save">Save</button>
            </div>
          </div>

          <div className="create-party-form-content">
            <div className="create-party-form-heading">General Details</div>
            <div className="create-party-form">
              <div className="create-party-form-row">
                <div className="create-party-form-col">
                  <label>
                    Party Name<span>*</span>
                  </label>
                  <input
                    required
                    type="text"
                    onChange={handleChange}
                    name="partyName"
                    value={data.partyName}
                    placeholder="Enter name"
                  />
                </div>

                <div className="create-party-form-col">
                  <label>Mobile Number</label>
                  <input
                    type="number"
                    onChange={handleChange}
                    name="partyNumber"
                    // value={data.partyNumber}
                    placeholder="Enter mobile number"
                  />
                </div>

                <div className="create-party-form-col">
                  <label>Email</label>
                  <input
                    type="text"
                    onChange={handleChange}
                    name="partyEmail"
                    value={data.partyEmail}
                    placeholder="Enter email"
                  />
                </div>

                <div className="create-party-form-col">
                  <label>Opening Balance</label>
                  <div className="create-party-form-col-balance">
                    <span>₹</span>
                    <input
                      type="number"
                      min={0}
                      onChange={handleChange}
                      name="partyOpeningBalance"
                      value={data.partyOpeningBalance}
                      placeholder="0"
                    />
                    <select
                      onChange={handleChange}
                      name="partyOpeningBalanceType"
                      value={data.partyOpeningBalanceType}
                    >
                      <option value="collect">To Collect</option>
                      <option value="pay">To Pay</option>
                    </select>
                  </div>
                </div>

                <div className="create-party-form-col">
                  <label>GSTIN</label>
                  <input
                    type="text"
                    onChange={handleChange}
                    name="partyGSTIN"
                    value={data.partyGSTIN}
                    placeholder="ex: 29XXXXXXXXXXXXX"
                  />
                </div>

                <div className="create-party-form-col">
                  <label>PAN Number</label>
                  <input
                    type="text"
                    onChange={handleChange}
                    name="partyPanNumber"
                    value={data.partyPanNumber}
                    placeholder="Enter party PAN Num"
                  />
                </div>
              </div>

              <div className="create-party-form-hr"></div>

              <div className="create-party-form-row">
                <div className="create-party-form-col">
                  <label>
                    Party Type<span>*</span>
                  </label>
                  <select
                    onChange={handleChange}
                    name="partyType"
                    value={data.partyType}
                  >
                    <option value="Customer">Customer</option>
                    <option value="Supplier">Supplier</option>
                  </select>
                </div>

                <div className="create-party-form-col">
                  <label>Party Category</label>
                  <input
                    type="text"
                    onChange={handleChange}
                    name="partyCategory"
                    value={data.partyCategory}
                    placeholder="Add Category"
                  />
                </div>
              </div>
            </div>

            <div className="create-party-form-heading">Address</div>
            <div className="create-party-form">
              <div className="create-party-row-2">
                <div className="create-party-form-col">
                  <div className="create-party-form-col-address">
                    <label>Billing Address</label>
                  </div>
                  <div
                    className="create-party-form-col-address-input"
                    onClick={(e) => setBillingAddressForm(true)}
                  >
                    {data.partyBillingAddress ? (
                      <>
                        {data.partyBillingAddress}
                        {data.partyBillingCity ? (
                          <>, {data.partyBillingCity}</>
                        ) : (
                          ""
                        )}
                        {data.partyBillingState ? (
                          <>, {data.partyBillingState}</>
                        ) : (
                          ""
                        )}
                        {data.partyBillingPinCode ? (
                          <> {data.partyBillingPinCode}</>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <div>Enter billing address</div>
                    )}
                  </div>
                </div>

                <div className="create-party-form-col">
                  <div className="create-party-form-col-address">
                    <label>Shipping Address</label>
                    {data.partyBothAddressSame ? (
                      <div
                        onClick={() => {
                          setPopupData("");
                          setData({
                            ...data,
                            partyBothAddressSame: false,
                            partyShippingAddress: "",
                            partyShippingCity: "",
                            partyShippingState: "",
                            partyShippingPinCode: "",
                          });
                        }}
                      >
                        <i class="fa-regular fa-square-check"></i> Same as
                        billing address
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          setData({ ...data, partyBothAddressSame: true });
                        }}
                      >
                        <i class="fa-regular fa-square"></i> Same as billing
                        address
                      </div>
                    )}
                  </div>

                  {data.partyBothAddressSame ? (
                    <div className="create-party-form-col-address-input-disable">
                      {data.partyBillingAddress ? (
                        <>
                          {data.partyBillingAddress}
                          {data.partyBillingCity ? (
                            <>, {data.partyBillingCity}</>
                          ) : (
                            ""
                          )}
                          {data.partyBillingState ? (
                            <>, {data.partyBillingState}</>
                          ) : (
                            ""
                          )}
                          {data.partyBillingPinCode ? (
                            <> {data.partyBillingPinCode}</>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <div>Enter billing address</div>
                      )}
                    </div>
                  ) : (
                    <div
                      className="create-party-form-col-address-input"
                      onClick={(e) => setShippingAddressForm(true)}
                    >
                      {data.partyShippingAddress ? (
                        <>
                          {data.partyShippingAddress}
                          {data.partyShippingCity ? (
                            <>, {data.partyShippingCity}</>
                          ) : (
                            ""
                          )}
                          {data.partyShippingState ? (
                            <>, {data.partyShippingState}</>
                          ) : (
                            ""
                          )}
                          {data.partyShippingPinCode ? (
                            <> {data.partyShippingPinCode}</>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <div>Enter shipping address</div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="create-party-form-hr"></div>

              <div className="create-party-form-row">
                <div className="create-party-form-col">
                  <label>Credit Period</label>
                  <div className="create-party-form-col-balance">
                    <input
                      type="number"
                      min={0}
                      onChange={handleChange}
                      name="partyCreditPeriod"
                      value={data.partyCreditPeriod}
                      placeholder="0"
                      defaultValue={30}
                    />
                    <div>Days</div>
                  </div>
                </div>

                <div className="create-party-form-col">
                  <label>Credit Limit</label>
                  <div className="create-party-form-col-balance">
                    <span>₹</span>
                    <input
                      type="number"
                      min={0}
                      onChange={handleChange}
                      name="partyCreditLimit"
                      value={data.partyCreditLimit}
                      placeholder="0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreatePartyComponent;
