import React from "react";

const PricingPageComponent = () => {
  return (
    <div>
      <div className="price-page">
        <div className="price-page-main">
          <div className="price-page-head">
            <div className="price-page-head-heading">
              Our <span>Plan</span> & <span>Price</span>
            </div>
          </div>

          <div className="price-page-price">
            <div className="price-page-price-col">
              <div className="price-page-price-col-heading">Diamond</div>
              {/* <div className="price-page-price-col-subheading">
                Lorem ipsum dolor sit amet.
              </div> */}
              <div className="price-page-price-col-price"><s>Rs.449</s> Free<span>/month</span></div>
              <div className="price-page-price-col-button">Subscribe</div>
              <div className="price-page-price-col-list">
                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Single Business
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Single User
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Easily Web Access
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Sales Record
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Sales Invoice
                </div>
                
                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Download Invoice
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-xmark"></i> <s>Quotation / Estimate</s>
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-xmark"></i> <s>Proforma Invoice</s>
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-xmark"></i> <s>Cash & Bank Record</s>
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-xmark"></i> <s>Purchage Record</s>
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-xmark"></i> <s>Item Batch Report</s>
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-xmark"></i> <s>Expenses Record</s>
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-xmark"></i> <s>Multiple Invoice
                  Theme</s>
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-xmark"></i> <s>Customise Invoice
                  Theme</s>
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-xmark"></i> <s>Staff Record</s>
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-xmark"></i> <s>Staff Attendance &
                  Payroll</s>
                </div>

              </div>
            </div>

            <div className="price-page-price-col">
              <div className="price-page-price-col-heading">Platinum</div>
              {/* <div className="price-page-price-col-subheading">
                Lorem ipsum dolor sit amet.
              </div> */}
              <div className="price-page-price-col-price"><s>Rs. 999</s> Free<span>/month</span></div>
              <div className="price-page-price-col-button">Subscribe</div>
              <div className="price-page-price-col-list">
                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> 2 Businesses
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> 3 Users
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Easily Web Access
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Sales Record
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Sales Invoice
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Download Invoice
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Quotation / Estimate
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Proforma Invoice
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Cash & Bank Record
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Purchage Record
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-xmark"></i> <s>Item Batch Report</s>
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-xmark"></i> <s>Expenses Record</s>
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-xmark"></i> <s>Multiple Invoice
                  Theme</s>
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-xmark"></i> <s>Customise Invoice
                  Theme</s>
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-xmark"></i> <s>Staff Record</s>
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-xmark"></i> <s>Staff Attendance &
                  Payroll</s>
                </div>

              </div>
            </div>

            <div className="price-page-price-col">
              <div className="price-page-price-col-heading">Enterprise</div>
              {/* <div className="price-page-price-col-subheading">
                Lorem ipsum dolor sit amet.
              </div> */}
              <div className="price-page-price-col-price"><s>Rs. 1599</s> Free<span>/month</span></div>
              <div className="price-page-price-col-button">Subscribe</div>
              <div className="price-page-price-col-list">
                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> 5 Businesses
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Unlimited Users
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Easily Web Access
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Sales Record
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Sales Invoice
                </div>
                
                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Download Invoice
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Quotation / Estimate
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Proforma Invoice
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Cash & Bank Record
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Purchage Record
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Item Batch Report
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Expenses Record
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Multiple Invoice
                  Theme
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Customise Invoice
                  Theme
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Staff Record
                </div>

                <div className="price-page-price-col-list-point">
                  <i class="fa-solid fa-check"></i> Staff Attendance &
                  Payroll
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPageComponent;
