import React from 'react'
// import { Link } from 'react-router-dom'
import bg from '../media/images/mainteinance.jpg'
import { Link } from 'react-router-dom'
import WebNavbar from './components/WebNavbar'
import HeaderComponent from './components/main/HeaderComponent'
import TopFeaturesComponent from './components/main/TopFeaturesComponent'
import HowWeWorkComponent from './components/main/HowWeWorkComponent'
import FooterComponent from './components/main/FooterComponent'
import StartInvoicingComponent from './components/main/StartInvoicingComponent'
import FeatureComponent from './components/main/FeatureComponent'
import NumberIncrement from './components/main/NumberIncrement'
import InvoiceTypesComponent from './components/main/InvoiceTypesComponent'
import AnytimeAnywhereComponent from './components/main/AnytimeAnywhereComponent'

const HomePage = () => {
  return (
    <div>

      <HeaderComponent/>
      <NumberIncrement/>
      <InvoiceTypesComponent />

      <AnytimeAnywhereComponent/>

      {/* <TopFeaturesComponent/>
      <HowWeWorkComponent />

      <FeatureComponent/> */}

      <StartInvoicingComponent/>
      <FooterComponent/>

      
      {/* <WebNavbar/> */}
      {/* <div className="home-page">
      <div className="home-page-content">
      <div className="home-page-content-heading-4">invoician</div>
      <div className="home-page-content-heading-3">Accounting & Billing Software</div>
      <div className="home-page-content-heading-1">Sorry!</div>
      <div className="home-page-content-heading-2">We're. doing some maintenance  </div>
      <Link to="/app">go to invoician app <i class="fa-solid fa-arrow-right-long"></i></Link>
      <br />
      
            
      </div>
      <div className="home-page-content"><img src={bg} alt="" /></div>


      </div> */}
      
    </div>
  )
}

export default HomePage