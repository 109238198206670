import React, { useState, useEffect } from "react";

import { PieChart, Pie, Cell, Tooltip } from "recharts";

const GstCalculatorComponent = () => {
  const [costPrice, setCostPrice] = useState(10000);
  const [gst, setGst] = useState(0);
  const [cess, setCess] = useState(0);
  const [profit, setProfit] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const [gstPercent, setGstPercent] = useState(18);
  const [profitPercent, setProfitPercent] = useState(0);
  const [cessPercentage, setCessPercentage] = useState(0);

  const [data, setData] = useState([]);

  const [addProfit, setAddProfit] = useState(false);
  const [addCess, setAddCess] = useState(false);

  const handleCalculator = () => {
    const cp = Number(costPrice);
    const profitValue = Number((cp * profitPercent) / 100);
    const gstValue = Number(((profitValue + cp) * gstPercent) / 100);
    const cessValue = Number(((profitValue + cp) * cessPercentage) / 100);

    setGst(gstValue);
    setCess(cessValue);
    setProfit(profitValue);
    setTotalPrice(cp + profitValue + gstValue + cessValue);

    setData([
      { name: "Cost Price", value: cp },
      { name: "Gst", value: Number(gstValue) },
      { name: "Profit", value: Number(profitValue) },
      { name: "CESS", value: Number(cessValue) },
    ]);
  };

  useEffect(() => {
    handleCalculator();
  }, [costPrice, gstPercent, profitPercent, cessPercentage]);

  // const COLORS = ["#0420b9", "#ff5959", "#62e28d", "#ffd748"];
  const COLORS = ["#174555", "#ff0000", "#1bbe52", "#ffc800"];

  



  return (
    <div>
      <div className="gst-calc">
        <div className="gst-calc-main">
          <div className="gst-calc-heading">GST Calculator</div>
          <div className="gst-calc-content">
            <div className="gst-calc-content-col-1">
              <div className="gst-calc-content-col-1-col">
                <div className="gst-calc-content-col-1-label">Cost Price</div>
                <div className="gst-calc-content-col-1-input">
                  <span>
                    <i class="fa-solid fa-indian-rupee-sign"></i>
                  </span>
                  <input
                    min={0}
                    type="number"
                    value={costPrice}
                    // onChange={(e) => setCostPrice(e.target.value)}
                    onChange={(e) => {
                      if (
                        e.target.value === "" ||
                        (e.target.value >= 0 && e.target.value <= 10000000)
                      ) {
                        setCostPrice(e.target.value.replace(/^0+/, ""));
                      }
                    }}
                  />
                </div>
              </div>
              <div className="gst-calc-content-col-1-range">
                <input
                  type="range"
                  min="0"
                  max="10000000"
                  value={costPrice}
                  step={10000}
                  onChange={(e) => {
                    if (
                      e.target.value === "" ||
                      (e.target.value >= 0 && e.target.value <= 10000000)
                    ) {
                      setCostPrice(e.target.value);
                    }
                  }}
                  className="range-slider"
                  style={{
                    background: `linear-gradient(90deg, #174555 ${(costPrice / 10000000)*100}%, #17455530 ${(costPrice / 10000000) * 100}%)`,
                  }}
                />
                <div>
                  <span><i class="fa-solid fa-indian-rupee-sign"></i> 0</span>
                  <span><i class="fa-solid fa-indian-rupee-sign"></i> 50 L</span>
                  <span><i class="fa-solid fa-indian-rupee-sign"></i> 1 Cr</span>
                </div>
              </div>

              <div className="gst-calc-content-col-1-col">
                <div className="gst-calc-content-col-1-label">GST </div>
                <div className="gst-calc-content-col-1-input">
                  <span>%</span>
                  <input
                    min={0}
                    type="number"
                    value={gstPercent}
                    onChange={(e) => {
                      if (
                        e.target.value === "" ||
                        (e.target.value >= 0 && e.target.value <= 28)
                      ) {
                        setGstPercent(e.target.value.replace(/^0+/, ""));
                        // setGstPercent(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>

              <div className="gst-calc-content-col-1-range">
                <input
                  type="range"
                  min="0"
                  max="28"
                  value={gstPercent}
                    onChange={(e) => {
                      if (
                        e.target.value === "" ||
                        (e.target.value >= 0 && e.target.value <= 28)
                      ) {
                        // setGstPercent(e.target.value.replace(/^0+/, ""));
                        setGstPercent(e.target.value);
                      }
                    }}
                  className="range-slider"
                  style={{
                    background: `linear-gradient(90deg, #174555 ${(gstPercent / 28)*100}%, #17455530 ${(gstPercent / 28) * 100}%)`,
                  }}
                />
                <div>
                  <span><i class="fa-solid fa-indian-rupee-sign"></i> 0%</span>
                  <span><i class="fa-solid fa-indian-rupee-sign"></i> 14%</span>
                  <span><i class="fa-solid fa-indian-rupee-sign"></i> 28%</span>
                </div>
              </div>

              {addProfit ? (
                <>
                <div className="gst-calc-content-col-1-col">
                  <div className="gst-calc-content-col-1-label">
                    Profit Ratio
                  </div>
                  <div className="gst-calc-content-col-1-input">
                    <span>%</span>
                    <input
                      min={0}
                      type="number"
                      value={profitPercent}
                      onChange={(e) => {
                        if (
                          e.target.value === "" ||
                          (e.target.value >= 0 && e.target.value <= 100)
                        ) {
                          setProfitPercent(e.target.value.replace(/^0+/, ""));
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="gst-calc-content-col-1-range">
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={profitPercent}
                    onChange={(e) => {
                      if (
                        e.target.value === "" ||
                        (e.target.value >= 0 && e.target.value <= 100)
                      ) {
                        // setGstPercent(e.target.value.replace(/^0+/, ""));
                        setProfitPercent(e.target.value);
                      }
                    }}
                  className="range-slider"
                  style={{
                    background: `linear-gradient(90deg, #174555 ${profitPercent}%, #17455530 ${profitPercent}%)`,
                  }}
                />
                <div>
                  <span><i class="fa-solid fa-indian-rupee-sign"></i> 0%</span>
                  <span><i class="fa-solid fa-indian-rupee-sign"></i> 50%</span>
                  <span><i class="fa-solid fa-indian-rupee-sign"></i> 100%</span>
                </div>
              </div>
                
              </>
              ) : (
                // <div className="gst-calc-content-col-1-button" onClick={() => setAddProfit(true)}>Profit Ratio</div>
                ""
              )}
              {addCess ? (
                <>
                <div className="gst-calc-content-col-1-col">
                  <div className="gst-calc-content-col-1-label">CESS</div>
                  <div className="gst-calc-content-col-1-input">
                    <span>%</span>
                    <input
                      min={0}
                      type="number"
                      value={cessPercentage}
                      onChange={(e) => {
                        if (
                          e.target.value === "" ||
                          (e.target.value >= 0 && e.target.value <= 100)
                        ) {
                          setCessPercentage(e.target.value.replace(/^0+/, ""));
                        }
                      }}
                      />
                  </div>
                </div>

                <div className="gst-calc-content-col-1-range">
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={cessPercentage}
                    onChange={(e) => {
                      if (
                        e.target.value === "" ||
                        (e.target.value >= 0 && e.target.value <= 100)
                      ) {
                        // setGstPercent(e.target.value.replace(/^0+/, ""));
                        setCessPercentage(e.target.value);
                      }
                    }}
                  className="range-slider"
                  style={{
                    background: `linear-gradient(90deg, #174555 ${cessPercentage}%, #17455530 ${cessPercentage}%)`,
                  }}
                />
                <div>
                  <span><i class="fa-solid fa-indian-rupee-sign"></i> 0%</span>
                  <span><i class="fa-solid fa-indian-rupee-sign"></i> 50%</span>
                  <span><i class="fa-solid fa-indian-rupee-sign"></i> 100%</span>
                </div>
              </div>
                
                      </>
              ) : (
                // <div className="gst-calc-content-col-1-button" onClick={() => setAddCess(true)}>CESS %</div>
                ""
              )}

              <div className="gst-calc-content-col-1-buttons">
                {!addProfit ? (
                  <div
                    className="gst-calc-content-col-1-btn"
                    onClick={() => setAddProfit(true)}
                  >
                    Add Profit Ratio{" "}
                  </div>
                ) : (
                  <div
                    className="gst-calc-content-col-1-btn gst-calc-content-col-1-btn-red"
                    onClick={() => {
                      setAddProfit(false);
                      setProfitPercent(0);
                    }}
                  >
                    Remove Profit Ratio{" "}
                  </div>
                )}
                {!addCess ? (
                  <div
                    className="gst-calc-content-col-1-btn"
                    onClick={() => setAddCess(true)}
                  >
                    Add CESS %
                  </div>
                ) : (
                  <div
                    className="gst-calc-content-col-1-btn gst-calc-content-col-1-btn-red"
                    onClick={() => {
                      setAddCess(false);
                      setCessPercentage(0);
                    }}
                  >
                    Remove CESS %{" "}
                  </div>
                )}
              </div>
            </div>

            <div className="gst-calc-content-col-2">
              <div className="gst-calc-content-col-2-graph">
                <PieChart width={280} height={280} style={{ margin: "auto" }}>
                  <Pie
                    data={data}
                    innerRadius="70%"
                    outerRadius="100%"
                    fill="#8884d8"
                    paddingAngle={0}
                  >
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
                <div className="gst-calc-content-col-2-value">
                  <span>Total Selling Price</span>
                  <div>
                    <i class="fa-solid fa-indian-rupee-sign"></i>{" "}
                    {/* {totalPrice.toLocaleString()} */}
                    {new Intl.NumberFormat("en-IN").format(totalPrice)}
                  </div>
                </div>
              </div>
              <div className="gst-calc-content-col-2-graph-lable">
                <div>
                  <div className="gst-calc-content-col-2-graph-cost"></div> Cost
                  Price
                </div>
                <div>
                  <div className="gst-calc-content-col-2-graph-gst"></div> GST
                </div>
                {profit ? (
                  <div>
                    <div className="gst-calc-content-col-2-graph-profit"></div>{" "}
                    Profit Ratio
                  </div>
                ) : (
                  ""
                )}
                {cess ? (
                  <div>
                    <div className="gst-calc-content-col-2-graph-cess"></div>{" "}
                    CESS
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="gst-calc-content-col-2-calcultion">
                <div className="gst-calc-content-col-2-calcultion-value">
                  <div>
                    <span>Cost Price</span>
                    <span>
                      <i class="fa-solid fa-indian-rupee-sign"></i>
                      {new Intl.NumberFormat("en-IN").format(costPrice)}
                    </span>
                  </div>
                  {profit ? (
                    <>
                      <div>+</div>
                      <div>
                        <span>Profit</span>
                        <span>
                          <i class="fa-solid fa-indian-rupee-sign"></i>{" "}
                          {new Intl.NumberFormat("en-IN").format(profit)}
                        </span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <>
                    <div>+</div>
                    <div>
                      <span>GST</span>
                      <span>
                        <i class="fa-solid fa-indian-rupee-sign"></i>{" "}
                        {new Intl.NumberFormat("en-IN").format(gst)}
                      </span>
                    </div>
                  </>
                  {cess ? (
                    <>
                      <div>+</div>
                      <div>
                        <span>CESS</span>
                        <span>
                          <i class="fa-solid fa-indian-rupee-sign"></i>{" "}
                          {new Intl.NumberFormat("en-IN").format(cess)}
                        </span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="gst-calc-content-col-2-calcultion-total">
                  Total Selling Price ={" "}
                  <i class="fa-solid fa-indian-rupee-sign"></i>{" "}
                  {new Intl.NumberFormat("en-IN").format(totalPrice)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GstCalculatorComponent;
