import React, { useState } from "react";
import CreateAccountPopup from "../../pages/form/CreateAccountPopup";
import TimerPopupAlertComponent from "../../../components/extra/TimerPopupAlertComponent";

const AppCashBankComponent = ({
  businessData,
  combinedData,
  totalAmount,
  fetchBusinessData,
}) => {
  // =================================================================================================

  const blankData = [];

  const [createBankAccount, setCreateBankAccount] = useState(false);

  const [timerAlertMessage, setTimerAlertMessage] = useState("");
  const [timerPopupAlert, setTimerPopupAlert] = useState(false);
  const [timerAlertStatus, setTimerAlertStatus] = useState("");

  const [bankId, setBankId] = useState(null);

  const bankData =
    businessData && businessData.bankData.find((e) => bankId === e._id);

  console.log("bankData is :", bankData);

  // =================================================================================================
  // =================================================================================================

  const [viewType, setViewType] = useState("Cash");
  const [dataViewFilter, setDataViewFilter] = useState("week");

  // ============================================================================
  // ============================================================================

  const today = new Date();
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - today.getDay()); // Set to the start of the week (Sunday)

  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1); // First day of this month

  const startOfYear = new Date(today.getFullYear(), 0, 1);
  // ============================================================================
  // ============================================================================
  // ============================================================================

  console.log("combinedData is :", combinedData);

  const cashData = combinedData.filter(
    (e) => e.paymentType === "Cash" && e.receivedAmount
  );

  const totalCash = cashData.reduce(
    (sum, e) =>
      e.invoiceType === "sales Invoice" ||
      e.invoiceType === "purchase Return" ||
      e.invoiceType === "debit Note"
        ? sum + e.receivedAmount
        : e.invoiceType === "purchase Invoice" ||
          e.invoiceType === "sales Return" ||
          e.invoiceType === "credit Note"
        ? sum - e.receivedAmount
        : sum,

    0
  );

  const totalCashPaid = cashData.reduce(
    (sum, e) =>
      e.invoiceType === "purchase Invoice" ||
      e.invoiceType === "sales Return" ||
      e.invoiceType === "credit Note"
        ? sum + e.receivedAmount
        : sum,

    0
  );

  const totalCashReceived = cashData.reduce(
    (sum, e) =>
      e.invoiceType === "sales Invoice" ||
      e.invoiceType === "purchase Return" ||
      e.invoiceType === "debit Note"
        ? sum + e.receivedAmount
        : sum,

    0
  );

  // ========================================
  const openingBalance = 0;
  let balance = openingBalance;

  const balanceCashData = cashData.reverse().map((e) => {
    balance =
      e.invoiceType === "sales Invoice" ||
      e.invoiceType === "purchase Return" ||
      e.invoiceType === "debit Note"
        ? balance + e.receivedAmount
        : e.invoiceType === "purchase Invoice" ||
          e.invoiceType === "sales Return" ||
          e.invoiceType === "credit Note"
        ? balance - e.receivedAmount
        : balance;

    return { ...e, balanceAmount: balance };
  });

  // ================================================================================================

  const filteredCashData = balanceCashData
    .reverse()
    .filter((e) =>
      dataViewFilter === "today"
        ? new Date(e.invoiceDate).toDateString() === today.toDateString()
        : dataViewFilter === "week"
        ? new Date(e.invoiceDate) >= startOfWeek &&
          new Date(e.invoiceDate) <= today
        : dataViewFilter === "month"
        ? new Date(e.invoiceDate) >= startOfMonth &&
          new Date(e.invoiceDate) <= today
        : dataViewFilter === "year"
        ? new Date(e.invoiceDate) >= startOfYear &&
          new Date(e.invoiceDate) <= today
        : e
    );

  // ================================================================================================
  // ================================================================================================
  // ================================================================================================
  // ================================================================================================
  // ================================================================================================
  // ================================================================================================
  // ================================================================================================
  // ================================================================================================

  const unlinkedData = combinedData.filter(
    (e) =>
      (e.paymentType === "Bank Transfer" ||
        e.paymentType === "UPI" ||
        e.paymentType === "Card" ||
        e.paymentType === "Cheque" ||
        e.paymentType === "Netbanking") &&
      e.receivedAmount
  );

  return (
    <div>
      {createBankAccount ? (
        <CreateAccountPopup
          open={setCreateBankAccount}
          fetchData={fetchBusinessData}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
          setTimerAlertStatus={setTimerAlertStatus}
        />
      ) : (
        ""
      )}

      {timerPopupAlert ? (
        <TimerPopupAlertComponent
          message={timerAlertMessage}
          setTimerPopupAlert={setTimerPopupAlert}
          status={timerAlertStatus}
        />
      ) : (
        ""
      )}

      <div className="app-cash-bank">
        <div className="app-cash-bank-main">
          <div className="app-cash-bank-top">
            <div className="app-cash-bank-top-heading">Cash and Bank</div>
            <div className="app-cash-bank-top-buttons">
              <div className="app-cash-bank-top-button-1">
                <i class="fa-solid fa-plus-minus"></i> Add/Reduce Money
              </div>
              <div className="app-cash-bank-top-button-1">
                <i class="fa-solid fa-right-left"></i> Transfer Money
              </div>
              <div
                className="app-cash-bank-top-button-2"
                onClick={() => setCreateBankAccount(true)}
              >
                + Add New Account
              </div>
            </div>
          </div>

          <div className="app-cash-bank-content">
            <div className="app-cash-bank-content-menu">
              <div className="app-cash-bank-content-menu-col">
                <div>
                  <div>Total Balance</div>
                  <div>
                    ₹ {new Intl.NumberFormat("en-IN").format(totalAmount)}
                  </div>
                </div>
              </div>

              <div className="app-cash-bank-content-menu-heading">Cash</div>
              <div className="app-cash-bank-content-menu-col">
                <div
                  onClick={() => setViewType("Cash")}
                  className={
                    viewType === "Cash"
                      ? "app-cash-bank-content-menu-col-active"
                      : ""
                  }
                >
                  <div>Cash in hand</div>
                  <div>₹ {totalCash}</div>
                </div>
              </div>

              <div className="app-cash-bank-content-menu-heading">
                Bank Accounts
              </div>
              <div className="app-cash-bank-content-menu-col">
                <div
                  onClick={() => setViewType("Unlinked")}
                  className={
                    viewType === "Unlinked"
                      ? "app-cash-bank-content-menu-col-active"
                      : ""
                  }
                >
                  <div>Unlinked Transactions</div>
                  <div>₹---</div>
                </div>
              </div>

              <div className="app-cash-bank-content-menu-col">
                {businessData.bankData.map((e) => (
                  <div
                    onClick={() => {
                      setViewType(e._id);
                      setBankId(e._id);
                    }}
                    className={
                      viewType === e._id
                        ? "app-cash-bank-content-menu-col-active"
                        : ""
                    }
                  >
                    <div>{e.accountName}</div>
                    <div>₹ {e.openingBalance}</div>
                  </div>
                ))}
              </div>
            </div>

            <div className="app-cash-bank-content-main">
              {/* ================================================================================================================= */}

              {viewType === "Cash" ? (
                <div className="app-cash-bank-content-data">
                  <div className="app-cash-bank-content-heading">
                    Transactions
                  </div>
                  <div className="app-cash-bank-content-options">
                    <div className="app-cash-bank-content-options-filter">
                      <i class="fa-regular fa-calendar"></i>
                      <select
                        value={dataViewFilter}
                        onChange={(e) => setDataViewFilter(e.target.value)}
                      >
                        <option value="today">Today</option>
                        <option value="week">This Week</option>
                        <option value="month">This Month</option>
                        <option value="year">This Year</option>
                        <option value="all">All</option>
                      </select>
                    </div>
                    {/* <div className="app-cash-bank-content-options-button">
                      Download Statement <i class="fa-solid fa-download"></i>
                    </div> */}
                  </div>

                  <div className="app-cash-bank-content-table">
                    <div className="app-cash-bank-content-table-head">
                      <div>Date</div>
                      <div>Type</div>
                      <div>Txn No</div>
                      <div>Party</div>
                      <div>Mode</div>
                      <div>Paid</div>
                      <div>Received</div>
                      <div>Balance</div>
                    </div>

                    <div className="app-cash-bank-content-table-data">
                      {filteredCashData.map((e) => {
                        return (
                          <div className="app-cash-bank-content-table-body">
                            <div>
                              {new Date(e.invoiceDate)
                                .toLocaleDateString("en-GB", {
                                  day: "2-digit",
                                  month: "short",
                                  year: "numeric",
                                })
                                .replace(",", "")}
                            </div>
                            <div>{e.invoiceType}</div>
                            <div>{e.invoiceNumber}</div>
                            <div>{e.invoicePartyData.partyName}</div>
                            <div>{e.paymentType}</div>

                            <div>
                              {e.invoiceType === "purchase Invoice" ||
                              e.invoiceType === "sales Return" ||
                              e.invoiceType === "credit Note" ? (
                                <>₹ {e.receivedAmount}</>
                              ) : (
                                "-"
                              )}
                            </div>

                            <div>
                              {e.invoiceType === "sales Invoice" ||
                              e.invoiceType === "purchase Return" ||
                              e.invoiceType === "debit Note" ? (
                                <>₹ {e.receivedAmount}</>
                              ) : (
                                "-"
                              )}
                            </div>

                            <div>₹ {e.balanceAmount}</div>
                          </div>
                        );
                      })}
                    </div>

                    {filteredCashData.length <= 0 ? (
                      <div className="app-cash-bank-content-table-empty">
                        No Transactions
                      </div>
                    ) : (
                      <div className="app-cash-bank-content-table-end">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div>Total</div>
                        <div>
                          ₹{" "}
                          {filteredCashData
                            .filter(
                              (e) =>
                                e.invoiceType === "purchase Invoice" ||
                                e.invoiceType === "sales Return" ||
                                e.invoiceType === "credit Note"
                            )
                            .reduce((sum, e) => sum + e.receivedAmount, 0)}
                        </div>
                        <div>
                          ₹{" "}
                          {filteredCashData
                            .filter(
                              (e) =>
                                e.invoiceType === "sales Invoice" ||
                                e.invoiceType === "purchase Return" ||
                                e.invoiceType === "debit Note"
                            )
                            .reduce((sum, e) => sum + e.receivedAmount, 0)}
                        </div>
                        <div></div>
                      </div>
                    )}
                  </div>
                </div>
              ) : 
              viewType === "Unlinked" ? (
                <div className="app-cash-bank-content-data">
                  <div className="app-cash-bank-content-heading">
                    Transactions
                  </div>

                  <div className="app-cash-bank-content-options">
                    <div className="app-cash-bank-content-options-filter">
                      <i class="fa-regular fa-calendar"></i>
                      <select
                        value={dataViewFilter}
                        onChange={(e) => setDataViewFilter(e.target.value)}
                      >
                        <option value="today">Today</option>
                        <option value="week">This Week</option>
                        <option value="month">This Month</option>
                        <option value="year">This Year</option>
                        <option value="all">All</option>
                      </select>
                    </div>
                    {/* <div className="app-cash-bank-content-options-button">
                      Download Statement <i class="fa-solid fa-download"></i>
                    </div> */}
                  </div>

                  <div className="app-cash-bank-content-table">
                    <div className="app-cash-bank-content-table-head">
                      <div>Date</div>
                      <div>Type</div>
                      <div>Txn No</div>
                      <div>Party</div>
                      <div>Mode</div>
                      <div>Paid</div>
                      <div>Received</div>
                      <div>Balance</div>
                    </div>

                    <div className="app-cash-bank-content-table-data">
                      {blankData.map((e) => (
                        <div className="app-cash-bank-content-table-body">
                          <div>
                            {new Date(e.invoiceDate)
                              .toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              })
                              .replace(",", "")}
                          </div>
                          <div>{e.invoiceType}</div>
                          <div>{e.invoiceNumber}</div>
                          <div>{e.invoicePartyData.partyName}</div>
                          <div>{e.paymentType}</div>

                          <div>
                            {e.invoiceType === "purchase Invoice" ||
                            e.invoiceType === "sales Return" ||
                            e.invoiceType === "credit Note" ? (
                              <>₹ {e.receivedAmount}</>
                            ) : (
                              "-"
                            )}
                          </div>

                          <div>
                            {e.invoiceType === "sales Invoice" ||
                            e.invoiceType === "purchase Return" ||
                            e.invoiceType === "debit Note" ? (
                              <>₹ {e.receivedAmount}</>
                            ) : (
                              "-"
                            )}
                          </div>

                          <div>₹ {e.balanceAmount}</div>
                        </div>
                      ))}
                    </div>

                    {blankData.length <= 0 ? (
                      <div className="app-cash-bank-content-table-empty">
                        Under Maintenance
                      </div>
                    ) : (
                      <div className="app-cash-bank-content-table-end">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div>Total</div>
                        <div>
                          ₹{" "}
                          {blankData
                            .filter(
                              (e) =>
                                e.invoiceType === "purchase Invoice" ||
                                e.invoiceType === "sales Return" ||
                                e.invoiceType === "credit Note"
                            )
                            .reduce((sum, e) => sum + e.receivedAmount, 0)}
                        </div>
                        <div>
                          ₹{" "}
                          {blankData
                            .filter(
                              (e) =>
                                e.invoiceType === "sales Invoice" ||
                                e.invoiceType === "purchase Return" ||
                                e.invoiceType === "debit Note"
                            )
                            .reduce((sum, e) => sum + e.receivedAmount, 0)}
                        </div>
                        <div></div>
                      </div>
                    )}
                  </div>
                </div>
              ) 
              : 
              viewType === bankId ? (
                <div className="app-cash-bank-content-data">
                  <div className="app-cash-bank-content-heading">
                    Transactions
                  </div>

                  <div className="app-cash-bank-content-bank-data">
                    <div className="app-cash-bank-content-bank-details">
                      {bankData && bankData.accountHolderName ? (
                        <div className="app-cash-bank-content-bank-details-col">
                          <div>Account Holder's Name:</div>
                          <span>{bankData.accountHolderName}</span>
                        </div>
                      ) : (
                        ""
                      )}
                      {bankData && bankData.accountName ? (
                        <div className="app-cash-bank-content-bank-details-col">
                          <div>Account Name:</div>
                          <span>{bankData.accountName}</span>
                        </div>
                      ) : (
                        ""
                      )}
                      {bankData && bankData.accountNumber ? (
                        <div className="app-cash-bank-content-bank-details-col">
                          <div>Account Number:</div>
                          <span>{bankData.accountNumber}</span>
                        </div>
                      ) : (
                        ""
                      )}
                      {bankData && bankData.IFSCcode ? (
                        <div className="app-cash-bank-content-bank-details-col">
                          <div>IFSC Code:</div>
                          <span>{bankData.IFSCcode}</span>
                        </div>
                      ) : (
                        ""
                      )}
                      {bankData && bankData.bankName ? (
                        <div className="app-cash-bank-content-bank-details-col">
                          <div>Bank & Branch:</div>
                          <span>{bankData.bankName}</span>
                        </div>
                      ) : (
                        ""
                      )}

                      {bankData && bankData.upiId ? (
                        <div className="app-cash-bank-content-bank-details-col">
                          <div>UPI ID:</div>
                          <span>{bankData.upiId}</span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="app-cash-bank-content-bank-buttons"> </div>
                  </div>

                  <div className="app-cash-bank-content-options">
                    <div className="app-cash-bank-content-options-filter">
                      <i class="fa-regular fa-calendar"></i>
                      <select
                        value={dataViewFilter}
                        onChange={(e) => setDataViewFilter(e.target.value)}
                      >
                        <option value="today">Today</option>
                        <option value="week">This Week</option>
                        <option value="month">This Month</option>
                        <option value="year">This Year</option>
                        <option value="all">All</option>
                      </select>
                    </div>
                    {/* <div className="app-cash-bank-content-options-button">
                      Download Statement <i class="fa-solid fa-download"></i>
                    </div> */}
                  </div>

                  <div className="app-cash-bank-content-table">
                    <div className="app-cash-bank-content-table-head">
                      <div>Date</div>
                      <div>Type</div>
                      <div>Txn No</div>
                      <div>Party</div>
                      <div>Mode</div>
                      <div>Paid</div>
                      <div>Received</div>
                      <div>Balance</div>
                    </div>

                    <div className="app-cash-bank-content-table-data">
                      {blankData.map((e) => (
                        <div className="app-cash-bank-content-table-body">
                          <div>
                            {new Date(e.invoiceDate)
                              .toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              })
                              .replace(",", "")}
                          </div>
                          <div>{e.invoiceType}</div>
                          <div>{e.invoiceNumber}</div>
                          <div>{e.invoicePartyData.partyName}</div>
                          <div>{e.paymentType}</div>

                          <div>
                            {e.invoiceType === "purchase Invoice" ||
                            e.invoiceType === "sales Return" ||
                            e.invoiceType === "credit Note" ? (
                              <>₹ {e.receivedAmount}</>
                            ) : (
                              "-"
                            )}
                          </div>

                          <div>
                            {e.invoiceType === "sales Invoice" ||
                            e.invoiceType === "purchase Return" ||
                            e.invoiceType === "debit Note" ? (
                              <>₹ {e.receivedAmount}</>
                            ) : (
                              "-"
                            )}
                          </div>

                          <div>₹ {e.balanceAmount}</div>
                        </div>
                      ))}
                    </div>

                    {blankData.length <= 0 ? (
                      <div className="app-cash-bank-content-table-empty">
                        No Transactions
                      </div>
                    ) : (
                      <div className="app-cash-bank-content-table-end">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div>Total</div>
                        <div>
                          ₹{" "}
                          {blankData
                            .filter(
                              (e) =>
                                e.invoiceType === "purchase Invoice" ||
                                e.invoiceType === "sales Return" ||
                                e.invoiceType === "credit Note"
                            )
                            .reduce((sum, e) => sum + e.receivedAmount, 0)}
                        </div>
                        <div>
                          ₹{" "}
                          {blankData
                            .filter(
                              (e) =>
                                e.invoiceType === "sales Invoice" ||
                                e.invoiceType === "purchase Return" ||
                                e.invoiceType === "debit Note"
                            )
                            .reduce((sum, e) => sum + e.receivedAmount, 0)}
                        </div>
                        <div></div>
                      </div>
                    )}
                  </div>
                </div>
              ) 
              : 
              (
                ""
              )}
              {/* ================================================================================================================= */}
              {/* ================================================================================================================= */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppCashBankComponent;
