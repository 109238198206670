import React from "react";
import LoanEMICalculatorComponent from "../../../website/components/pages/LoanEMICalculatorComponent";

const AppEmiCalculator = () => {
  
  return (
    <div>
     
      <div className="app-page">
        <div className="app-main-page">
          <div className="app-page-top">
            <div className="app-page-top-col">
              <div className="app-page-top-heading">EMI Calculator</div>
            </div>
           
          </div>

          <div className="app-page-content-calculator">
            <LoanEMICalculatorComponent/>
             </div>

        </div>
      </div>
    </div>
  );
};

export default AppEmiCalculator;
