import React from "react";

import GstCalculatorComponent from "../../../website/components/pages/GstCalculatorComponent";

const AppGstCalculator = () => {
  
  return (
    <div>
      

      <div className="app-page">
        <div className="app-main-page">
          <div className="app-page-top">
            <div className="app-page-top-col">
              <div className="app-page-top-heading">GST Calculator</div>
            </div>
           
          </div>

          <div className="app-page-content-calculator">
            <GstCalculatorComponent/>
            </div>

        </div>
      </div>
    </div>
  );
};

export default AppGstCalculator;
