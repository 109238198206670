import React from 'react'
import CountUp from "react-countup";

const NumberIncrement = () => {
  return (
        <div className="header-numbers">
    <div>

       
        <div className="header-numbers-col">
          <div className="header-numbers-col-inc">
            <CountUp end={25000} enableScrollSpy />+
          </div>
          <div className="header-numbers-col-txt">Happy Customers</div>
        </div>
       
        <div className="header-numbers-col">
          <div className="header-numbers-col-inc">
            {/* <CountUp end={25000} enableScrollSpy />+ */}
            <span>
            Multi-Device
            </span>
          </div>
          <div className="header-numbers-col-txt">Use on Mobile/Desktop</div>
        </div>
       
        <div className="header-numbers-col">
          <div className="header-numbers-col-inc">
            {/* <CountUp end={25000} enableScrollSpy />+ */}
            <span>
            Multi-User
            </span>
          </div>
          <div className="header-numbers-col-txt">User Management
          Feature</div>
        </div>
       
        <div className="header-numbers-col">
          <div className="header-numbers-col-inc">
            {/* <CountUp end={25000} enableScrollSpy />+ */}
            <span>
            Free
            </span>
          </div>
          <div className="header-numbers-col-txt">Get Started for Free</div>
        </div>

      </div>
    </div>
  )
}

export default NumberIncrement