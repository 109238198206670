import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import LoadingComponent from "../../../components/extra/LoadingComponent";
import CreatePartyFormPopup from "./CreatePartyFormPopup";

const SelectPartyForm = ({ open, invoicePartyData }) => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  // use state ============================================================================================

  const [searchItem, setSearchItem] = useState("");
  const [partyData, setPartyData] = useState();

  const [createData, setCreateData] = useState(false)

  const { fetchParties } = useContext(AuthContext);

  const fetchPartyData = (e) => fetchParties(userId, businessId, setPartyData);

  useEffect(() => {
    fetchPartyData();
  }, []);

  if (!partyData)
    return (
      <div>
        <LoadingComponent />
      </div>
    );

  return (
    <div>

{
  createData?
  <div className="select-form-create-form">
    <CreatePartyFormPopup open={setCreateData} close={open} fetchData={fetchPartyData} invoicePartyData={invoicePartyData} />
  </div>
  :""
}
      
      <div className="select-form">
        <div className="select-form-main">
          <div className="select-form-top">
            <div className="select-form-top-heading">Select Party</div>
            <div className="select-form-top-search">
              <div className="app-page-top-search">
                <i className="fa-solid fa-magnifying-glass"></i>{" "}
                <input
                  type="text"
                  placeholder="search party...."
                  value={searchItem} 
                  onChange={(e) => setSearchItem(e.target.value)}
                /> 
                {searchItem ? (
                  <i
                    onClick={(e) => setSearchItem("")}
                    className="fa-solid fa-circle-xmark"
                  ></i>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="select-form-data-list">
            <div className="select-form-data-list-table select-party-form-data-list-table">

              <div className="select-form-data-list-table-head select-party-form-data-list-table-head">
                <div>Party Name</div>
                <div>Mobile Number</div>
              </div>

              <div className="select-form-data-list-table-list select-party-form-data-list-table-list">
                {partyData
                  .filter((e) =>
                    e.partyName
                      .toLocaleLowerCase()
                      .includes(searchItem.toLowerCase())
                  )
                  .map((e) => (
                    <div onClick={a=>{invoicePartyData(e); console.log(e); open(false)}} className="select-form-data-list-table-body select-party-form-data-list-table-body">
                      <div>{e.partyName}</div>
                      <div>{e.partyNumber ? e.partyNumber : "-"}</div>
                    </div>
                  ))}

{
               partyData  
                .filter((e) =>
                  e.partyName
                    .toLocaleLowerCase()
                    .includes(searchItem.toLowerCase())
                ).length === 0 || partyData.length === 0 ? (
                <div className="app-page-table-blank">
                  <span>No data found</span>
                </div>
              ) : (
                ""
              )
            }
                  
              </div>
            </div>
          </div>

          <div className="select-form-end">
            <div
              className="select-form-end-button"
              onClick={(e) => open(false)}
            >
              Done
            </div>
            <div className="select-form-end-button" onClick={e=>setCreateData(true)}>Create  Party</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectPartyForm;
