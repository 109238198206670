import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import LoadingComponent from "../../../components/extra/LoadingComponent";
// import ViewPartyPage from "./view/ViewPartyPage";
import TimerPopupAlertComponent from "../../../components/extra/TimerPopupAlertComponent";
import ViewInvoicePage from "./view/ViewInvoicePage";
import AppInvoiceSettingPageComponent from "../../components/settings/AppInvoiceSettingPageComponent";

const AppSaleInvoicePage = () => {
  
  // get from storage ============================================================================================
  
  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");
  
  // useState ============================================================================================
  const [fetchData, setFetchData] = useState()
  const [businessData, setBusinessData] = useState(null);

  const [singlefetchData, setSingleFetchData] = useState()
  const [singlefetchDataId, setSingleFetchDataId] = useState();
  const [timerPopupAlert, setTimerPopupAlert] = useState(false)
  const [timerAlertMessage, setTimerAlertMessage] = useState("")
  const [timerAlertStatus, setTimerAlertStatus] = useState("")
  const [search, setsearch] = useState("");
  
  // const [openSetting, setOpenSetting] = useState(false)
  
  const [viewData, setViewData] = useState(false)




  
  //  ============================================================================================
   // fetch company  ================================================================================================================

    
  const { fetchInvoice, fetchBusiness } = useContext(AuthContext);
  
  const fetchInvoiceData = (e) => fetchInvoice(userId, businessId, setFetchData);
  
  const fetchBusinessData = (e) => fetchBusiness(userId, businessId, setBusinessData);
  
  useEffect(() => {
    fetchInvoiceData();
    fetchBusinessData();
  }, []);
  

  //  filtered data ==================================================================================================================

  const [filteredData, setFilteredData] = useState("")
  

  // sort ascending ------------------------------------------------------------------------------
  
  const [sortOrder, setSortOrder] = useState("")
  
  const handleAtoZ = () => {
    setSortOrder("z-a")
    const sorted = [...fetchData].sort((a, b) => {
      return a.invoicePartyData&&a.invoicePartyData.partyName.localeCompare(b.invoicePartyData&&b.invoicePartyData.partyName);
    });
    setFetchData(sorted);
  };  
  
  // sort descending ------------------------------------------------------------------------------
  
  
  const handleZtoA = () => {
    setSortOrder("a-z")
    const sorted = [...fetchData].sort((a, b) => {
      return b.invoicePartyData&&b.invoicePartyData.partyName.localeCompare(a.invoicePartyData&&a.invoicePartyData.partyName);
    });
    setFetchData(sorted);
  };  
  
  
  // sort number ascending ------------------------------------------------------------------------------
  
  
  const handlePriceAcs = () => {
    setSortOrder("p-9-1")
    const sorted = [...fetchData].sort((a, b) => {
      return a.invoiceTotalAmount - b.invoiceTotalAmount;
      
    });
    setFetchData(sorted);
    
  };  
  
  // sort number descending ------------------------------------------------------------------------------

  const handlePriceDes = () => {
    setSortOrder("p-1-9")
    const sorted = [...fetchData].sort((a, b) => {
        return b.invoiceTotalAmount - a.invoiceTotalAmount;
    });
    setFetchData(sorted);

  }; 
  
const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining(fetchData&&fetchData.invoiceDueDate));
  
function getTimeRemaining(targetDate) {
  const now = new Date();
  const end = new Date(targetDate);
  const difference = end - now;
  // const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  // return { days, hours, minutes, seconds };
  return Math.floor(difference / (1000 * 60 * 60 * 24))
}


useEffect(() => {
  setTimeRemaining(getTimeRemaining(fetchData&&fetchData.invoiceDueDate));

},);



  
  if (!fetchData || !businessData)return (<div><LoadingComponent /></div>);


  
  

  return (
  

<div>

{/* {
  !businessData.invoiceSettingData?
  <AppInvoiceSettingPageComponent fetchData={fetchBusinessData}/>
  :""
} */}

{/* {
  openSetting?
  <AppInvoiceSettingPageComponent fetchData={fetchBusinessData} data={businessData.invoiceSettingData} open={setOpenSetting} setTimerAlertMessage={setTimerAlertMessage} setTimerPopupAlert={setTimerPopupAlert}/>
  :""
} */}



{
  viewData?
  <ViewInvoicePage open={setViewData} fetchData={fetchInvoiceData} businessData={businessData} fetchBusinessData={fetchBusinessData} data={singlefetchData} dataId={singlefetchDataId} setSingleFetchData={setSingleFetchData} setTimerPopupAlert={setTimerPopupAlert} setTimerAlertMessage={setTimerAlertMessage} setTimerAlertStatus={setTimerAlertStatus}/>
  :""
}
  
  
  
{
timerPopupAlert?
<TimerPopupAlertComponent message={timerAlertMessage} setTimerPopupAlert={setTimerPopupAlert} status={timerAlertStatus}/>
:""
}

{/* {
  updateStock?
<UpdateItemStock open={setUpdateStock} itemData={singlefetchData} fetchData={fetchInvoiceData} fetchInvoiceData={fetchInvoiceData} setTimerPopupAlert={setTimerPopupAlert} setTimerAlertMessage={setTimerAlertMessage}/>
:""
}

{viewData?<ViewItemPage open={setViewData} fetchData={fetchInvoiceData} userDataId={singlefetchData} setTimerPopupAlert={setTimerPopupAlert} setTimerAlertMessage={setTimerAlertMessage}/>:""} */}

<div className="app-page">
  <div className="app-main-page">
    
    
    
    <div className="app-page-top">
      <div className="app-page-top-col">
        <div className="app-page-top-heading">Sales Invoices</div>
      </div>
      <div className="app-page-top-col">

        <div className="app-page-top-search">
        <i className="fa-solid fa-magnifying-glass"></i>{" "}
        <input type="text" placeholder="search invoice...." value={search} onChange={(e) => setsearch(e.target.value)}/>
{
search? 
<i onClick={e=>setsearch('')} className="fa-solid fa-circle-xmark"></i>
:""
}
      </div>
        
        {/* <div onClick={e=>setOpenSetting(true)} className="app-page-top-button-2"><i className="fa-solid fa-gear"></i></div> */}
        <Link to="/app/add-sale-invoice" className="app-page-top-button">Create Invoices</Link>

      </div>
    </div>




          <div className="app-page-filter app-page-filter-3">
            {/* <div className="app-page-filter-col-1 app-page-filter-col-active"> */}
            <div
              onClick={(e) => setFilteredData("")}
              className={
                filteredData === ""
                  ? "app-page-filter-col-1 app-page-filter-col-active"
                  : "app-page-filter-col-1"
              }
            >
              <div className="app-page-filter-col-icon">
                {/* <i className="fa-solid fa-indian-rupee-sign"></i> */}
                <i className="fa-solid fa-file-lines"></i>
              </div>
              <div className="app-page-filter-col-text">
                {/* {invoicePartyData.length} */}
                
            {/* {fetchData.map(e=> (e.invoiceItems.map(e=>((e.itemQuantity*e.itemPrice)-(e.itemQuantity*e.itemDiscount)+(e.itemQuantity*e.itemTax))).reduce((a,b)=>a+b) )).reduce((a,b)=>a+b).toLocaleString()} */}
            {
              fetchData
              .map(e=>(e.invoiceTotalAmount))
              .reduce((a,b)=>a+b, 0).toLocaleString() 
            }

              {/* {fetchData.length} */}
            {/* {fetchData.map(e=>(e.invoiceTotalAmount)) } */}
            {/* 0 */}
                {/* <span>Total Invoice</span> */}
                <span>Total Sales</span>
              </div>
            </div>

            {/* <div className={"app-page-filter-col-2"}>
              <div className="app-page-filter-col-icon">
                <i className="fa-solid fa-indian-rupee-sign"></i>
              </div>
              <div className="app-page-filter-col-text">
            {fetchData.map(e=>(e.invoiceTotalAmount)).reduce((a,b)=>a+b, 0).toLocaleString() }
                <span>Total Sales</span>

              </div>
            </div> */}

           
            <div
              onClick={(e) => setFilteredData("Paid")}
              className={
                filteredData === "Paid"
                  ? "app-page-filter-col-2 app-page-filter-col-active"
                  : "app-page-filter-col-2"
              }
            >
              <div className="app-page-filter-col-icon">
                <i className="fa-solid fa-indian-rupee-sign"></i>
              </div>
              <div className="app-page-filter-col-text">
             
                  
                  
            {/* {fetchData.map(e=>(e.receivedAmount?e.receivedAmount:0)).reduce((a,b)=>a+b, 0).toLocaleString() } */}
            {
              fetchData
              .filter(e=>e.receivedAmount)
              .map(e=>e.receivedAmount)
              .reduce((a,b)=>a+b, 0) 
            }
                
                <span>Paid</span>
              </div>
            </div>

           
            
            <div
              onClick={(e) => setFilteredData("Unpaid")}
              className={
                filteredData === "Unpaid"
                  ? "app-page-filter-col-3 app-page-filter-col-active"
                  : "app-page-filter-col-3"
              }
            >
              <div className="app-page-filter-col-icon">
                <i className="fa-solid fa-indian-rupee-sign"></i>
              </div>
              <div className="app-page-filter-col-text">
                
            {(fetchData.map(e=>(e.invoiceTotalAmount)).reduce((a,b)=>a+b, 0) - fetchData.map(e=>(e.receivedAmount?e.receivedAmount:0)).reduce((a,b)=>a+b, 0)).toLocaleString() }
                      
                <span>Unpaid</span>
              </div>
            </div>

            
          </div>





    <div className="app-page-table app-page-table-invoice">
      <div className="app-page-table-head">
        {/* <div>Party Name</div> */}

<div>Date </div>

<div>Invoice No.</div>
        

        {
sortOrder === "z-a"?

<div onClick={handleZtoA} className="app-page-table-head-filter">
        <>Party Name <div><i className="fa-solid fa-sort-up"></i> <i className="fa-solid fa-sort-down app-page-table-head-filter-active"></i></div></>
      </div>
:
sortOrder === "a-z"?
      <div onClick={handleAtoZ}  className="app-page-table-head-filter">
        <>Party Name <div><i className="fa-solid fa-sort-up  app-page-table-head-filter-active"></i> <i className="fa-solid fa-sort-down"></i></div></>
      </div>
        :
      <div onClick={handleAtoZ}  className="app-page-table-head-filter">
        <>Party Name <div><i className="fa-solid fa-sort-up"></i> <i className="fa-solid fa-sort-down"></i></div></>
      </div>
}

  
        
{
sortOrder === "p-9-1"?

<div onClick={handlePriceDes} className="app-page-table-head-filter">
        <>Amount <div><i className="fa-solid fa-sort-up"></i> <i className="fa-solid fa-sort-down app-page-table-head-filter-active"></i></div></>
      </div>
:
sortOrder === "p-1-9"?
      <div onClick={handlePriceAcs}  className="app-page-table-head-filter">
        <>Amount <div><i className="fa-solid fa-sort-up  app-page-table-head-filter-active"></i> <i className="fa-solid fa-sort-down"></i></div></>
      </div>
        :
      <div onClick={handlePriceAcs}  className="app-page-table-head-filter">
        <>Amount <div><i className="fa-solid fa-sort-up"></i> <i className="fa-solid fa-sort-down"></i></div></>
      </div>
}
{/* <div><i className="fa-solid fa-download"></i></div> */}

        {/* <div>Balance</div> */}

<div>Status</div>

      </div>

      <div className="app-page-table-body">
{


  // fetchData
  fetchData.filter(e=> filteredData === "Paid"?  e.receivedAmount>0 : filteredData === "Unpaid"? e.receivedAmount < e.invoiceTotalAmount || e.receivedAmount === 0 || !e.receivedAmount  :  e )
  .filter( e => search?e.invoicePartyData&&e.invoicePartyData.partyName.toLocaleLowerCase().includes(search.toLowerCase()):e )
.map( e => (
<div key={e._id} className="app-page-table-body-data">
<div onClick={ a => {setViewData(true) ; setSingleFetchData(e); setSingleFetchDataId(e._id); } }>{e.invoiceDate?new Date(e.invoiceDate).toDateString():"-"}</div>
<div onClick={ a => {setViewData(true) ; setSingleFetchData(e); setSingleFetchDataId(e._id); } }>{e.invoiceNumber?e.invoiceNumber:"-"}</div>
<div onClick={ a => {setViewData(true) ; setSingleFetchData(e); setSingleFetchDataId(e._id); } }>{e.invoicePartyData?e.invoicePartyData.partyName:"-"}</div>
{/* <div onClick={ a => {setViewData(true) ; setSingleFetchData(e._id); } }>{e.invoicePaymentReceived?e.invoicePaymentReceived:"-"}</div> */}
{/* <div>p : {e.invoicePaymentReceived}</div> */}
{/* <div onClick={ a => {setViewData(true) ; setSingleFetchData(e); setSingleFetchDataId(e._id); } }> {e.invoiceDueDate? <>{Math.floor((new Date(e.invoiceDueDate) -new Date() ) / (1000 * 60 * 60 * 24))} Days</> : "-"}</div> */}
{/* {e.services.map(n=>(e.taxPercentage*n.quantity*n.inventoryPrice)/100).reduce((a,b)=>a+b,0)+e.totalPrice } */}
{/* {e.services.map(e=>e.price*e.qty).reduce((a,b)=>a+b)} */}

<div onClick={ a => {setViewData(true) ; setSingleFetchData(e); setSingleFetchDataId(e._id); } }>
₹ {e.invoiceTotalAmount.toFixed(2)}
</div>

<div>
   {e.invoiceTotalAmount === e.receivedAmount? <div className="app-page-table-body-data-paid">Paid</div> : !e.receivedAmount || e.receivedAmount === 0? <div className="app-page-table-body-data-unpaid">Unpaid</div> : e.receivedAmount>0? <div className="app-page-table-body-data-partially">Partially Paid</div> : "" } 
</div>

  {/* <span>{e.invoiceItems?e.invoiceItems.map(e=>((e.itemQuantity*e.itemPrice)-(e.itemQuantity*e.itemDiscount)+(e.itemQuantity*e.itemTax))).reduce((a,b)=>a+b) :"-"} </span>
  <span>{e.invoicePaymentReceived === e.invoiceItems.map(e=>((e.itemQuantity*e.itemPrice)-(e.itemQuantity*e.itemDiscount)+(e.itemQuantity*e.itemTax))).reduce((a,b)=>a+b) ? "paid" : e.invoicePaymentReceived > 0  ? "partially Paid" : "unpaid"} </span> */}
  {/* <div>p : {e.invoicePaymentReceived}</div> */}

  {/* Rs. {e.invoiceTotalAmount}  */}
  


{/* <div onClick={ a => {setViewData(true) ; setSingleFetchData(e._id); } }>{e.itemPrice?<>Rs. {e.itemPrice}</>:"-"}</div> */}

{/* <div className="app-page-table-body-data-edit">{e.itemType === "Product"?<div  onClick={ a => {setUpdateStock(true) ; setSingleFetchData(e); } }><i className="fa-solid fa-pen-to-square"></i></div>:<div onClick={ a => {setViewData(true) ; setSingleFetchData(e._id); } }>&nbsp;</div>}</div> */}
{/* <div>{ new Date(e.invoiceDueDate) - new Date() }</div> */}

{/* <div  className="app-page-table-body-data-edit"><i className="fa-solid fa-download"></i></div> */}

</div>
) )
}
        

      </div>
    {
// fetchData.filter(e=> filteredData === "stockFilter"? e.itemType === "Product" && e.itemStock < 1 :  e ).filter( e => e.invoicePartyData.partyName.toLocaleLowerCase().includes(search.toLowerCase()) )
// fetchData.filter(e=> filteredData === "Paid"? e.invoiceTotalAmount >= e.receivedAmount : filteredData === "Unpaid"? e.receivedAmount < e.invoiceTotalAmount || e.receivedAmount === 0 || !e.receivedAmount  :  e ).filter( e => search?e.invoicePartyData&&e.invoicePartyData.partyName.toLocaleLowerCase().includes(search.toLowerCase()):e )
fetchData.filter(e=> filteredData === "Paid"?  e.receivedAmount>0 : filteredData === "Unpaid"? e.receivedAmount < e.invoiceTotalAmount || e.receivedAmount === 0 || !e.receivedAmount  :  e )
  .filter( e => search?e.invoicePartyData&&e.invoicePartyData.partyName.toLocaleLowerCase().includes(search.toLowerCase()):e )

.length == 0
||
      fetchData.length===0?
      <div className="app-page-table-blank">
        <span>No such data found</span>
        </div>
      
      :""
    }

    </div>
  </div>
</div>
</div>
    
  );
};

export default AppSaleInvoicePage;
