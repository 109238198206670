import React, { useContext, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";

const UpdateItemStock = ({
  open,
  itemData,
  fetchData,
  setTimerPopupAlert,
  setTimerAlertMessage,
}) => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");
  const itemId = itemData._id;

  // useStates  ================================================================================================================

  const [loading, setLoading] = useState(false);
  const [updatedStock, setUpdatedStock] = useState(0);
  const [stockType, setStockType] = useState("add")

  //   ================================================================================================================

  const [data, setData] = useState({
    itemStock: itemData.itemStock,
  });



  const { updateItem } = useContext(AuthContext);

  const handleUpdate = async (e) => {
    setLoading(true);
    e.preventDefault();
    await updateItem(
      userId,
      businessId,
      itemId,
      data,
      "",
      setTimerPopupAlert,
      setTimerAlertMessage,
      open
    );
    fetchData();
    setLoading(false);
  };

  const handleData = e => {
    if(stockType === "add"){
      setData({
        itemStock: itemData.itemStock + Number(updatedStock),
      })
    }
    if(stockType === "reduce"){
      setData({
        itemStock: itemData.itemStock - Number(updatedStock),
      })
    }

  }

  return (
    <div>
      <div className="app-popup-form">
        <form onSubmit={handleUpdate} className="app-popup-form-main">
          <div className="app-popup-form-top">
            <div className="app-popup-form-top-heading">
              Adjust Stock Quantity {stockType === "add" ? (`(${itemData.itemStock + Number(updatedStock)})`) : stockType === "reduce" ? (`(${itemData.itemStock - Number(updatedStock)})`) : (`(${itemData.itemStock + Number(updatedStock)})`)}

            </div>
            <div className="app-popup-form-top-close">
              <i onClick={(e) => open(false)} className="fa-solid fa-xmark"></i>
              {/* <i className="fa-solid fa-circle-xmark"></i> */}
              </div>
          </div>
          
          <div className="app-popup-form-content">
            
            <div className="app-popup-form-content-2">

              <div className="app-popup-form-col">
                <div className="app-popup-form-col-heading">{itemData.itemType} Name</div>
                <div className="app-popup-form-col-text">{itemData.itemName}</div>
              </div>

              <div className="app-popup-form-col">
                <div className="app-popup-form-col-heading">{itemData.itemType} Price</div>
                <div className="app-popup-form-col-text">{itemData.itemPrice?<>Rs. {itemData.itemPrice}</>:"-----"}</div>
              </div>

            </div>

            <div className="app-popup-form-content-1">
                <div className="app-popup-form-col-heading">Add Or Reduce Stock</div>
              
              <div className="app-popup-form-col-2">
              <div onClick={e=>setStockType("add")} className={stockType === "add" ? "app-popup-form-col-option app-popup-form-col-option-active" : "app-popup-form-col-option"}>Add (+)</div>
              <div onClick={e=>setStockType("reduce")} className={stockType === "reduce" ? "app-popup-form-col-option app-popup-form-col-option-active" : "app-popup-form-col-option"}>Reduce (-)</div>
              

              </div>
              
            </div>
            
{/* <div>data is : {data.itemStock}</div> */}
            <div className="app-popup-form-content-1">
                <div className="app-popup-form-col-heading">Adjust quantity</div>
                <div className="app-popup-form-col-input">
              <input type="number" min={0} onChange={e=> setUpdatedStock(e.target.value)} name="updatedStock" id="" placeholder="0"/>
              {
                itemData.itemUnit?
                <span>{itemData.itemUnit}</span>
                :""
              }
                </div>

            </div>

            <div className="app-popup-form-end">
            <button onClick={handleData}>Save</button>
            <div className="app-popup-form-end-button" onClick={(e) => open(false)} >Close</div>

            </div>

            
            
            
          </div>
        </form>
      </div>

    </div>
  );
};

export default UpdateItemStock;
