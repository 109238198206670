import React from 'react'
import { Link } from 'react-router-dom'

import img1 from '../../../media/images/anytime-1.webp'
import img2 from '../../../media/images/anytime-2.webp'
import img3 from '../../../media/images/anytime-3.webp'


const AnytimeAnywhereComponent = () => {
  return (
    <div>
        <div className="anytime" id="inventoryManagement">
        <div className="anytime-main">
        <div className="anytime-col-1">
        <div className="anytime-col-1-heading-1">Easily run your business</div>
        <div className="anytime-col-1-heading-1">With the Best Billing & Accounting Software</div>
        <div className="anytime-col-1-heading-2">Anytime, <br /> Anywhere!</div>
        <div className="anytime-col-1-text">Multi-user, multi-device, multi-business functionalities make invoician billing software a superpower for your business!</div>
        <Link to="/login" target='_blank' className="anytime-col-1-button">Get Started Now</Link>
            
        </div>
        <div className="anytime-col-2">
            <img src={img3} alt="" />
            <img src={img1} alt="" />
            {/* <img src={img2} alt="" /> */}
        </div>

        </div>

        </div>
    </div>
  )
}

export default AnytimeAnywhereComponent