import React, { useEffect, useState } from "react";

const LoadingComponent = () => {
  const handleReload = () => {
    window.location.reload();
  };

  
  const [showRefresh, setShowRefresh] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowRefresh(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <div className="loading-component">
        <div>
          <span>Loading</span>
          <div className="loader"></div>
        </div>
        {showRefresh?<div className="loader-link" onClick={handleReload}>refresh app</div>:""}
      </div>
    </div>
  );
};

export default LoadingComponent;
