import React from "react";
import WebNavbar from "../components/WebNavbar";
import GstCalculatorComponent from "../components/pages/GstCalculatorComponent";
import Navbar from "../components/main/Navbar";
import FooterComponent from "../components/main/FooterComponent";

const GstCalculator = () => {
  return (
    <div>
      <div className="web-header">
        <div className="web-header-main">
          <Navbar />

          <div className="calculator-blank"></div>
        </div>
      </div>
          <div className="calculator-component">
            <GstCalculatorComponent />
          </div>


      
      {/* <WebNavbar/> */}
      {/* <Navbar/> */}
      
      <FooterComponent />
    </div>
  );
};

export default GstCalculator;
