import React from "react";

const DownloadingComponent = () => {

  return (
    <div>
      <div className="downloading-component">
         &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; downloading<div className="loader"></div>
      </div>
    </div>
  );
};

export default DownloadingComponent;
