import React, { useRef } from "react";
import logo from "../../../media/images/logo/invoician-white-logo.png";
import { Link } from "react-router-dom";
// import header from "../../../media/images/header.jpeg";
// import header from "../../../media/images/header.webp";
import header from "../../../media/images/in_web.png";
import Navbar from "./Navbar";
// import header from '../../../media/images/header-1.jpeg'

const HeaderComponent = () => {
  const navRef = useRef(HeaderComponent);

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <div>
      <div className="web-header">
        <div className="web-header-main">

<Navbar/>

          
          <div className="web-header-content">
          <div className="web-header-content-main">
            <div className="web-header-content-col-1">
              <div className="web-header-content-col-1-heading-1">
                {/* India’s Most Trusted Billing, Accounting & Inventory Software */}
                India’s Most Trusted Billing, Accounting & Inventory Software
              </div>
              <div className="web-header-content-col-1-heading-2">
                Take control of your business today! Available on Web.
              </div>
              <div className="web-header-content-col-1-buttons">
                <Link to="/login" target="_blank">Start Free Invoicing</Link>
                <Link to="https://wa.me/+919958501500?text=Hello ! I'm Interested in business solution service. Please, arrange free demo for me." target="_blank">Book Free Demo</Link>
              </div>
            </div>
            <div className="web-header-content-col-2">
              <img src={header} alt="" />
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderComponent;
