import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import TimerPopupAlertComponent from "../../../components/extra/TimerPopupAlertComponent";

const CreatePartyFormPopup = ({open, close, invoicePartyData}) => {

        
  // get from storage ============================================================================================
  
  
  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");
  

    
    // useStates  ================================================================================================================

  const [ShippingAddressSame, setShippingAddressSame] = useState(false);
  const [addAddress, setAddAddress] = useState(false);
  const [addAccount, setAddAccount] = useState(false);
  const [loading, setLoading] = useState(false)
  const [timerAlertMessage, setTimerAlertMessage] = useState("")
  const [timerPopupAlert, setTimerPopupAlert] = useState(false)

//   ================================================================================================================
    const navigate = useNavigate();


    const [data, setData] = useState({
        partyName : "",
        partyNumber : "",
        partyEmail : "",
        partyOpeningBalance : "",
        partyOpeningBalanceType : "To Collect",
        partyType : "",
        partyCategory : "",
        partyBillingAddress : "",
        partyBillingState : "",
        partyBillingPinCode : "",
        partyShippingAddress : "",
        partyShippingState : "",
        partyShippingPinCode : "",
        partyGSTIN : "",
        partyPanNumber : "",
        partyCreditPeriod : "",
        partyCreditLimit : "",
      });
          
      const handleChange = (e) => {
        const value = e.target.value;
        setData({
          ...data,
          [e.target.name]: value,
        });
        if(ShippingAddressSame){
          // setLoading(true);
          setData({
          ...data,
          [e.target.name]: value,
            partyShippingAddress : data.partyBillingAddress,
            partyShippingState : data.partyBillingState,
            partyShippingPinCode : data.partyBillingPinCode,
        })
      }

        
        
        
        
      };
    
    const { createParty } = useContext(AuthContext);

    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault(); // Prevent form submission on Enter
      }
    };
    

  const handleCreate = async (e) => {  
    setLoading(true);
    e.preventDefault();
    await createParty(userId, businessId, data,  setTimerPopupAlert, setTimerAlertMessage, "", "", invoicePartyData, open);
    setLoading(false);
  };
  


  const handleShippingAddressSame = (e) => {
    setShippingAddressSame(true)
    setData({
      ...data,
        partyShippingAddress : data.partyBillingAddress,
        partyShippingState : data.partyBillingState,
        partyShippingPinCode : data.partyBillingPinCode,
    })


  }
  

  const handleShippingAddressDifferent = (e) => {
    setShippingAddressSame(false)
  }
  
  
  return (
    <div>
        
{
  timerPopupAlert?
  <TimerPopupAlertComponent message={timerAlertMessage} setTimerPopupAlert={setTimerPopupAlert}/>
  :""
}
      <form className="app-form-popup" onSubmit={handleCreate}>
        <div className="app-form-main">
          <div className="app-form-top">
            <div className="app-form-top-col-1">
              <div className="app-form-top-col-heading">Create Party</div>
            </div>

            <div className="app-form-top-col-2">
              <div className="app-form-top-col-2-buttons">
                
                
                
                
                {
                  loading === true?
                  <div className="app-form-top-col-button-disable">Updating...</div>
                  :
                  <>
                  <div onClick={e=>open(false)}  className="app-form-top-col-button-2">Close</div>
   <button className="app-form-top-col-button-1">Create</button> 
  </>
                }
                
              </div>
            </div>
          </div>

          <div className="app-form-form">
            <div className="app-form-form-head"><div><i className="fa-solid fa-file-lines"></i> General Details</div></div>

            <div className="app-form-form-body app-form-form-body-3">
              <div className="app-form-form-input-col">
                <label>Party Name *</label>
                <input onKeyDown={handleKeyDown} onChange={handleChange} name="partyName" value={data.partyName} required type="text" placeholderOff="Enter Name" />
              </div>

              <div className="app-form-form-input-col">
                <label>Mobile Number</label>
                <input onKeyDown={handleKeyDown} onChange={handleChange} name="partyNumber" value={data.partyNumber} type="text" placeholderOff="Enter Mobile Number" />
              </div>

              <div className="app-form-form-input-col">
                <label>Email</label>
                <input onKeyDown={handleKeyDown} onChange={handleChange} name="partyEmail" value={data.partyEmail} type="email" placeholderOff="Enter Email" />
              </div>

              <div className="app-form-form-input-col app-form-form-input-col-balance-type">
                <label>Opening Balance</label>
                <div>
                  <input onKeyDown={handleKeyDown} onChange={handleChange} name="partyOpeningBalance" value={data.partyOpeningBalance} type="number" placeholder="Rs." />
                  <select  onChange={handleChange} name="partyOpeningBalanceType">
                    <option value="To Collect">To Collect</option>
                    <option value="To Pay">To Pay</option>
                  </select>
                </div>
              </div>

              <div className="app-form-form-input-col">
                <label>Party Type</label>
                <input onKeyDown={handleKeyDown} onChange={handleChange} name="partyType"
                value={data.partyType}
                  list="party-type"
                  type="text"
                  placeholderOff="Add Party Type"
                />
                <datalist id="party-type">
                  <option>Customer</option>
                  <option>Supplier</option>
                </datalist>
              </div>

              <div className="app-form-form-input-col">
                <label>Party Category</label>
                <input onKeyDown={handleKeyDown} onChange={handleChange} name="partyCategory" value={data.partyCategory} type="text" placeholderOff="Enter Party Category" />
              </div>
            </div>

            {addAddress ? (
              <>
                <div className="app-form-form-head">
                    <div>
                    <i className="fa-solid fa-location-arrow"></i> Address Details
                    </div>
                  <span onClick={(e) => setAddAddress(false)}>
                    Revove Address <i className="fa-solid fa-circle-minus"></i>
                  </span>
                </div>

                <div className="app-form-form-body app-form-form-body-2">
                  <div className="app-form-form-body-col">
                    <div className="app-form-form-body-heading">
                      Billing Address
                    </div>

                    <div className="app-form-form-input-col">
                      <label>Billing Address *</label>
                      <input onKeyDown={handleKeyDown} required onChange={handleChange} value={data.partyBillingAddress} name="partyBillingAddress"
                        type="text"
                        placeholderOff="Enter Billing Address"
                      />
                    </div>

                    <div className="app-form-form-body-col-2">
                      <div className="app-form-form-input-col">
                        <label>State</label>
                        <input onKeyDown={handleKeyDown} onChange={handleChange} name="partyBillingState"
                          list="state-type"
                          value={data.partyBillingState}
                          type="text"
                          placeholderOff="Enter State"
                        />
                        <datalist id="state-type">
                          {/* <option value="Andaman and Nicobar Islands">
        Andaman and Nicobar Islands
      </option> */}
                          <option value="Andhra Pradesh">Andhra Pradesh</option>
                          <option value="Arunachal Pradesh">
                            Arunachal Pradesh
                          </option>
                          <option value="Assam">Assam</option>
                          <option value="Bihar">Bihar</option>
                          <option value="Chandigarh">Chandigarh</option>
                          <option value="Chhattisgarh">Chhattisgarh</option>
                          {/* <option value="Dadra and Nagar Haveli and Daman and Diu">
        Dadra and Nagar Haveli and Daman and Diu
      </option> */}
                          <option value="Delhi">Delhi</option>
                          <option value="Goa">Goa</option>
                          <option value="Gujarat">Gujarat</option>
                          <option value="Haryana">Haryana</option>
                          <option value="Himachal Pradesh">
                            Himachal Pradesh
                          </option>
                          <option value="Jammu and Kashmir">
                            Jammu and Kashmir
                          </option>
                          <option value="Jharkhand">Jharkhand</option>
                          <option value="Karnataka">Karnataka</option>
                          <option value="Kerala">Kerala</option>
                          <option value="Ladakh">Ladakh</option>
                          <option value="Lakshadweep">Lakshadweep</option>
                          <option value="Madhya Pradesh">Madhya Pradesh</option>
                          <option value="Maharashtra">Maharashtra</option>
                          <option value="Manipur">Manipur</option>
                          <option value="Meghalaya">Meghalaya</option>
                          <option value="Mizoram">Mizoram</option>
                          <option value="Nagaland">Nagaland</option>
                          <option value="Odisha">Odisha</option>
                          <option value="Puducherry">Puducherry</option>
                          <option value="Punjab">Punjab</option>
                          <option value="Rajasthan">Rajasthan</option>
                          <option value="Sikkim">Sikkim</option>
                          <option value="Tamil Nadu">Tamil Nadu</option>
                          <option value="Telangana">Telangana</option>
                          <option value="Tripura">Tripura</option>
                          <option value="Uttar Pradesh">Uttar Pradesh</option>
                          <option value="Uttarakhand">Uttarakhand </option>
                        </datalist>
                      </div>

                      <div className="app-form-form-input-col">
                        <label>Pincode</label>
                        <input onKeyDown={handleKeyDown} onChange={handleChange} name="partyBillingPinCode" type="number" value={data.partyBillingPinCode} placeholderOff="Enter Pincode" />
                      </div>
                    </div>
                  </div>

                  <div className="app-form-form-body-col">
                    <div className="app-form-form-body-heading">
                      Shipping Address
                      <span>
                        {ShippingAddressSame ? (
                          <div onClick={handleShippingAddressDifferent} className="app-form-form-body-heading-shipping">
                          <i className="fa-regular fa-square-check"></i>
                          same as billing address
                          </div>
                        ) : (
                          <div onClick={handleShippingAddressSame}>
                          <i className="fa-regular fa-square"></i>
                        same as billing address
                          </div>
                        )}
                      </span>
                    </div>

                    {ShippingAddressSame ? 
                    <>
                    <div className="app-form-form-input-col">
                      <label>Shipping Address</label>
                      <input onKeyDown={handleKeyDown} onChange={handleChange} name="partyShippingAddress"
                        type="text"
                        value={data.partyBillingAddress}
                        placeholderOff="Enter Shipping Address"
                        disabled
                      />
                    </div>

                    <div className="app-form-form-body-col-2">
                      <div className="app-form-form-input-col">
                        <label>State</label>

                        <input onKeyDown={handleKeyDown} onChange={handleChange} name="partyShippingState"
                          list="state-type"
                          type="text" disabled
                          value={data.partyBillingState}
                          placeholderOff="Add Party Type"
                        />
                      </div>

                      <div className="app-form-form-input-col">
                        <label>Pincode</label>
                        <input onKeyDown={handleKeyDown} onChange={handleChange} name="partyShippingPinCode" type="number" disabled value={data.partyBillingPinCode} placeholderOff="Enter Pincode" />
                      </div>




                      
                    </div>

                    </>

                    :
                    <>
                    <div className="app-form-form-input-col">
                      <label>Shipping Address</label>
                      <input onKeyDown={handleKeyDown} onChange={handleChange} name="partyShippingAddress"
                        type="text"
                        value={data.partyShippingAddress}
                        placeholderOff="Enter Shipping Address"
                      />
                    </div>

                    <div className="app-form-form-body-col-2">
                      <div className="app-form-form-input-col">
                        <label>State</label>

                        <input onKeyDown={handleKeyDown} onChange={handleChange} name="partyShippingState"
                          list="state-type"
                          type="text"
                          value={data.partyShippingState}
                          placeholderOff="Add Party Type"
                        />
                      </div>

                      <div className="app-form-form-input-col">
                        <label>Pincode</label>
                        <input onKeyDown={handleKeyDown} onChange={handleChange} name="partyShippingPinCode" type="number" value={data.partyShippingPinCode} placeholderOff="Enter Pincode" />
                      </div>




                      
                    </div>

                    </>
}
                    
                  </div>
                </div>
              </>
            ) : (
              <div className="app-form-form-head">
                <div onClick={(e) => setAddAddress(true)}>
                <i className="fa-solid fa-location-arrow"></i> Add Address Details <i className="fa-solid fa-circle-plus"></i>
                </div>
              </div>
            )}

            {
                addAccount?
            <>
            <div className="app-form-form-head">
                <div>

                <i className="fa-solid fa-file-invoice"></i> Account Details
                </div>
            <span onClick={(e) => setAddAccount(false)}>
                    Revove Account <i className="fa-solid fa-circle-minus"></i>
                  </span>
            </div>

            <div className="app-form-form-body app-form-form-body-4">

          

              <div className="app-form-form-input-col">
                <label>GSTIN</label>
                <input onKeyDown={handleKeyDown} onChange={handleChange} name="partyGSTIN" type="text" value={data.partyGSTIN} placeholderOff="Enter GSTIN" />
              </div>

              <div className="app-form-form-input-col">
                <label>PAN Number</label>
                <input onKeyDown={handleKeyDown} onChange={handleChange} name="partyPanNumber" type="text" value={data.partyPanNumber} placeholderOff="Enter Pan Number" />
              </div>

              <div className="app-form-form-input-col app-form-form-input-col-balance-type">
                <label>Credit Period</label>
                <div>
                  <input onKeyDown={handleKeyDown} onChange={handleChange} name="partyCreditPeriod" type="number" value={data.partyCreditPeriod} placeholderOff="" />
                  <span>Days</span>
                </div>
              </div>

              <div className="app-form-form-input-col">
                <label>Credit Limit</label>
                <input onKeyDown={handleKeyDown} onChange={handleChange} name="partyCreditLimit" type="number" value={data.partyCreditLimit} placeholder="Rs." />
              </div>
            </div>
            </>
            :
            <div className="app-form-form-head">
            <div onClick={(e) => setAddAccount(true)}>
            <i className="fa-solid fa-file-invoice"></i> Add Account Details <i className="fa-solid fa-circle-plus"></i>
            </div>
          </div>
}
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreatePartyFormPopup;
