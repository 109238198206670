import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import LoadingComponent from "../../../components/extra/LoadingComponent";
import TimerPopupAlertComponent from "../../../components/extra/TimerPopupAlertComponent";
import ViewItemPage from "./view/ViewItemPage";
import UpdateItemStock from "./update/UpdateItemStock";
import AddStaffForm from "../../components/forms/AddStaffForm";
import ViewStaffPage from "./view/ViewStaffPage";

const AppManageStaff = () => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  // useState ============================================================================================
  const [fetchData, setFetchData] = useState();
  const [singlefetchData, setSingleFetchData] = useState();
  const [singlefetchDataId, setSingleFetchDataId] = useState();
  const [timerPopupAlert, setTimerPopupAlert] = useState(false);
  const [timerAlertMessage, setTimerAlertMessage] = useState("");
  const [search, setsearch] = useState("");
  const [updateStock, setUpdateStock] = useState(false);

  const [viewData, setViewData] = useState(false);

  //  ============================================================================================
  const { fetchStaff} = useContext(AuthContext);

  const fetchStaffData = (e) => fetchStaff(userId, businessId, setFetchData);

  useEffect(() => {
    fetchStaffData();
  }, []);

  //  filtered data ==================================================================================================================

  const [filteredData, setFilteredData] = useState("");

  // sort ascending ------------------------------------------------------------------------------

  const [sortOrder, setSortOrder] = useState("");

  const handleAtoZ = () => {
    setSortOrder("z-a");
    const sorted = [...fetchData].sort((a, b) => {
      return a.staffName.localeCompare(b.staffName);
    });
    setFetchData(sorted);
  };

  // sort descending ------------------------------------------------------------------------------

  const handleZtoA = () => {
    setSortOrder("a-z");
    const sorted = [...fetchData].sort((a, b) => {
      return b.staffName.localeCompare(a.staffName);
    });
    setFetchData(sorted);
  };

  // sort number ascending ------------------------------------------------------------------------------

  const handlePriceAcs = () => {
    setSortOrder("p-9-1");
    const sorted = [...fetchData].sort((a, b) => {
      return a.itemPrice - b.itemPrice;
    });
    setFetchData(sorted);
  };

  // sort number descending ------------------------------------------------------------------------------

  const handlePriceDes = () => {
    setSortOrder("p-1-9");
    const sorted = [...fetchData].sort((a, b) => {
      return b.itemPrice - a.itemPrice;
    });
    setFetchData(sorted);
  };


  // ********************************************************************************************************************************************

  const [createStaffForm, setCreateStaffForm] = useState(false)
  
  
  
  if (!fetchData)
    return (
      <div>
        <LoadingComponent />
      </div>
    );

  return (
    <div>
      {timerPopupAlert ? (
        <TimerPopupAlertComponent
          message={timerAlertMessage}
          setTimerPopupAlert={setTimerPopupAlert}
        />
      ) : (
        ""
      )}

      {updateStock ? (
        <UpdateItemStock
          open={setUpdateStock}
          itemData={singlefetchData}
          fetchData={fetchStaffData}
          fetchStaffData={fetchStaffData}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
        />
      ) : (
        ""
      )}

      {viewData ? (
        <ViewStaffPage
          open={setViewData}
          fetchData={fetchStaffData}
          data={singlefetchData}
          setSingleFetchData={setSingleFetchData}
          dataId={singlefetchDataId}
          setTimerPopupAlert={setTimerPopupAlert}
          setTimerAlertMessage={setTimerAlertMessage}
        />
      ) : (
        ""
      )}


{/* ******************************************************************************************************************************88 */}

{createStaffForm?<AddStaffForm open={setCreateStaffForm} setTimerPopupAlert={setTimerPopupAlert} setTimerAlertMessage={setTimerAlertMessage} fetchData={fetchStaffData}/>:""}




      <div className="app-page">
        <div className="app-main-page">
          <div className="app-page-top">
            <div className="app-page-top-col">
              <div className="app-page-top-heading">Staff / Employees</div>
            </div>
            <div className="app-page-top-col">
              <div className="app-page-top-search">
                <i className="fa-solid fa-magnifying-glass"></i>{" "}
                <input
                  type="text"
                  placeholder="search staff...."
                  value={search}
                  onChange={(e) => setsearch(e.target.value)}
                />
                {search ? (
                  <i
                    onClick={(e) => setsearch("")}
                    className="fa-solid fa-circle-xmark"
                  ></i>
                ) : (
                  ""
                )}
              </div>

              <div className="app-page-top-button" onClick={()=>setCreateStaffForm(true)}>
                + Add Staff
              </div>
            </div>
          </div>

          <div className="app-page-filter app-page-filter-2">
            {/* <div className="app-page-filter-col-1 app-page-filter-col-active"> */}
            <div
              onClick={(e) => setFilteredData("")}
              className={
                filteredData === ""
                  ? "app-page-filter-col-1 app-page-filter-col-active"
                  : "app-page-filter-col-1"
              }
            >
              <div className="app-page-filter-col-icon">
                {/* <i className="fa-solid fa-users"></i> */}
                <i className="fa-solid fa-clipboard-user"></i>
              </div>
              <div className="app-page-filter-col-text">
                {fetchData.length}
                <span>Total Staff</span>
              </div>
            </div>

            {/* <div onClick={e=>setFilteredData("To Collect")} className={filteredData === "To Collect" ?"app-page-filter-col-2 app-page-filter-col-active" : "app-page-filter-col-2"}>
              <div className="app-page-filter-col-icon">
                <i className="fa-solid fa-indian-rupee-sign"></i>
              </div>
              <div className="app-page-filter-col-text" >
              {fetchData.filter(e=>e.partyOpeningBalanceType === "To Collect").reduce((sum, item) => sum + item.partyOpeningBalance,0).toLocaleString()}
               
                <span>To Collect</span>
              </div>
            </div> */}

            {/* <div
              onClick={(e) => setFilteredData("stockFilter")}
              className={
                filteredData === "stockFilter"
                  ? "app-page-filter-col-3 app-page-filter-col-active"
                  : "app-page-filter-col-3"
              }
            >
              <div className="app-page-filter-col-icon">
                <i className="fa-solid fa-store-slash"></i>
              </div>
              <div className="app-page-filter-col-text">
                {
                  fetchData.filter(
                    (e) => e.itemType === "Product" && e.enableStock && e.itemStock < 1
                  ).length
                }
                <span>Out Of stock</span>
              </div>
            </div> */}

          </div>

          {/* <div className="app-page-table app-page-table-party"> */}
          <div className="app-page-table app-page-table-manage-staff">
            <div className="app-page-table-head">
              {/* <div>Item Name</div> */}

              {sortOrder === "z-a" ? (
                <div
                  onClick={handleZtoA}
                  className="app-page-table-head-filter"
                >
                  <>
                    Staff Name{" "}
                    <div>
                      <i className="fa-solid fa-sort-up"></i>{" "}
                      <i className="fa-solid fa-sort-down app-page-table-head-filter-active"></i>
                    </div>
                  </>
                </div>
              ) : sortOrder === "a-z" ? (
                <div
                  onClick={handleAtoZ}
                  className="app-page-table-head-filter"
                >
                  <>
                    Staff Name{" "}
                    <div>
                      <i className="fa-solid fa-sort-up  app-page-table-head-filter-active"></i>{" "}
                      <i className="fa-solid fa-sort-down"></i>
                    </div>
                  </>
                </div>
              ) : (
                <div
                  onClick={handleAtoZ}
                  className="app-page-table-head-filter"
                >
                  <>
                    Staff Name{" "}
                    <div>
                      <i className="fa-solid fa-sort-up"></i>{" "}
                      <i className="fa-solid fa-sort-down"></i>
                    </div>
                  </>
                </div>
              )}

              <div>Staff Identification</div>
              <div>Mobile Number</div>
              <div>Designation</div>

              {/* <div>Balance</div> */}
            </div>

            <div className="app-page-table-body">
              {
                fetchData
                  .filter((e) =>
                    filteredData === "stockFilter"
                      ? e.itemType === "Product" && e.enableStock  && e.itemStock < 1
                      : e
                  )
                  .filter((e) =>
                    e.staffName
                      .toLocaleLowerCase()
                      .includes(search.toLowerCase())
                  )
                  .map((e) => (
                    <div key={e._id} className="app-page-table-body-data" onClick={(a) => {
                      setViewData(true);
                      setSingleFetchData(e);
                      setSingleFetchDataId(e._id);
                    }}>
                      <div
                      >
                        {e.staffName}
                      </div>

                      <div>{e.staffIdentification?e.staffIdentification:"-"}</div>
                      <div>{e.staffMobileNumber?e.staffMobileNumber:"-"}</div>
                      <div>{e.staffDesignation?e.staffDesignation:"-"}</div>
                      
                   
                    </div>
                  ))
              }
            </div>

            {fetchData
              .filter((e) =>
                filteredData === "stockFilter"
                  ? e.itemType === "Product" && e.enableStock && e.itemStock < 1
                  : e
              )
              .filter((e) =>
                e.staffName.toLocaleLowerCase().includes(search.toLowerCase())
              ).length == 0 || fetchData.length === 0 ? (
              <div className="app-page-table-blank">
                <span>No such data found</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppManageStaff;
