import React, { useContext, useEffect, useState } from "react";
import AppSettingBusinessPageComponent from "./AppSettingBusinessPageComponent";
import { AuthContext } from "../../../context/AuthContext";
import LoadingComponent from "../../../components/extra/LoadingComponent";

const AppSettingBusinessPage = () => {
  // get from storage ================================================================================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  // usestates ================================================================================================================================================

  const [businessUpdateGetData, setBusinessUpdateGetData] = useState();

  // ================================================================================================================================================

  const { fetchBusiness } = useContext(AuthContext);

  const fetchBusinessData = (e) =>
    fetchBusiness(userId, businessId, setBusinessUpdateGetData);

  useEffect(() => {
    fetchBusinessData();
  }, []);

  if (!businessUpdateGetData)
    return (
      <div>
        <LoadingComponent />
      </div>
    );

  return (
    <div>
      <AppSettingBusinessPageComponent businessData={businessUpdateGetData} fetchBusinessData={fetchBusinessData} />
    </div>
  );
};

export default AppSettingBusinessPage;
