import React, { useState, useEffect } from "react";

import { PieChart, Pie, Cell, Tooltip } from "recharts";

const LoanEMICalculatorComponent = () => {
  const [data, setData] = useState([]);

  // const COLORS = ["#0420b9", "#ff5959", "#62e28d", "#ffd748"];
  const COLORS = ["#174555", "#ff0000", "#1bbe52", "#ffc800"];

  // ===========================================================================================================================

  const [principal, setPrincipal] = useState(100000);
  const [rate, setRate] = useState(9.9);
  const [term, setTerm] = useState(3);
  const [termUnit, setTermUnit] = useState("years"); // Default to months
  const [emi, setEmi] = useState(null);
  const [totalInterest, setTotalInterest] = useState(null);

  const calculateEMI = () => {
    const months = termUnit === "years" ? term * 12 : term; // Convert years to months
    const monthlyRate = rate / 12 / 100;
    const emiValue =
      (principal * monthlyRate * Math.pow(1 + monthlyRate, months)) /
      (Math.pow(1 + monthlyRate, months) - 1);

    const totalPayment = emiValue * months; // Total payment over the loan term
    const interestValue = totalPayment - principal; // Total interest paid

    setEmi(emiValue.toFixed(2));
    setTotalInterest(interestValue.toFixed(2));

    setData([
      { name: "Principal Loan Amount", value: Number(principal) },
      { name: "Total interest", value: Number(interestValue.toFixed(2)) },
    ]);

    // setData([
    //   { name: "Cost Price", value: 400 },
    //   { name: "Gst", value: 300 },
    // ]);
  };
  // const calculateEMI = () => {
  //   if (principal && rate && term) {
  //     const months = termUnit === "years" ? term * 12 : term; // Convert years to months
  //     const monthlyRate = rate / 12 / 100;
  //     const emiValue =
  //       (principal * monthlyRate * Math.pow(1 + monthlyRate, months)) /
  //       (Math.pow(1 + monthlyRate, months) - 1);

  //     const totalPayment = emiValue * months; // Total payment over the loan term
  //     const interestValue = totalPayment - principal; // Total interest paid

  //     setEmi(emiValue.toFixed(2));
  //     setTotalInterest(interestValue.toFixed(2));

  //   setData([
  //     { name: "Cost Price", value: principal },
  //     // { name: "Gst", value: Number(totalInterest) },
  //     { name: "Gst", value: 300000 },
  //   ]);

  //   // setData([
  //   //   { name: "Cost Price", value: 400 },
  //   //   { name: "Gst", value: 300 },
  //   // ]);

  //   } else {
  //     setEmi(null); // Reset EMI if inputs are invalid
  //     setTotalInterest(null); // Reset interest if inputs are invalid
  //   }
  // };

  useEffect(() => {
    calculateEMI();
  }, [principal, rate, term, termUnit]);

  return (
    <div>
      <div className="gst-calc">
        <div className="gst-calc-main">
          <div className="gst-calc-heading">Loan EMI Calculator</div>
          <div className="gst-calc-content">
            <div className="gst-calc-content-col-1">
              <div className="gst-calc-content-col-1-col">
                <div className="gst-calc-content-col-1-label">Loan Amount</div>
                <div className="gst-calc-content-col-1-input">
                  <span>
                    <i class="fa-solid fa-indian-rupee-sign"></i>
                  </span>

                  <input
                    min={0}
                    step={10000}
                    type="number"
                    value={principal}
                    onChange={(e) => setPrincipal(e.target.value)}
                  />
                </div>
              </div>
              <div className="gst-calc-content-col-1-range">
                <input
                  type="range"
                  min="0"
                  max="100000000"
                  step={10000}
                  value={principal}
                  onChange={(e) => setPrincipal(e.target.value)}
                  className="range-slider"
                  style={{
                    background: `linear-gradient(90deg, #174555 ${
                      (principal / 100000000) * 100
                    }%, #17455530 ${(principal / 100000000) * 100}%)`,
                  }}
                />
                <div>
                  <span>
                    <i class="fa-solid fa-indian-rupee-sign"></i> 0
                  </span>
                  <span>
                    <i class="fa-solid fa-indian-rupee-sign"></i> 5 Cr
                  </span>
                  <span>
                    <i class="fa-solid fa-indian-rupee-sign"></i> 10 Cr
                  </span>
                </div>
              </div>

              <div className="gst-calc-content-col-1-col">
                <div className="gst-calc-content-col-1-label">Loan Tenure</div>
                <div className="gst-calc-content-col-1-input">

                  <input
                    min={0}
                    type="number"
                    value={term}
                    onChange={(e) => setTerm(e.target.value)}
                  />

                  <select
                    value={termUnit}
                    onChange={(e) => setTermUnit(e.target.value)}
                  >
                    <option value="months">Months</option>
                    <option value="years">Years</option>
                  </select>
                </div>
              </div>
              {termUnit === "months" ? (
                <div className="gst-calc-content-col-1-range">
                  <input
                    type="range"
                    min="0"
                    max="36"
                    value={term}
                    onChange={(e) => setTerm(e.target.value)}
                    className="range-slider"
                    style={{
                      background: `linear-gradient(90deg, #174555 ${
                        (term / 36) * 100
                      }%, #17455530 ${(term / 36) * 100}%)`,
                    }}
                  />
                  <div>
                    <span>0 mth</span>
                    <span>18 mth</span>
                    <span>36 mth</span>
                  </div>
                </div>
              ) : termUnit === "years" ? (
                <div className="gst-calc-content-col-1-range">
                  <input
                    type="range"
                    min="0"
                    max="30"
                    value={term}
                    onChange={(e) => setTerm(e.target.value)}
                    className="range-slider"
                    style={{
                      background: `linear-gradient(90deg, #174555 ${
                        (term / 30) * 100
                      }%, #17455530 ${(term / 30) * 100}%)`,
                    }}
                  />
                  <div>
                    <span>0 Yr</span>
                    <span>15 Yrs</span>
                    <span>30 Yrs</span>
                  </div>
                </div>
              ) : (
                ""
              )}

              <>
                <div className="gst-calc-content-col-1-col">
                  <div className="gst-calc-content-col-1-label">
                    Interest Rate
                  </div>
                  <div className="gst-calc-content-col-1-input">
                    <span>%</span>

                    <input
                      min={0}
                      type="number"
                      value={rate}
                      onChange={(e) => setRate(e.target.value)}
                    />
                  </div>
                </div>

                <div className="gst-calc-content-col-1-range">
                  <input
                    type="range"
                    min="0"
                    max="30"
                    value={rate}
                    onChange={(e) => setRate(e.target.value)}
                    className="range-slider"
                    style={{
                      background: `linear-gradient(90deg, #174555 ${
                        (rate / 30) * 100
                      }%, #17455530 ${(rate / 30) * 100}%)`,
                    }}
                  />
                  <div>
                    <span>
                      <i class="fa-solid fa-indian-rupee-sign"></i> 0%
                    </span>
                    <span>
                      <i class="fa-solid fa-indian-rupee-sign"></i> 15%
                    </span>
                    <span>
                      <i class="fa-solid fa-indian-rupee-sign"></i> 30%
                    </span>
                  </div>
                </div>
              </>
            </div>

            <div className="gst-calc-content-col-2">
              <div className="gst-calc-content-col-2-graph">
                <PieChart width={280} height={280} style={{ margin: "auto" }}>
                  <Pie
                    data={data}
                    innerRadius="70%"
                    outerRadius="100%"
                    fill="#8884d8"
                    paddingAngle={0}
                  >
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
                <div className="gst-calc-content-col-2-value">
                  <span>Monthly EMI</span>
                  <div>
                    <i class="fa-solid fa-indian-rupee-sign"></i>{" "}
                    {new Intl.NumberFormat("en-IN").format(emi)}
                  </div>
                </div>
              </div>
              <div className="gst-calc-content-col-2-graph-lable">
                <div>
                  <div className="gst-calc-content-col-2-graph-cost"></div>
                  Principal Loan Amount
                </div>
                <div>
                  <div className="gst-calc-content-col-2-graph-gst"></div> Total
                  Interest
                </div>
              </div>

              <div className="gst-calc-content-col-2-calcultion">
                <div className="gst-calc-content-col-2-calcultion-value">
                  <div>
                    <span>PRINCIPAL LOAN AMOUNT</span>
                    <span>
                      <i class="fa-solid fa-indian-rupee-sign"></i>{" "}
                      {new Intl.NumberFormat("en-IN").format(principal)}
                    </span>
                  </div>

                  <>
                    <div>+</div>
                    <div>
                      <span>TOTAL PAYABLE INTEREST</span>
                      <span>
                        <i class="fa-solid fa-indian-rupee-sign"></i>{" "}
                        {new Intl.NumberFormat("en-IN").format(totalInterest)}
                      </span>
                    </div>
                  </>
                </div>
                <div className="gst-calc-content-col-2-calcultion-total">
                  Total Payment = <i class="fa-solid fa-indian-rupee-sign"></i>{" "}
                  {new Intl.NumberFormat("en-IN").format(
                    Number(principal) + Number(totalInterest)
                  )}
                  <br />
                  Monthly EMI = <i class="fa-solid fa-indian-rupee-sign"></i>{" "}
                  {new Intl.NumberFormat("en-IN").format(emi)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanEMICalculatorComponent;
