import React, { useContext, useEffect, useState } from 'react'
import AppCashBankComponent from '../../components/pageComponents/AppCashBankComponent'
import { AuthContext } from '../../../context/AuthContext';
import LoadingComponent from '../../../components/extra/LoadingComponent';

const AppCashBankPage = () => {

   // get from storage ============================================================================================

   const userId = localStorage.getItem("invoician-user-id");
   const businessId = localStorage.getItem("business-id");
 
   // useState ============================================================================================
 
   const [businessData, setBusinessData] = useState(null);
 
   //  ============================================================================================
   const { fetchBusiness } = useContext(AuthContext);
   const fetchBusinessData = (e) =>
     fetchBusiness(userId, businessId, setBusinessData);
 
   useEffect(() => {
     fetchBusinessData();
   }, []);
 
   if (!businessData)
     return (
       <div>
         <LoadingComponent />
       </div>
     );
 
   const salesInvoiceData = businessData.salesInvoiceData.map((e) => {
     return { ...e, invoiceType: "sales Invoice" };
   });
   const quotationData = businessData.quotationData.map((e) => {
     return { ...e, invoiceType: "quotation" };
   });
   const proformaData = businessData.proformaData.map((e) => {
     return { ...e, invoiceType: "proforma" };
   });
   const deliveryChallanData = businessData.deliveryChallanData.map((e) => {
     return { ...e, invoiceType: "delivery Challan" };
   });
   const salesReturnData = businessData.salesReturnData.map((e) => {
     return { ...e, invoiceType: "sales Return" };
   });
   const creditNoteData = businessData.creditNoteData.map((e) => {
     return { ...e, invoiceType: "credit Note" };
   });
   const purchaseInvoiceData = businessData.purchaseInvoiceData.map((e) => {
     return { ...e, invoiceType: "purchase Invoice" };
   });
   const purchaseReturnData = businessData.purchaseReturnData.map((e) => {
     return { ...e, invoiceType: "purchase Return" };
   });
   const debitNoteData = businessData.debitNoteData.map((e) => {
     return { ...e, invoiceType: "debit Note" };
   });
   const purchaseOrderData = businessData.purchaseOrderData.map((e) => {
     return { ...e, invoiceType: "purchase Order" };
   });
 
   const combinedData = [
     ...salesInvoiceData,
     ...quotationData,
     ...proformaData,
     ...deliveryChallanData,
     ...salesReturnData,
     ...creditNoteData,
     ...purchaseInvoiceData,
     ...purchaseReturnData,
     ...debitNoteData,
     ...purchaseOrderData,
   ];
 
   combinedData.sort(
     (a, b) => new Date(b.invoiceDate) - new Date(a.invoiceDate)
   );
 
   // -----------------------------------------------------------------------------
   // -----------------------------------------------------------------------------
   // -----------------------------------------------------------------------------
 
   const partyTotalAmount = (id, openingBalance, openingBalanceType) => {
     const salesData =
       businessData &&
       businessData.salesInvoiceData.filter(
         (e) => e.invoicePartyData._id === id
       );
 
     const salesReturnData =
       businessData &&
       businessData.salesReturnData.filter((e) => e.invoicePartyData._id === id);
 
     const creditNoteData =
       businessData &&
       businessData.creditNoteData.filter((e) => e.invoicePartyData._id === id);
 
     const purchaseData =
       businessData &&
       businessData.purchaseInvoiceData.filter(
         (e) => e.invoicePartyData._id === id
       );
 
     const purchaseReturnData =
       businessData &&
       businessData.purchaseReturnData.filter(
         (e) => e.invoicePartyData._id === id
       );
 
     const debitNoteData =
       businessData &&
       businessData.debitNoteData.filter((e) => e.invoicePartyData._id === id);
 
     const salesBalance =
       salesData &&
       salesData.reduce(
         (e, data) => e + (data.invoiceTotalAmount - data.receivedAmount),
         0
       );
     const salesReturnBalance =
       salesReturnData &&
       salesReturnData.reduce(
         (e, data) => e + (data.invoiceTotalAmount - data.receivedAmount),
         0
       );
     const creditNoteBalance =
       creditNoteData &&
       creditNoteData.reduce(
         (e, data) => e + (data.invoiceTotalAmount - data.receivedAmount),
         0
       );
 
     const purchaseBalance =
       purchaseData &&
       purchaseData.reduce(
         (e, data) => e + (data.invoiceTotalAmount - data.receivedAmount),
         0
       );
     const purchaseReturnBalance =
       purchaseReturnData &&
       purchaseReturnData.reduce(
         (e, data) => e + (data.invoiceTotalAmount - data.receivedAmount),
         0
       );
     const debitNoteBalance =
       debitNoteData &&
       debitNoteData.reduce(
         (e, data) => e + (data.invoiceTotalAmount - data.receivedAmount),
         0
       );
     // return salesBalance;
 
     const totalBalance =
       openingBalanceType === "collect"
         ? salesBalance &&
           salesBalance -
             salesReturnBalance -
             creditNoteBalance -
             purchaseBalance +
             purchaseReturnBalance +
             debitNoteBalance +
             openingBalance
         : openingBalanceType === "pay"
         ? salesBalance &&
           salesBalance -
             salesReturnBalance -
             creditNoteBalance -
             purchaseBalance +
             purchaseReturnBalance +
             debitNoteBalance -
             openingBalance
         : "update balance";
 
     return totalBalance;
   };
 
   const toCollect = businessData.partyData
     .filter(
       (e) =>
         partyTotalAmount(
           e._id,
           e.partyOpeningBalance,
           e.partyOpeningBalanceType
         ) > 0
     )
     .reduce(
       (sum, e) =>
         sum +
         partyTotalAmount(
           e._id,
           e.partyOpeningBalance,
           e.partyOpeningBalanceType
         ),
       0
     );
 
   const toPay = Math.abs(
     businessData.partyData
       .filter(
         (e) =>
           partyTotalAmount(
             e._id,
             e.partyOpeningBalance,
             e.partyOpeningBalanceType
           ) < 0
       )
       .reduce(
         (sum, e) =>
           sum +
           partyTotalAmount(
             e._id,
             e.partyOpeningBalance,
             e.partyOpeningBalanceType
           ),
         0
       )
   );

   const totalAmount = toCollect+toPay;
  
  return (
    <div>

      <AppCashBankComponent businessData={businessData} combinedData={combinedData} totalAmount={totalAmount} fetchBusinessData={fetchBusinessData} />
      
    </div>
  )
}

export default AppCashBankPage