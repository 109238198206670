import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import LoadingComponent from "../../../components/extra/LoadingComponent";
import { Navigate, useNavigate } from "react-router-dom";

const AppConfiguration = () => {


  const id = localStorage.getItem("invoician-user-id");
  const token = localStorage.getItem("invoician-token");

  // const id = "66dac2a2e8256b1cccc22639";
  // const token = "40226217889";



  const [configUserData, setConfigUserData] = useState(null);

  const { fetchUser, logout } = useContext(AuthContext);

  useEffect(() => {
    fetchUser(id, setConfigUserData);
  }, []);

  //   ============================================================================================================================================================================================


  // condition 1 ----------------------------------------------------------
  if (!token || !id) {
    return <Navigate to="/login" />;
  }
  

  // condition 2 ----------------------------------------------------------
  if (id.length !== 24){
    // console.log("incorect id")
    logout()
    return <Navigate to="/login" />
  }  


  // condition 3 ----------------------------------------------------------
  if (!configUserData)return <LoadingComponent />;
    

  // condition 4 ----------------------------------------------------------
  if (configUserData === "noUser"){
    // console.log("no user found")
    logout()
    return <Navigate to="/login" />
  };


  // condition 5 ----------------------------------------------------------
  if (token !== configUserData.userToken || id !== configUserData._id || !configUserData.userVerified) {
    logout()
    return <Navigate to="/login" />
  }
  
  
  // conditions ------------------------------------------------------------------------------

  if (configUserData.businessData.length < 1) {
    return <Navigate to="/welcome-user" />;
  }



  // if (configUserData.themeColor) { 
  //   document.documentElement.style.setProperty("--main", configUserData.themeColor);
  // }

  if (configUserData.packageStartDate && new Date() > configUserData.packageEndDate) {
    logout()

    // alert(true)
    // console.log("end Date", configUserData.packageEndDate)
    // console.log("todays :", new Date())
    
    
    return <Navigate to="/login" />
  }

  //   =============================================================================

  return <div></div>;
};

export default AppConfiguration;
