import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import TimerPopupAlertComponent from "../../../components/extra/TimerPopupAlertComponent";

import imgLogo from "../../../media/images/icons/upload.svg"
import addSign from "../../../media/images/icons/add-sign.svg"
import emptyBox from "../../../media/images/icons/empty-sign.svg"


const AppSettingBusinessPageComponent = ( { businessData, fetchBusinessData }) => {

    
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  // useStates ============================================================================================

    

  const [loading, setLoading] = useState(false);
  const [timerPopupAlert, setTimerPopupAlert] = useState(false);
  const [timerAlertMessage, setTimerAlertMessage] = useState("");
  const [timerAlertStatus, setTimerAlertStatus] = useState("");
  
  const [removeCompanyLogo, setRemoveCompanyLogo] = useState(null);
  const [removeCompanySignature, setRemoveCompanySignature] = useState(null);



  
    const [ selectType, setSelectType ] = useState(false)
    
    const [submitButton, setSubmitButton] = useState(false);

    const [companyLogoImage, setCompanyLogoImage] = useState(null)
    const [preiewCompanyLogo, setPreiewCompanyLogo] = useState(null)


    const [signPopup, setSignPopup] = useState(false)

    const [companySignatureImage, setcompanySignatureImage] = useState(null)
    const [preiewCompanySignature, setPreiewCompanySignature] = useState(null)

    
    // ============================================================================================================

    const [ data, setData ] = useState({
        businessName : businessData.businessName ? businessData.businessName : "" ,
        companyPhoneNumber : businessData.companyPhoneNumber ? businessData.companyPhoneNumber : "" ,
        companyEmail : businessData.companyEmail ? businessData.companyEmail : "" ,
        companyBillingAddress : businessData.companyBillingAddress ? businessData.companyBillingAddress : "" ,
        companyState : businessData.companyState ? businessData.companyState : "" ,
        companyPincode : businessData.companyPincode ? businessData.companyPincode : "" ,
        companyCity : businessData.companyCity ? businessData.companyCity : "" ,
        companyGstRegistered : businessData.companyGstRegistered ? businessData.companyGstRegistered : false,
        companyGSTIN : businessData.companyGSTIN ? businessData.companyGSTIN : "",
        companyPanNumber : businessData.companyPanNumber ? businessData.companyPanNumber : "",
        businessType : businessData.businessType?businessData.businessType:[],
        industryType : businessData.industryType ? businessData.industryType : "",
        businessRegistrationType : businessData.businessRegistrationType ? businessData.businessRegistrationType : "",
        businessTermsConditions : businessData.businessTermsConditions ? businessData.businessTermsConditions : "",
        companyLogo : businessData.companyLogo ? businessData.companyLogo : "",
        companySignature : businessData.companySignature ? businessData.companySignature : "",
        companySignatureType : businessData.companySignatureType ? businessData.companySignatureType : "",
    })
    
    // ============================================================================================================
    // ============================================================================================================

    const handleChange = e => {
        const value = e.target.value;
        const name = e.target.name;

        setData({
            ...data,
            [name] : value,
        })

        setSubmitButton(true)
        
    }

    const handleCancel = () => {
        setSubmitButton(false)
        setData({
            businessName : businessData.businessName?businessData.businessName:"",
            companyPhoneNumber : businessData.companyPhoneNumber?businessData.companyPhoneNumber:"",
            companyEmail : businessData.companyEmail?businessData.companyEmail:"",
            companyBillingAddress : businessData.companyBillingAddress?businessData.companyBillingAddress:"",
            companyState : businessData.companyState?businessData.companyState:"",
            companyPincode : businessData.companyPincode?businessData.companyPincode:"",
            companyCity : businessData.companyCity?businessData.companyCity:"",
            companyGstRegistered : businessData.companyGstRegistered?businessData.companyGstRegistered:"",
            companyGSTIN : businessData.companyGSTIN?businessData.companyGSTIN:"",
            businessType : businessData.businessType?businessData.businessType:[],
            industryType : businessData.industryType?businessData.industryType:"",
            businessRegistrationType : businessData.businessRegistrationType?businessData.businessRegistrationType:"",
            businessTermsConditions : businessData.businessTermsConditions?businessData.businessTermsConditions:"",
            companyLogo : businessData.companyLogo?businessData.companyLogo:"",
            companySignature : businessData.companySignature?businessData.companySignature:"",
            companySignatureType : businessData.companySignatureType?businessData.companySignatureType:"",
            companyPanNumber : businessData.companyPanNumber?businessData.companyPanNumber:"",
        })
    }
    
    
    // ============================================================================================================
    // ============================================================================================================

    
  const typeRef = useRef(null);
  const typeListRef = useRef(null);

  const handleClickOutside = (event) => {
    // Check if the click is outside the button and dropdown
    if (
      typeListRef.current &&
      !typeListRef.current.contains(event.target) &&
      typeRef.current &&
      !typeRef.current.contains(event.target)
    ) {
      setSelectType(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

    
    // ============================================================================================================
    // ============================================================================================================

      // ============================================================================================
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click(); // Trigger the hidden file input
  };
    
    
  const logoInputRef = useRef(null);

  const handleLogoInputClick = () => {
    logoInputRef.current.click(); // Trigger the hidden file input
  };
    
    
  // ============================================================================================================
  // ============================================================================================================
  // ============================================================================================================

  
  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    // setError("")

    if (file) {
      const fileSizeInKB = file.size / 1024; // Convert bytes to KB

      if (fileSizeInKB > 500) {
        setTimerPopupAlert(true)
setTimerAlertMessage("File size must be less than 500 KB.")
setTimerAlertStatus("False")

      } else {
      
  
    
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreiewCompanyLogo(reader.result);
      };
      reader.readAsDataURL(file);
    }

    setCompanyLogoImage(e.target.files[0]);
    setSubmitButton(true);
  }
}
  };

  
  
  const handleSignChange = (e) => {
    const file = e.target.files[0];
    // setError("")

    if (file) {
      const fileSizeInKB = file.size / 1024; // Convert bytes to KB

      if (fileSizeInKB > 500) {
        setTimerPopupAlert(true)
        setTimerAlertMessage("File size must be less than 500 KB.")
        setTimerAlertStatus("False")
      } else {
      
  
    
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreiewCompanySignature(reader.result);
      };
      reader.readAsDataURL(file);
    }

    setcompanySignatureImage(e.target.files[0]);

    setData({
      ...data,
      companySignatureType : "Image"
    })
    
    setSubmitButton(true);
    setSignPopup(false)
  }
}
  };

  
  
    // ============================================================================================================
    // ============================================================================================================
    // ============================================================================================================
    // ============================================================================================================
    // ============================================================================================================
    // ============================================================================================================
    // ============================================================================================================
  const { updateBusiness, link } = useContext(AuthContext);

  const handleBusiness = async (e) => {
    e.preventDefault();
    setLoading(true);


    const formData = new FormData();

    formData.append( "businessName" , data.businessName );
    formData.append( "companyPhoneNumber" , data.companyPhoneNumber );
    formData.append( "companyEmail" , data.companyEmail );
    formData.append( "companyBillingAddress" , data.companyBillingAddress );
    formData.append( "companyState" , data.companyState );
    formData.append( "companyPincode" , data.companyPincode );
    formData.append( "companyCity" , data.companyCity );
    formData.append( "companyGstRegistered" , data.companyGstRegistered );
    formData.append( "companyGSTIN" , data.companyGSTIN );
    formData.append( "industryType" , data.industryType );
    formData.append( "businessRegistrationType" , data.businessRegistrationType );
    formData.append( "businessTermsConditions" , data.businessTermsConditions );
    formData.append( "companyLogo" , data.companyLogo );
    formData.append( "companySignature" , data.companySignature );
    formData.append( "companySignatureType" , data.companySignatureType );
    formData.append( "companyPanNumber" , data.companyPanNumber );
    formData.append( "removeLogo" , removeCompanyLogo );
    formData.append( "removeSignature" , removeCompanySignature );
    

    if(data.businessType.length>0){

      data.businessType.forEach( (e, i) =>{
        formData.append( `businessType[${i}]` , e );
        
      })
    }else{
      formData.append("businessType", "");  
    }
    
    
    if (companyLogoImage) {
      formData.append("companyLogo", companyLogoImage);
    }

    if (companySignatureImage) {
      formData.append("companySignature", companySignatureImage);
    }
    

    updateBusiness(
      userId,
      businessId,
      formData,
      fetchBusinessData,
      setTimerPopupAlert,
      setTimerAlertMessage,
      setTimerAlertStatus,
      setSubmitButton,
      setRemoveCompanyLogo,
      setRemoveCompanySignature,
    );

    setLoading(false);
  };
    
    
    // ============================================================================================================
    // ============================================================================================================
    
    
  return (
    <div>


      {timerPopupAlert ? (
        <TimerPopupAlertComponent
          message={timerAlertMessage}
          setTimerPopupAlert={setTimerPopupAlert}
          status={timerAlertStatus}
        />
      ) : (
        ""
      )}



        
      <form onSubmit={handleBusiness} className="setting-business-page">
        <div className="setting-business-page-main">
          {/**************************************************************************************************************************/}

          <div className="setting-business-page-head">
            <div className="setting-business-page-head-col">
              <div className="setting-business-page-heading">
                <div className="setting-business-page-main-heading">
                  Business Settings
                </div>
                <div className="setting-business-page-sub-heading">
                  Edit your company settings and information
                </div>
              </div>
              <div className="setting-business-page-head-col-button">
                Create new business
              </div>
            </div>

            <div className="setting-business-page-buttons">

                <div className="setting-business-page-button-cancel" onClick={handleCancel} >Cancel</div>

                {
                    submitButton?
                    <button className="setting-business-page-button">Save Changes</button>
                    :
                    <div className="setting-business-page-button-off">Save Changes</div>
                }

              
              
            </div>
          </div>

          {/**************************************************************************************************************************/}

          <div className="setting-business-page-form">
            <div className="setting-business-page-form-row">
              <div className="setting-business-page-form-section-1">

                <div className="setting-business-page-form-company">
                <div className="setting-business-page-form-company-logo">
                <div className="setting-business-page-form-company-logo-image">


                  {
                    data.companyLogo?
                    <>
                    {/* <img src={`https://api.invoician.com/${data.companyLogo}`} /> */}
                    <img src={`${link}/${data.companyLogo}`} />
                    <div className="setting-business-page-form-company-logo-option">
                      <div onClick={ () => {
                         setData({ ...data, companyLogo : null }); 
                      setRemoveCompanyLogo("Yes"); 
                      setSubmitButton(true) } }>Remove Logo</div>
                    </div>
                    </>

                    :

                    preiewCompanyLogo?
                    <>
                    <img src={preiewCompanyLogo} />
                    <div className="setting-business-page-form-company-logo-option">
                      <div 
                      onClick={(e) => {
                        setCompanyLogoImage(null);
                        setPreiewCompanyLogo(null);
                        setSubmitButton(true);
                      }}
                      >Remove Logo</div>
                    </div>
                    </>
                    :
                    <>
<img src={imgLogo} alt="" />
                    <div className="setting-business-page-form-company-logo-option">
                    <span onClick={handleLogoInputClick}>Upload Image</span>
                  </div>
                    </>

                    
                    
                  }

                  {/* { 
                    preiewCompanyLogo?
                    <img src={preiewCompanyLogo} />
                    :
                    <img src={imgLogo} alt="" />
                  } */}
                  
                <input 
                  type="file"
                  accept="image/*"
                  onChange={handleLogoChange}
                  ref={logoInputRef}
                  style={{ display: "none" }}
                />
                  
                </div>
                {/* <div className="setting-business-page-form-company-logo-option">
                  <span onClick={handleButtonClick}>Upload Image</span>
                </div> */}

                </div>

                <div className="setting-business-page-form-col">
                  <label>
                    Business Name <b>*</b>
                  </label>
                  <input required name="businessName" value={data.businessName} onChange={handleChange}  type="text" placeholder="Enter Business Name" />
                </div>
                
                </div>

                <div className="setting-business-page-form-row">
                  <div className="setting-business-page-form-col">
                    <label>Company Phone Number</label>
                    <input name="companyPhoneNumber" value={data.companyPhoneNumber} onChange={handleChange} 
                      type="number"
                      placeholder="Enter Company Phone Number"
                    />
                  </div>

                  <div className="setting-business-page-form-col">
                    <label>Company E-Mail</label>
                    <input name="companyEmail" value={data.companyEmail} onChange={handleChange}  type="email" placeholder="Enter Company E-Mail" />
                  </div>
                </div>

                <div className="setting-business-page-form-col">
                  <label>Billing Address</label>
                  <textarea name="companyBillingAddress" value={data.companyBillingAddress} onChange={handleChange} placeholder="Enter Billing Address" />
                </div>

                <div className="setting-business-page-form-row">
                  <div className="setting-business-page-form-col">
                    <label>State</label>
                    <input name="companyState" value={data.companyState} onChange={handleChange}  type="text" placeholder="Enter State" />
                  </div>

                  <div className="setting-business-page-form-col">
                    <label>Pincode</label>
                    <input name="companyPincode" value={data.companyPincode} onChange={handleChange}  type="number" placeholder="Enter Pincode" />
                  </div>
                </div>

                <div className="setting-business-page-form-col">
                  <label>City</label>
                  <input name="companyCity" value={data.companyCity} onChange={handleChange}  type="text" placeholder="Enter City" />
                </div>

                <div className="setting-business-page-form-col">
                  <label>Are you GST Registered?</label>
                <div className="setting-business-page-form-radio-options">
                    
                <div className="setting-business-page-form-radio" onClick={ () => {setData({ ...data, companyGstRegistered : true}); setSubmitButton(true)}}>
                    Yes <input checked={data.companyGstRegistered?true:false} type="radio" />
                </div>

                <div className="setting-business-page-form-radio" onClick={ () => {setData({ ...data, companyGstRegistered : false, companyGSTIN : ""}); setSubmitButton(true)}}>
                    no <input checked={!data.companyGstRegistered?true:false} type="radio" />
                </div>

                </div>
                </div>

{

data.companyGstRegistered?

                <div className="setting-business-page-form-col">
                  <label>
                    GSTIN <b>*</b>
                  </label>
                  <input required name="companyGSTIN" value={data.companyGSTIN} onChange={handleChange}  type="text" placeholder="Enter GST Number" />
                </div>
                :""}

                <div className="setting-business-page-form-col">
                  <label>PAN Number</label>
                  <input name="companyPanNumber" value={data.companyPanNumber} onChange={handleChange}  type="text" placeholder="Enter PAN Number" />
                </div>

                {/* section-1 close -------------- */}
              </div>

              {/* -------------------------------------------------------------------------------------------------------------- */}
              <div className="setting-business-page-form-section-2">
                <div className="setting-business-page-form-row">
                  
                  <div className="setting-business-page-form-col">
                    <label>
                      Business Type (Select multiple, if applicable)
                    </label>
                  <div className="setting-business-page-form-col-dropdown" ref={typeRef} onClick={ () => setSelectType(true) } >
                    <div className="setting-business-page-form-col-dropdown-select">{data.businessType.length === 1 && data.businessType[0] === "" ?"select":data.businessType.length>0?data.businessType.map( (e, i) => <>{e}{e.length>0 ? i< data.businessType.length-1?", ":"":""} </>): "select"} <i className="fa-solid fa-angle-down"></i></div>
{
    selectType?

                    <div className="setting-business-page-form-col-select-options" ref={typeListRef}>
                        
                        {
                        data.businessType.some( e => e === "Retailer")?
                        <div className="setting-business-page-form-col-option" onClick={ () => { setData({...data, businessType : data.businessType.filter( e => e !== "Retailer")}); setSubmitButton(true) }}>Retailer <i className="fa-regular fa-square-check"></i></div>
                        :
                        <div className="setting-business-page-form-col-option" onClick={ () => { setData( { ...data, businessType : [...data.businessType, "Retailer"] }); setSubmitButton(true) }}>Retailer <i className="fa-regular fa-square"></i></div>
                        }
                        
                        {
                        data.businessType.some( e => e === "Wholesaler")?
                        <div className="setting-business-page-form-col-option" onClick={ () => { setData({...data, businessType : data.businessType.filter( e => e !== "Wholesaler")}); setSubmitButton(true) }}>Wholesaler <i className="fa-regular fa-square-check"></i></div>
                        :
                        <div className="setting-business-page-form-col-option" onClick={ () => { setData( { ...data, businessType : [...data.businessType, "Wholesaler"] }); setSubmitButton(true) }}>Wholesaler <i className="fa-regular fa-square"></i></div>
                        }
                        
                        {
                        data.businessType.some( e => e === "Distributor")?
                        <div className="setting-business-page-form-col-option" onClick={ () => { setData({...data, businessType : data.businessType.filter( e => e !== "Distributor")}); setSubmitButton(true) }}>Distributor <i className="fa-regular fa-square-check"></i></div>
                        :
                        <div className="setting-business-page-form-col-option" onClick={ () => { setData( { ...data, businessType : [...data.businessType, "Distributor"] }); setSubmitButton(true) }}>Distributor <i className="fa-regular fa-square"></i></div>
                        }
                        
                        {
                        data.businessType.some( e => e === "Manufacturer")?
                        <div className="setting-business-page-form-col-option" onClick={ () => { setData({...data, businessType : data.businessType.filter( e => e !== "Manufacturer")}); setSubmitButton(true) }}>Manufacturer <i className="fa-regular fa-square-check"></i></div>
                        :
                        <div className="setting-business-page-form-col-option" onClick={ () => { setData( { ...data, businessType : [...data.businessType, "Manufacturer"] }); setSubmitButton(true) }}>Manufacturer <i className="fa-regular fa-square"></i></div>
                        }
                        
                        {
                        data.businessType.some( e => e === "Services")?
                        <div className="setting-business-page-form-col-option" onClick={ () => { setData({...data, businessType : data.businessType.filter( e => e !== "Services")}); setSubmitButton(true) }}>Services <i className="fa-regular fa-square-check"></i></div>
                        :
                        <div className="setting-business-page-form-col-option" onClick={ () => { setData( { ...data, businessType : [...data.businessType, "Services"] }); setSubmitButton(true) }}>Services <i className="fa-regular fa-square"></i></div>
                        }
                        
                        
                    </div>
                    :""}

                    </div>

                  </div>

                  <div className="setting-business-page-form-col">
                    <label>Industry Type</label>
                  <div className="setting-business-page-form-col-select">
                    <select name="industryType" onChange={handleChange} value={data.industryType}>
                        <option disabled selected value="">Select Industry Type</option>
                      <option value="Accounting and Financial Services">
                        Accounting and Financial Services
                      </option>
                      <option value="Agriculture">Agriculture</option>
                      <option value="Automobile">Automobile</option>
                      <option value="Battery">Battery</option>
                      <option value="Broadband/ cable/ internet">
                        Broadband/ cable/ internet
                      </option>
                      <option value="Building Material and Construction">
                        Building Material and Construction
                      </option>
                      <option value="Cleaning and Pest Control">
                        Cleaning and Pest Control
                      </option>
                      <option value="Consulting">Consulting</option>
                      <option value="Dairy (Milk)">Dairy (Milk)</option>
                      <option value="Doctor / Clinic / Hospital">
                        Doctor / Clinic / Hospital
                      </option>
                      <option value="Education-Schooling/Coaching">
                        Education-Schooling/Coaching
                      </option>
                      <option value="Electrical works">Electrical works</option>
                      <option value="Electronics">Electronics</option>
                      <option value="Engineering">Engineering</option>
                      <option value="Event planning and management">
                        Event planning and management
                      </option>
                      <option value="FMCG">FMCG</option>
                      <option value="Fitness - Gym and Spa">
                        Fitness - Gym and Spa
                      </option>
                      <option value="Footwear">Footwear</option>
                      <option value="Fruits and Vegetables">
                        Fruits and Vegetables
                      </option>
                      <option value="Furniture">Furniture</option>
                      <option value="Garment/Clothing">Garment/Clothing</option>
                      <option value="General Store(Kirana)">
                        General Store(Kirana)
                      </option>
                      <option value="Gift Shop">Gift Shop</option>
                      <option value="Hardware">Hardware</option>
                      <option value="Home services">Home services</option>
                      <option value="Hotels and Hospitality">
                        Hotels and Hospitality
                      </option>
                      <option value="Information Technology">
                        Information Technology
                      </option>
                      <option value="Interiors">Interiors</option>
                      <option value="Jewellery">Jewellery</option>
                      <option value="Liquor">Liquor</option>
                      <option value="Machinery">Machinery</option>
                      <option value="Meat">Meat</option>
                      <option value="Medicine(Pharma)">Medicine(Pharma)</option>
                      <option value="Mobile and accessories">
                        Mobile and accessories
                      </option>
                      <option value="Oil And Gas">Oil And Gas</option>
                      <option value="Opticals">Opticals</option>
                      <option value="Other services">Other services</option>
                      <option value="Others">Others</option>
                      <option value="Packaging">Packaging</option>
                      <option value="Paints">Paints</option>
                      <option value="Photography">Photography</option>
                      <option value="Plywood">Plywood</option>
                      <option value="Printing">Printing</option>
                      <option value="Real estate - Rentals and Lease">
                        Real estate - Rentals and Lease
                      </option>
                      <option value="Restaurants/ Cafe/ Catering">
                        Restaurants/ Cafe/ Catering
                      </option>
                      <option value="Safety Equipments">
                        Safety Equipments
                      </option>
                      <option value="Salon">Salon</option>
                      <option value="Scrap">Scrap</option>
                      <option value="Service Centres">Service Centres</option>
                      <option value="Sports Equipments">
                        Sports Equipments
                      </option>
                      <option value="Stationery">Stationery</option>
                      <option value="Tailoring/ Boutique">
                        Tailoring/ Boutique
                      </option>
                      <option value="Textiles">Textiles</option>
                      <option value="Tiles/Sanitary Ware">
                        Tiles/Sanitary Ware
                      </option>
                      <option value="Tours and Travel">Tours and Travel</option>
                      <option value="Transport and Logistics">
                        Transport and Logistics
                      </option>
                      <option value="Utensils">Utensils</option>
                    </select>
                    {
                        data.industryType?
                        <i className="fa-solid fa-xmark" onClick={ () => {setData({ ...data, industryType : "" }); setSubmitButton(true) } }></i>
                        :
                        <i className="fa-solid fa-angle-down"></i>
                    }
                  </div>
                  </div>

                  <div className="setting-business-page-form-col">
                    <label>Business Registration Type</label>

                    <select name="businessRegistrationType" onChange={handleChange} value={data.businessRegistrationType}>
                        <option disabled selected value="">Select Registration Type</option>
                      <option value="Private Limited Company">
                        Private Limited Company
                      </option>
                      <option value="Public Limited Company">
                        Public Limited Company
                      </option>
                      <option value="Partnerships Firm">
                        Partnerships Firm
                      </option>
                      <option value="Limited Liability Partnership">
                        Limited Liability Partnership
                      </option>
                      <option value="One Person Company">
                        One Person Company
                      </option>
                      <option value="Sole Proprietorship">
                        Sole Proprietorship
                      </option>
                      <option value="Section 8 Company">
                        Section 8 Company
                      </option>
                      <option value="Business Not Registered">
                        Business Not Registered
                      </option>
                    </select>
                  </div>
                </div>

                <div className="setting-business-page-form-note">
                <b>Note: </b>Terms & Conditions and Signature added below will be shown on your Invoices
                </div>

                <div className="setting-business-page-form-col">
                  <label>Terms and Conditions</label>
                  <textarea name="businessTermsConditions" onChange={handleChange} value={data.businessTermsConditions} placeholder="Enter Terms & Conditions" />
                </div>

                <div className="setting-business-page-form-col">
                  <label>Signature</label>
                <div className="setting-business-page-form-signature">
                  {
                    signPopup?
                    <div className="setting-business-page-form-signature-popup">
                    <div className="setting-business-page-form-signature-popup-main">

                    <div className="setting-business-page-form-signature-popup-top">
                      Signature <i className="fa-solid fa-xmark" onClick={ () => setSignPopup(false)}></i>
                    </div>
                    <div className="setting-business-page-form-row">
                                
                <input 
                  type="file"
                  accept="image/*"
                  onChange={handleSignChange}
                  ref={fileInputRef}
                  style={{ display: "none" }}
                />
                    <div onClick={handleButtonClick} className="setting-business-page-form-signature-popup-col">
                      <img src={addSign} alt="" />
                      Upload Signature from Desktop
                    </div>
                    <div className="setting-business-page-form-signature-popup-col" onClick={ () => { setData({ ...data, companySignatureType: "Box"}, setSignPopup(false)); setSubmitButton(true)  } }>
                      <img src={emptyBox} alt="" />
                      Show Empty Signature Box on Invoice
                    </div>

                    </div>

                    </div>

                    </div>
                    :""
                  }



{
  data.companySignatureType === "Image"?
  data.companySignature?
  <>
  <div className="setting-business-page-form-signature-col"><img src={`${link}/${data.companySignature}`}  alt="company signature"/></div>
  <div className="setting-business-page-form-signature-text-link" onClick={ () => { setData( {...data, companySignature : null}) ; setRemoveCompanySignature("Yes"); setSubmitButton(true); }} >Remove</div>
  </>
  :
  
  preiewCompanySignature?        
  <>
  <div className="setting-business-page-form-signature-col"> <img src={preiewCompanySignature} /></div>
  <div className="setting-business-page-form-signature-text-link"
                      onClick={(e) => {
                        setcompanySignatureImage(null);
                        setPreiewCompanySignature(null);
                        setSubmitButton(true);
                      }}
                      >Remove</div>
  </>
  :
  <div className="setting-business-page-form-signature-col" onClick={ () => {setSignPopup(true) }}>+ Add Signature</div>



  :
  
  data.companySignatureType === "Box"?
  <>
                <div className="setting-business-page-form-signature-col" onClick={ () => {setSignPopup(true) }}></div>
                <div className="setting-business-page-form-signature-text">You have enabled to show ‘Empty Signature box’ on invoices</div>
                <div className="setting-business-page-form-signature-text-link" onClick={ () => { setData({ ...data, companySignatureType: ""}, setSignPopup(false)); setSubmitButton(true)  } }>Remove</div>
</>
  :
  <div className="setting-business-page-form-signature-col" onClick={ () => {setSignPopup(true) }}>+ Add Signature</div>

}



{/*                   
{
  data.companySignatureType === "Box"?
  
<>
                <div className="setting-business-page-form-signature-col" onClick={ () => {setSignPopup(true) }}></div>
                <div className="setting-business-page-form-signature-text">You have enabled to show ‘Empty Signature box’ on invoices</div>
                <div className="setting-business-page-form-signature-text-link" onClick={ () => { setData({ ...data, companySignatureType: ""}, setSignPopup(false))  } }>Remove</div>
</>

:
data.companySignatureType === "Image"?
                
                <>
                <>
                <div className="setting-business-page-form-signature-col" onClick={ () => {setSignPopup(true) }}>+ Add Signature</div>
                </>
                

                
                <>
                <div className="setting-business-page-form-signature-col"> <img src={preiewCompanySignature} /></div>
                <div className="setting-business-page-form-signature-text-link">Remove</div>
                </>
                </>

                :
                <div className="setting-business-page-form-signature-col" onClick={ () => {setSignPopup(true) }}>+ Add Signature</div>

                } */}

                    </div> 
                </div>
                {/* section-2 close -------------- */}
              </div>
            </div>
          </div>

          {/**************************************************************************************************************************/}
          {/**************************************************************************************************************************/}
          {/**************************************************************************************************************************/}
          {/**************************************************************************************************************************/}
        </div>
      </form>
    </div>
  );
};

export default AppSettingBusinessPageComponent;
