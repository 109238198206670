import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import LoadingComponent from "../../../components/extra/LoadingComponent";
// import CreateItemForm from "./CreateItemForm";
import CreateItemFormPopup from "./CreateItemFormPopup";

const SelectItemStockForm = ({
  open,
  selectedItems,
  setSelectedItems,
  handleRemoveItem,
  handleQuantityChange,
}) => {
  // get from storage ============================================================================================

  const userId = localStorage.getItem("invoician-user-id");
  const businessId = localStorage.getItem("business-id");

  const [createData, setCreateData] = useState(false);

  // use state ============================================================================================

  const [searchItem, setSearchItem] = useState("");
  const [fetchData, setFetchData] = useState();

  const { fetchItems } = useContext(AuthContext);

  const fetchItemData = (e) => fetchItems(userId, businessId, setFetchData);

  useEffect(() => {
    fetchItemData();
  }, []);

  // set items ===========================================================================================

  const handleAddItem = (item) => {
    // const existingProduct = selectedProducts.find((p) => p.id === product.id);
    // if (!existingProduct) {
    //   // Add product with quantity 1
    //   setSelectedProducts([...selectedProducts, { ...product, quantity: 1 }]);
    // }

    if (!selectedItems.some((p) => p._id === item._id)) {
      setSelectedItems([...selectedItems, { ...item, quantity: 1, discount : 0, cess : 0 }]);
    }
    console.log("first==", selectedItems);
  };

  // ===========================================================================================

  if (!fetchData)
    return (
      <div>
        <LoadingComponent />
      </div>
    );

  return (
    <div>
      {createData ? (
        <div className="select-form-create-form">
          <CreateItemFormPopup open={setCreateData} fetchData={fetchItemData} />
        </div>
      ) : (
        ""
      )}

      <div className="select-form">
        <div className="select-form-main">
          <div className="select-form-top">
            <div className="select-form-top-heading">Add Items</div>
            <div className="select-form-top-search">
              <div className="app-page-top-search">
                <i className="fa-solid fa-magnifying-glass"></i>{" "}
                <input
                  type="text"
                  placeholder="search item...."
                  value={searchItem}
                  onChange={(e) => setSearchItem(e.target.value)}
                />
                {searchItem ? (
                  <i
                    onClick={(e) => setSearchItem("")}
                    className="fa-solid fa-circle-xmark"
                  ></i>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="select-form-data-list">
            <div className="select-form-data-list-table select-item-form-data-list-table">
              <div className="select-form-data-list-table-head select-item-form-data-list-table-head">
                <div>Item Name</div>
                <div>Total Price</div>
                <div>Tax</div>
                <div>Stock</div>
                <div></div>
              </div>

              {/* <div className="select-item-form-data-list-table-list"></div> */}

              <div className="select-form-data-list-table-list select-item-form-data-list-table-list">
                {/* {fetchData
                  .filter((e) =>
                    e.itemName
                      .toLocaleLowerCase()
                      .includes(searchItem.toLowerCase())
                  )
                  .map((e) => (
                    <div   className="select-form-data-list-table-body select-item-form-data-list-table-body">
                      <div>{e.itemName}</div>
                      <div>{e.itemPrice ? <>Rs. {e.itemPrice}</> : "-"}</div>
                      <div>{e.itemStock ?  <>
                            {e.itemStock > 0 ? (
                              <span className="app-page-table-body-data-green">
                                {" "}
                                {e.itemStock}  { e.itemUnit}
                              </span>
                            ) : e.itemStock < 0 ? (
                              <span className="app-page-table-body-data-red">
                                {" "}
                                {e.itemStock}  { e.itemUnit}
                              </span>
                            ) : (
                              <span className="app-page-table-body-data-red">
                                --
                              </span>
                            )}
                          </> : "--"}</div>
                      <div><span className="select-form-data-list-add">add</span></div>
                    </div>
                  ))} */}

                {selectedItems.map((item) => (
                  <div
                    key={item._id}
                    className="select-form-data-list-table-body select-item-form-data-list-table-body select-item-form-data-list-table-body-selected "
                  >
                    <div className="select-form-data-list-name"><span>{item.itemName}</span>{item.itemCategory?<div><span>{item.itemCategory}</span></div> : ""} </div>
                    <div>
                      {item.itemPrice ? (
                        <>
                          Rs.{" "}
                          {item.itemPrice +
                            (item.itemPrice * item.itemTax) / 100}
                        </>
                      ) : (
                        "-"
                      )}
                    </div>
                    <div>{item.itemTax ? <>{item.itemTax}%</> : "-"}</div>
                    <div>
                      {item.itemType === "Service" ? (
                        <span className="select-form-data-list-stock-disable">
                          service
                        </span>
                      ) : item.itemType === "Product" ? (
                        <span className="select-form-data-list-stock">
                          {!item.itemStock ? (
                            <span className="app-page-table-body-data-red select-form-data-list-stock-disable">
                              disable
                            </span>
                          ) : item.itemStock - item.quantity > 0 ? (
                            <span className="app-page-table-body-data-green">
                              {" "}
                              {item.itemStock - item.quantity} {item.itemUnit}
                            </span>
                          ) : item.itemStock - item.quantity <= 0 ? (
                            <span className="app-page-table-body-data-red">
                              {" "}
                              {item.itemStock - item.quantity} {item.itemUnit}
                            </span>
                          ) : (
                            <span className="app-page-table-body-data-red">
                              --
                            </span>
                          )}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>

                    <div>
                  
                      <span className="select-form-data-list-del">
                        <input
                          placeholder="Enter new price"
                          type="number"
                          defaultValue={item.quantity}
                          onChange={(e) =>
                            handleQuantityChange(item._id, e.target.value)
                          }
                          min={0}
                        />
                        <i
                          className="fa-solid fa-trash"
                          onClick={() => handleRemoveItem(item._id)}
                        ></i>
                      </span>
                    </div>

                  </div>
                ))}
                {/* <h3>------------------------------------------</h3> */}
                {fetchData
                  .filter((e) =>e.itemName.toLocaleLowerCase().includes(searchItem.toLowerCase()))
                  .filter((e) => !selectedItems.some((b) => e._id === b._id))
                  .map((item) => (
                    <div
                      key={item._id}
                      className="select-form-data-list-table-body select-item-form-data-list-table-body"
                    >

                      {/* <div>{item.itemName}</div> */}
                    <div className="select-form-data-list-name"><span>{item.itemName}</span>{item.itemCategory?<div><span>{item.itemCategory}</span></div> : ""} </div>

                      <div>
                        {item.itemPrice ? (
                          <>
                            Rs.{" "}
                            {item.itemPrice +
                              (item.itemPrice * item.itemTax) / 100}
                          </>
                        ) : (
                          "-"
                        )}
                      </div>
                      <div>{item.itemTax ? <>{item.itemTax}%</> : "-"}</div>

                      {/* <div>
                        {item.itemStock ? (
                          <>
                            {item.itemStock > 0 ? (
                              <span className="app-page-table-body-data-green">
                                {" "}
                                {item.itemStock} {item.itemUnit}
                              </span>
                            ) : item.itemStock < 0 ? (
                              <span className="app-page-table-body-data-red">
                                {" "}
                                {item.itemStock} {item.itemUnit}
                              </span>
                            ) : (
                              <span className="app-page-table-body-data-red">
                                --
                              </span>
                            )}
                          </>
                        ) : (
                          "--"
                        )}
                      </div> */}

<div>
                      {item.itemType === "Service" ? (
                        <span className="select-form-data-list-stock-disable">
                          service
                        </span>
                      ) : item.itemType === "Product" ? (
                        <span className="select-form-data-list-stock">
                          {!item.enableStock ? (
                            <span className="app-page-table-body-data-red select-form-data-list-stock-disable">
                              disable
                            </span>
                          ) : item.itemStock > 0 ? (
                            <span className="app-page-table-body-data-green">
                              {" "}
                              {item.itemStock } {item.itemUnit}
                            </span>
                          ) : item.itemStock  <= 0 ? (
                            <span className="app-page-table-body-data-red">
                              {" "}
                              {item.itemStock } {item.itemUnit}
                            </span>
                          ) : (
                            <span className="app-page-table-body-data-red">
                              --
                            </span>
                          )}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>

                      <div>
                        <span
                          onClick={() => handleAddItem(item)}
                          className="select-form-data-list-add"
                        >
                          add 
                        </span>
                      </div>
                      
                    </div>
                  ))}

                {/* {fetchData
.filter((e) =>e.itemName.toLocaleLowerCase().includes(searchItem.toLowerCase())
)
.map((item) => {
          const isSelected = selectedItems.some((p) => p._id === item._id);
          return (
            <div key={item._id}  className="select-form-data-list-table-body select-item-form-data-list-table-body">
                      <div>{item.itemName}</div>
                      <div>{item.itemPrice ? <>Rs. {item.itemPrice}</> : "-"}</div>
                      <div>{item.itemTax ? <>{item.itemTax}%</> : "-"}</div>
                      <div>{item.itemStock ?  <>
                            {item.itemStock > 0 ? (
                              <span className="app-page-table-body-data-green">
                                {" "}
                                {item.itemStock}  { item.itemUnit}
                              </span>
                            ) : item.itemStock < 0 ? (
                              <span className="app-page-table-body-data-red">
                                {" "}
                                {item.itemStock}  { item.itemUnit}
                              </span>
                            ) : (
                              <span className="app-page-table-body-data-red">
                                --
                              </span>
                            )}
                          </> : "--"}</div>
                      
                      <div>
                {isSelected ?
                        <span onClick={() =>  handleRemoveItem(item._id) } className="select-form-data-list-remove">remove</span>
                        : 
                        <span onClick={() =>  handleAddItem(item)} className="select-form-data-list-add">add</span>
                }
                </div>
                    </div>
          );
        })} */}

                {fetchData
                .filter((e) =>e.itemName.toLocaleLowerCase().includes(searchItem.toLowerCase()))
                .length === 0 || fetchData.length === 0 ? (
                  <div className="app-page-table-blank">
                    <span>No data found</span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="select-form-end">
            <div
              className="select-form-end-button"
              onClick={(e) => open(false)}
            >
              Done
            </div>
            <div
              className="select-form-end-button"
              onClick={(e) => setCreateData(true)}
            >
              Create Item
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectItemStockForm;
